import moment from "moment";
import "moment-timezone";

const parseDateRange = (dateRange: any) => {
  const { startDate, endDate } = dateRange;
  const parseStartDate = moment(startDate).format("DD MMM YYYY");
  const parseEndDate = moment(endDate).format("DD MMM YYYY");
  return parseStartDate + " - " + parseEndDate;
};
const parseDateToFormat = (date: any, format = "") => {
  return moment(date).format(format);
};
const parseUnixToFormat = (date: any, format = "DD MMM YYYY") => {
  return moment.unix(date).format(format);
};
const getDateWithTZ = (date: any) => {
  let d = new Date(date);
  let curr_date = moment(d).format("DD.MM.YYYY HH:mm UTCZ");
  return curr_date.substring(curr_date.length - 3, 0);
};
const formatTimezoneForCalendar = (date: any, timezone: any) => {
  if (timezone === undefined) {
    return date;
  }
  const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const otherZone = moment.tz(date, timezone).format("Z");
  const dateWithOtherZone = [dateWithoutZone, otherZone].join("");

  return new Date(dateWithOtherZone);
};
const getTimeForTimer = (date2: any) => {
  let d1 = new Date();
  let d2 = new Date(date2);
  let temp_time = (d2.getTime() - d1.getTime()) / 1000;
  const time = new Date();
  time.setSeconds(time.getSeconds() + temp_time);
  return time;
};
const countPercent = (date: any, startDate: any) => {
  let date1 = new Date(date);
  let date2 = new Date();
  let start = new Date(startDate);
  let time1 = date1.getTime();
  let time2 = date2.getTime();
  let start_time = start.getTime();
  let t1 = time1 - start_time;
  let t2 = time2 - start_time;
  let percent = (t2 * 100) / t1;
  // console.log("percent", percent);
  return percent;
};

const formatDateToCustomFormat = (date: any) => {
  if (date) {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate.replace(",", "");
  }
};

const formatDateForApiCall = (date: any) => {
  if (date) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}-${month}-${year}`;
  }
};

const formatDateForCryptoApiCall = (date: any) => {
  const dateToFormat = date ? date : new Date();

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = dateToFormat.toLocaleDateString("en-US", options);
  const [month, day, year, hours, minutes] = formattedDate.split(/[/, ,:,\s]+/);
  let returnedHours;

  if (hours === "24") {
    returnedHours = "00";
  } else {
    returnedHours = hours;
  }
  return `${day}-${month}-${year} ${returnedHours}:${minutes}`;
};

const formatDateForProfitTable = (date: any) => {
  const dateToFormat = new Date(date);

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = dateToFormat.toLocaleDateString("en-US", options as any);
  const [month, day, year, hours, minutes] = formattedDate.split(/[/, ,:,\s]+/);
  let returnedHours;

  if (hours === "24") {
    returnedHours = "00";
  } else {
    returnedHours = hours;
  }
  return `${day}-${month}-${year} ${returnedHours}:${minutes}`;
};

const formatDateForHistoryTable = (date: any) => {
  const dateToFormat = new Date(date);

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  const formattedDate = dateToFormat.toLocaleDateString("en-US", options as any);
  const [month, day, year, hours, minutes, seconds] = formattedDate.split(/[/, ,:,\s]+/);
  let returnedHours;

  if (hours === "24") {
    returnedHours = "00";
  } else {
    returnedHours = hours;
  }
  return `${year}\\${month}\\${day} ${returnedHours}:${minutes}:${seconds}`;
};

const formatDateForTelegramApiCall = (date: any) => {
  const dateToFormat = date ? date : new Date();

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    hour12: false,
  };
  const formattedDate = dateToFormat.toLocaleDateString("en-US", options);
  const [month, day, year, hours, minutes, seconds] = formattedDate.split(/[/, ,:,\s]+/);
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
};

const formatDateTimeForAxiosRequest = (date: any, url: any) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const timezoneOffsetMinutes = date.getTimezoneOffset();
  const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffsetMinutes / 60));
  const timezoneSign = timezoneOffsetMinutes < 0 ? "+" : "-";
  const timezone = `${timezoneSign}${String(timezoneOffsetHours).padStart(2, "0")}00`;

  if (url.includes("/admin/event/stop")) {
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${timezone}`;
  }

  if (url === "/admin/event") {
    return `${day}-${month}-${year} ${hours}:${minutes} ${timezone}`;
  }

  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const setDateToOneMonthEarlier = () => {
  const initialDate = new Date();
  initialDate.setMonth(initialDate.getMonth() - 1);
  return initialDate;
};

const calculateDateOneWeekEarlier = () => {
  const todaysDate = new Date();
  const sevenDaysAgoDate = new Date(todaysDate);
  sevenDaysAgoDate.setDate(todaysDate.getDate() - 7);

  return { todaysDate, sevenDaysAgoDate };
};

export {
  parseDateRange,
  parseDateToFormat,
  parseUnixToFormat,
  getDateWithTZ,
  formatTimezoneForCalendar,
  getTimeForTimer,
  countPercent,
  formatDateToCustomFormat,
  formatDateForApiCall,
  setDateToOneMonthEarlier,
  formatDateForTelegramApiCall,
  formatDateForCryptoApiCall,
  formatDateForProfitTable,
  formatDateForHistoryTable,
  calculateDateOneWeekEarlier,
  formatDateTimeForAxiosRequest,
};
// export const formatUTC = (dateInt, addOffset) => {
//   let date = new Date(dateInt);
//   const offsetDate = new Date();
//   if (addOffset === undefined) {
//     return date;
//   }
//   offsetDate.setTime(date.getTime() + -addOffset * 60 * 60000);
//   return offsetDate;
// };
