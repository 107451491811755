import React from "react";
import cx from "classnames";
import Success from "assets/svg/Success";
import Error from "assets/svg/Error";
import styles from "./index.module.scss";

const ValidationText = ({ isValid }) => {
  const getContent = () => {
    if (isValid) {
      return (
        <span className={styles.success}>
          Confirmed <Success />
        </span>
      );
    }

    return (
      <span className={styles.error}>
        Not Confirmed <Error />
      </span>
    );
  };

  return (
    <div className={cx(styles.validation)}>
      <div className={styles.inner}>{getContent()}</div>
    </div>
  );
};

export default ValidationText;
