import * as React from "react";

const GreenCheckSquare = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="126" height="126" viewBox="0 0 126 126" fill="none" {...props}>
    <g filter="url(#filter0_ddiiii_7561_64593)">
      <circle cx="63" cy="63" r="60" fill="#212745" />
    </g>
    <path
      d="M84.9012 40.0876L65.5586 34.9491C58.5425 33.0852 53.4682 35.6607 51.773 41.9333L46.0314 63.1778C44.3084 69.5532 47.4263 74.2168 54.4424 76.0807L73.785 81.2192C80.9141 83.1131 85.8508 80.5893 87.5738 74.2139L93.3154 52.9694C95.0106 46.6968 92.0303 41.9815 84.9012 40.0876Z"
      fill="url(#paint0_linear_7561_64593)"
    />
    <g opacity="0.5" filter="url(#filter1_f_7561_64593)">
      <path
        d="M80.4068 46.0147L68.3863 42.823C64.0262 41.6653 60.8035 43.5211 59.6313 47.8559L55.6614 62.5376C54.4701 66.9435 56.34 70.0906 60.7002 71.2483L72.7207 74.44C77.1511 75.6164 80.2867 73.7984 81.4781 69.3925L85.448 54.7108C86.6201 50.3759 84.8372 47.1911 80.4068 46.0147Z"
        fill="#248048"
      />
    </g>
    <g filter="url(#filter2_b_7561_64593)">
      <path
        d="M65.9583 44.3037H44.2178C35.7172 44.3037 30 50.2176 30 59.0139V79.3151C30 88.0865 35.7172 94.0005 44.2178 94.0005H65.9583C74.4589 94.0005 80.151 88.0865 80.151 79.3151V59.0139C80.151 50.2176 74.4589 44.3037 65.9583 44.3037Z"
        fill="url(#paint1_linear_7561_64593)"
      />
    </g>
    <g filter="url(#filter3_d_7561_64593)">
      <mask id="path-5-inside-1_7561_64593" fill="white">
        <path d="M52.0989 77.2228C51.5372 77.2228 50.9755 77.0116 50.5467 76.5867L44.5963 70.6902C43.7387 69.8404 43.7387 68.4638 44.5963 67.6164C45.4539 66.7666 46.8406 66.7641 47.6982 67.614L52.0989 71.9749L62.4501 61.7174C63.3076 60.8676 64.6943 60.8676 65.5519 61.7174C66.4095 62.5673 66.4095 63.9439 65.5519 64.7937L53.6511 76.5867C53.2223 77.0116 52.6606 77.2228 52.0989 77.2228Z" />
      </mask>
      <path
        d="M52.0989 77.2228C51.5372 77.2228 50.9755 77.0116 50.5467 76.5867L44.5963 70.6902C43.7387 69.8404 43.7387 68.4638 44.5963 67.6164C45.4539 66.7666 46.8406 66.7641 47.6982 67.614L52.0989 71.9749L62.4501 61.7174C63.3076 60.8676 64.6943 60.8676 65.5519 61.7174C66.4095 62.5673 66.4095 63.9439 65.5519 64.7937L53.6511 76.5867C53.2223 77.0116 52.6606 77.2228 52.0989 77.2228Z"
        fill="white"
      />
      <path
        d="M44.5963 67.6164L44.6719 67.6929L44.672 67.6928L44.5963 67.6164ZM52.0989 71.9749L52.0232 72.0512L52.0989 72.1262L52.1746 72.0512L52.0989 71.9749ZM65.5519 64.7937L65.4762 64.7173L65.5519 64.7937ZM53.6511 76.5867L53.7268 76.6631L53.6511 76.5867ZM52.0989 77.1153C51.5641 77.1153 51.0301 76.9143 50.6224 76.5103L50.471 76.6631C50.921 77.1089 51.5103 77.3304 52.0989 77.3304V77.1153ZM50.6224 76.5103L44.672 70.6138L44.5206 70.7666L50.471 76.6631L50.6224 76.5103ZM44.672 70.6138C43.8568 69.806 43.857 68.4981 44.6719 67.6929L44.5207 67.54C43.6205 68.4294 43.6207 69.8747 44.5206 70.7666L44.672 70.6138ZM44.672 67.6928C45.4878 66.8844 46.807 66.8822 47.6225 67.6903L47.7738 67.5376C46.8742 66.6461 45.42 66.6489 44.5206 67.5401L44.672 67.6928ZM47.6225 67.6903L52.0232 72.0512L52.1746 71.8985L47.7738 67.5376L47.6225 67.6903ZM52.1746 72.0512L62.5258 61.7938L62.3744 61.6411L52.0232 71.8985L52.1746 72.0512ZM62.5258 61.7938C63.3414 60.9855 64.6605 60.9855 65.4762 61.7938L65.6276 61.6411C64.7281 60.7497 63.2739 60.7497 62.3744 61.6411L62.5258 61.7938ZM65.4762 61.7938C66.2913 62.6016 66.2913 63.9095 65.4762 64.7173L65.6276 64.87C66.5276 63.9782 66.5276 62.5329 65.6276 61.6411L65.4762 61.7938ZM65.4762 64.7173L53.5754 76.5103L53.7268 76.6631L65.6276 64.87L65.4762 64.7173ZM53.5754 76.5103C53.1677 76.9143 52.6337 77.1153 52.0989 77.1153V77.3304C52.6875 77.3304 53.2768 77.1089 53.7268 76.6631L53.5754 76.5103Z"
        fill="white"
        mask="url(#path-5-inside-1_7561_64593)"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddiiii_7561_64593"
        x="0"
        y="0"
        width="126"
        height="126"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0627451 0 0 0 0 0.109804 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7561_64593" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.243137 0 0 0 0 0.431373 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_7561_64593" result="effect2_dropShadow_7561_64593" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_7561_64593" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0627451 0 0 0 0 0.109804 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_7561_64593" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.243137 0 0 0 0 0.431373 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="effect3_innerShadow_7561_64593" result="effect4_innerShadow_7561_64593" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="-1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0627451 0 0 0 0 0.109804 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="effect4_innerShadow_7561_64593" result="effect5_innerShadow_7561_64593" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0627451 0 0 0 0 0.109804 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="effect5_innerShadow_7561_64593" result="effect6_innerShadow_7561_64593" />
      </filter>
      <filter
        id="filter1_f_7561_64593"
        x="47.7824"
        y="34.9585"
        width="45.5438"
        height="47.3585"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="3.76344" result="effect1_foregroundBlur_7561_64593" />
      </filter>
      <filter
        id="filter2_b_7561_64593"
        x="6"
        y="20.3037"
        width="98.1509"
        height="97.6963"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7561_64593" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7561_64593" result="shape" />
      </filter>
      <filter
        id="filter3_d_7561_64593"
        x="31.9531"
        y="49.0801"
        width="52.2419"
        height="46.1426"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="3" />
        <feGaussianBlur stdDeviation="7.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.501961 0 0 0 0 0.282353 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7561_64593" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7561_64593" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_7561_64593"
        x1="96"
        y1="43.036"
        x2="54.4247"
        y2="31.8687"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#33B366" />
        <stop offset="1" stop-color="#6AF2A0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7561_64593"
        x1="80.151"
        y1="44.3036"
        x2="29.9234"
        y2="44.3811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#33B366" />
        <stop offset="1" stop-color="#6AF2A0" />
      </linearGradient>
    </defs>
  </svg>
);

export default GreenCheckSquare;
