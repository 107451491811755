import * as React from "react";

const RedTriangle = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="124" height="109" viewBox="0 0 124 109" fill="none" {...props}>
    <path
      d="M120.394 107.263H3.60631C3.32291 107.265 3.04414 107.192 2.79863 107.051C2.55313 106.911 2.34975 106.708 2.20941 106.463C2.06907 106.219 1.99683 105.941 2.00011 105.66C2.00339 105.379 2.08207 105.103 2.22807 104.862L60.6218 2.81062C60.7572 2.56521 60.9566 2.36047 61.1991 2.21786C61.4416 2.07525 61.7182 2 62 2C62.2818 2 62.5584 2.07525 62.8009 2.21786C63.0434 2.36047 63.2428 2.56521 63.3782 2.81062L121.772 104.862C121.918 105.103 121.997 105.379 122 105.66C122.003 105.941 121.931 106.219 121.791 106.463C121.65 106.708 121.447 106.911 121.201 107.051C120.956 107.192 120.677 107.265 120.394 107.263Z"
      stroke="#FF0000"
      stroke-width="3"
    />
    <path d="M62.001 90.1172V91.726" stroke="#FF0000" stroke-width="3" stroke-linecap="round" />
    <path d="M62.001 35.4844V77.3374" stroke="#FF0000" stroke-width="3" stroke-linecap="round" />
  </svg>
);

export default RedTriangle;
