// React Redux Styling
import React from "react";
import styles from "./index.module.scss";

// Components
import Button from "components/Button";

// Utils & Constants
import { useTranslation } from "react-i18next";
import { withdrawAndDepositToggleSettings } from "constants/adminSettings";

const TransferToggleSettings = ({ title, type, toggleBtnHandler, depositWithdrawSettings }) => {
  const { t } = useTranslation(["common", "button"]);

  const setBtnFunctionality = (value, btnType) => {
    if (!!depositWithdrawSettings) {
      const currentSetting = depositWithdrawSettings[`${type}${value}`];

      return btnType === currentSetting;
    }
  };

  const renderToggleBtn = (value, btnType) => {
    return (
      <Button
        disabled={setBtnFunctionality(value, btnType)}
        customBox
        height={48}
        styleBtn={{ lineHeight: "24px" }}
        onClick={() => {
          const setting = `${type}${value}`;
          toggleBtnHandler(setting, btnType);
        }}
      >
        {btnType === "ON" ? t("button:turnOn") : t("button:toggleOff")}
      </Button>
    );
  };

  return (
    <div className={styles["toggles"]}>
      <p className={styles["toggles__title"]}>{title}</p>

      <div className={styles["toggles__wrapper"]}>
        {withdrawAndDepositToggleSettings.map(({ text, value }) => {
          return (
            <div key={value} className={styles["toggles__row"]}>
              <span className={styles["toggles__text"]}>{text}</span>

              {renderToggleBtn(value, "ON")}
              {renderToggleBtn(value, "OFF")}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TransferToggleSettings;
