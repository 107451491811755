import moment from "moment";
import cx from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Loader from "components/Loader";
import { TextParser } from "utils/textParser";
import { parseDateToFormat } from "utils/date";
import { numberFormat } from "utils/numberFormat";
import LangComponent from "components/LangComponent";
import TimeContainer from "components/TimeContainer";
import ModalEventEdit from "containers/ModalEventEdit";
import ImageListModal from "containers/ImageListModal";
import ImageContainer from "containers/ImageContainer";

import styles from "./index.module.scss";

const EventDataRow = ({ title, description, isEditable = true, onClick, key }) => (
  <div className={styles["event__data-row"]} key={key}>
    <span className={styles["th"]}>{title}</span>
    <span className={styles["td"]}>
      <span className={styles["td-text"]}>{description === null ? "-" : description}</span>
      {isEditable && (
        <div className={styles["td-btn"]}>
          <Button type="edit" onClick={onClick} />
        </div>
      )}
    </span>
  </div>
);

const EventDetail = ({
  data,
  requestData,
  showTimer,
  saveBtn = true,
  publishBtn,
  cancelBtn,
  deleteBtn = true,
  setData = () => {},
  setRequestData = () => {},
  saveHandler = () => {},
  publishHandler = () => {},
  cancelHandler = () => {},
  deleteHandler = () => {},
  onFieldChange = () => {},
  saveWithPublish = () => {},
}) => {
  const [isLoading] = useState(false);
  const [eventData, setEventData] = useState(data);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditImageModal, setShowEditImageModal] = useState(false);
  const [editedValue, setEditedValue] = useState(null);
  const [editedFieldName, setEditedFieldName] = useState(null);
  const [editedFieldLabel, setEditedFieldLabel] = useState("");
  const [editedFieldType, setEditedFieldType] = useState("textarea");
  const { t } = useTranslation(["button", "event"]);

  const editField = (value, name, type, label) => {
    setEditedValue(value);
    setEditedFieldName(name);
    setEditedFieldType(type);
    setEditedFieldLabel(label);
    setShowEditModal(true);
  };

  React.useEffect(() => {
    // TODO: We should fix this when possible 🙏
    setEventData((prevData) => ({ ...data, image: prevData.image, imageUuid: prevData.imageUuid }));

    setRequestData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const imageLink = eventData?.image?.imageLink || eventData?.uk?.image?.imageLink || eventData?.imageLink;

  return (
    <div className={styles["event"]}>
      <div className={styles["content"]}>
        <div className={styles["event__head"]}>
          <div className={styles["event__head-img"]}>
            <ImageContainer
              imageLink={imageLink}
              imageUuid={eventData?.image?.imageUuid || eventData?.uk?.image?.imageUuid}
            />
          </div>
          <div className={styles["event__head-action"]}>
            <div className={styles["event__head-action-btns"]}>
              <div className={styles["btn"]}>
                <Button
                  type="edit"
                  width={38}
                  height={38}
                  onClick={() => {
                    setShowEditImageModal(true);
                  }}
                />
              </div>

              {saveBtn && (
                <div className={styles["btn"]}>
                  <Button onClick={() => saveHandler(requestData)}>{t("button:save")}</Button>
                </div>
              )}

              {publishBtn && (
                <div className={styles["btn"]}>
                  <Button
                    onClick={() => {
                      saveWithPublish({
                        ...eventData,
                        startReference: Number(eventData.startReference),
                        controlReference: Number(eventData.controlReference),
                        startTime: parseDateToFormat(eventData.startTime),
                        betEndTime: parseDateToFormat(eventData.betEndTime),
                        finishTime: parseDateToFormat(eventData.finishTime),
                      });
                    }}
                  >
                    {t("button:publish")}
                  </Button>
                </div>
              )}

              {cancelBtn && (
                <div className={styles["btn"]}>
                  <Button onClick={cancelHandler}>{t("button:cancelEvent")}</Button>
                </div>
              )}

              {deleteBtn && (
                <div className={cx(styles["btn"], styles["delete-btn"])}>
                  <Button type="delete" onClick={deleteHandler} />
                </div>
              )}
            </div>

            {showTimer && (
              <div className={styles["timer-wrap"]}>
                <TimeContainer
                  createTime={moment(eventData.startTime).subtract(10, "day")}
                  betEndTime={eventData.betEndTime}
                  startTime={eventData.startTime}
                  finishTime={eventData.finishTime}
                  viewType="pageType"
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles["event__data"]}>
          <EventDataRow
            title={t("event:subCategory")}
            description={eventData.subCategory}
            onClick={() => {
              editField(eventData.subCategory, "subCategory", "textarea", t("event:subCategory"));
            }}
          />
          <EventDataRow
            title={t("event:eventDescription")}
            description={TextParser(eventData.title)}
            onClick={() => {
              editField(eventData.title, "title", "textarea", t("event:eventDescription"));
            }}
          />
          <EventDataRow
            title={t("event:eventAdditionalInfo")}
            description={TextParser(eventData.additionalInfo)}
            onClick={() => {
              editField(eventData.additionalInfo, "additionalInfo", "textarea", t("event:eventAdditionalInfo"));
            }}
          />
          <EventDataRow
            title={t("event:startTime")}
            description={parseDateToFormat(eventData.startTime, "DD.MM.YYYY HH:mm UTCZ")}
            isEditable={data.status !== "PUBLISHED"}
            onClick={() => {
              editField(eventData.startTime, "startTime", "date", t("event:startTime"));
            }}
          />
          <EventDataRow
            title={t("event:betEndTime")}
            description={parseDateToFormat(eventData.betEndTime, "DD.MM.YYYY HH:mm UTCZ")}
            isEditable={data.status !== "PUBLISHED"}
            onClick={() => {
              editField(eventData.betEndTime, "betEndTime", "date", t("event:betEndTime"));
            }}
          />
          <EventDataRow
            title={t("event:finishTime")}
            description={parseDateToFormat(eventData.finishTime, "DD.MM.YYYY HH:mm UTCZ")}
            isEditable={data.status !== "PUBLISHED"}
            onClick={() => {
              editField(eventData.finishTime, "finishTime", "date", t("event:finishTime"));
            }}
          />
          <EventDataRow
            title={t("event:startReference")}
            description={numberFormat(eventData.startReference)}
            onClick={() => {
              editField(eventData.startReference, "startReference", "number", t("event:startReference"));
            }}
          />
          <EventDataRow
            title={t("event:controlReference")}
            description={numberFormat(eventData.controlReference)}
            onClick={() => {
              editField(eventData.controlReference, "controlReference", "number", t("event:controlReference"));
            }}
          />
        </div>

        <LangComponent eventList viewType="vertical" />
      </div>

      {isLoading && <Loader />}

      <ModalEventEdit
        type={editedFieldType}
        editedValue={editedValue}
        fieldName={editedFieldName}
        isOpen={showEditModal}
        setIsOpenModal={() => setShowEditModal(!showEditModal)}
        successHandler={(fieldName, value) => {
          setEventData({
            ...eventData,
            [fieldName]: value,
          });
          setRequestData({
            ...eventData,
            [fieldName]: value,
          });
          setData({
            ...eventData,
            [fieldName]: value,
          });
          onFieldChange(fieldName, value);
        }}
        title={editedFieldLabel}
      />

      <ImageListModal
        isOpen={showEditImageModal}
        setIsOpenModal={() => setShowEditImageModal(!showEditImageModal)}
        successHandler={(image) => {
          setEventData((eventData) => ({ ...eventData, imageUuid: image?.imageUuid, image }));
          setData((data) => ({ ...data, imageUuid: image?.imageUuid, image }));
          onFieldChange("imageUuid", image.imageUuid);
        }}
      />
    </div>
  );
};

export default EventDetail;
