// import { spaceInNumberRegex } from "./regex";

import { isNumber } from "lodash";

export const numberFormat = (x: any) => {
  if (!isNumber(x) && !x) {
    return x;
  }

  if (typeof x === "string") {
    // @ts-ignore
    return Intl.NumberFormat("uk-UA").format(x.replaceAll(" ", ""));
  }

  // const parts = x.toString().split(".");
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  // return parts.join(".");

  return Intl.NumberFormat("uk-UA").format(x);
};
