import API from "../api";

export const getCommissionsMethod = () => API.get("/admin/system-settings");

export const setSystemSettingMethod = (data) => API.put("/admin/system-settings", data);

export const getDepositWithdrawSettingMethod = () => API.get("/admin/system-settings/routing");

export const toggleDepositWithdrawSettingMethod = (setting, toggle) =>
  API.post(`/admin/system-settings/routing?permissionsRoute=${setting}&onOff=${toggle}`);
