export const roles = [
  {
    value: "ROLE_ADMIN",
    label: "Administrator",
    access: "повний доступ",
  },

  {
    value: "ROLE_SUB_ADMIN",
    label: "Sub-Administrator",
    access:
      "доступ: все, за виключенням адміністратора; можливості: повне право редагування",
  },

  {
    value: "ROLE_TECH_SUPPORT",
    label: "Technical Support",
    access: "доступ: користувачів, можливості: призупинити діяльність",
  },

  {
    value: "ROLE_MARKETER",
    label: "Marketer",
    access: "доступ: статистика, можливість: спостерігати",
  },
];
