export const NOTIFICATION_TABLE_HEADERS = [
  {
    key: "create_date",
    label: "Дата створення",
  },
  {
    key: "end_date",
    label: "Дата закінчення",
  },
  {
    key: "notification_text",
    label: "Текст повідомлення",
  },
  {
    key: "status",
    label: "Статус",
  },
  {
    key: "actions",
    label: "",
  },
];

export const USER_TABLE_HEADERS = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "username",
    label: "Username",
    sort: true,
    sortOptions: [
      { value: "asc", label: "" },
      { value: "desc", label: "" },
    ],
  },
  {
    key: "email",
    label: "Email",
    sort: true,
    sortOptions: [
      { value: "asc", label: "" },
      { value: "desc", label: "" },
    ],
  },
  {
    key: "verified",
    label: "Дані верифікації",
  },
  {
    key: "balance",
    label: "Баланс",
  },
  {
    key: "activity_history",
    label: "Історія активності",
  },
  {
    key: "out_history",
    label: "Історія виведення",
  },
  {
    key: "add_history",
    label: "Історія поповнення",
  },
  {
    key: "lang",
    label: "Мова",
    sort: true,
    sortOptions: [
      { value: "eng", label: "English" },
      { value: "ukr", label: "Ukrainian" },
      { value: "pol", label: "Polish" },
      { value: "fre", label: "French" },
      { value: "ger", label: "German" },
      { value: "spa", label: "Spanish" },
      { value: "kaz", label: "Kazakh" },
      { value: "no_info", label: "No info" },
    ],
  },
  {
    key: "marketing",
    label: "Маркетинг",
    sort: false,
    sortOptions: [
      { value: "1", label: "Referral link" },
      { value: "2", label: "Research" },
      { value: "3", label: "Podcasting" },
      { value: "4", label: "Video" },
      { value: "5", label: "Experimental" },
    ],
  },
  {
    key: "actions",
    label: "",
  },
];

export const ACTIVITY_HISTORY_HEADERS = [
  {
    key: "operationTimestamp",
    label: "Дата\\час",
  },
  {
    key: "subCategory",
    label: "Підтема події",
  },
  {
    key: "startReference",
    label: "Початкове значення",
  },
  {
    key: "controlReference",
    label: "Граничне значення",
  },
  {
    key: "expirationReference",
    label: "Значення експірації",
  },
  {
    key: "bidUpCoefficient",
    label: "Коеф. так",
  },
  {
    key: "bidDownCoefficient",
    label: "Коеф. ні",
  },
  {
    key: "totalAmount",
    label: "Загальний пул",
  },
  {
    key: "eventBidDirection",
    label: "Напрямок",
  },
  {
    key: "bidAmount",
    label: "Ставка",
  },
  {
    key: "balanceBeforeBid",
    label: "Кошти до ставки",
  },
  {
    key: "balanceAfterBid",
    label: "Кошти після ставки",
  },
  {
    key: "totalGainAmount",
    label: "Виграш",
  },
  {
    key: "feeAmount",
    label: "Комісія сервісу",
  },
  {
    key: "clearGainAmount",
    label: "Чиста сума виграшу",
  },
  {
    key: "balanceAfterExpiration",
    label: "Баланс після експірації",
  },
  {
    key: "bidStatus",
    label: "Статус",
  },
  {
    key: "actions",
    label: "",
  },
];

export const WITHDRAW_HISTORY_HEADERS = [
  {
    key: "operationTime",
    label: "Дата\\час",
  },
  {
    key: "paymentType",
    label: "Метод оплати",
  },
  {
    key: "currency",
    label: "Валюта",
  },
  {
    key: "value",
    label: "Сума виводу",
  },
  {
    key: "fee",
    label: "Комісія",
  },
  {
    key: "userBalance",
    label: "Залишок балансу",
  },
  {
    key: "to",
    label: "Карта",
  },
  {
    key: "supportCode",
    label: "Support code",
  },
  {
    key: "status",
    label: "Статус",
  },
];

export const ADD_HISTORY_HEADERS = [
  {
    key: "operationTime",
    label: "Дата\\час",
  },
  {
    key: "paymentType",
    label: "Метод оплати",
  },
  {
    key: "currency",
    label: "Валюта",
  },
  {
    key: "value",
    label: "Сума до зарахування USDT",
  },
  {
    key: "fee",
    label: "Комісія",
  },
  {
    key: "userBalance",
    label: "Залишок балансу",
  },
  {
    key: "supportCode",
    label: "Support code",
  },
  {
    key: "status",
    label: "Статус",
  },
];

export const BALANCE_HEADERS = [
  {
    key: "username",
    label: "Username",
    sort: true,
    sortOptions: [
      { value: "asc", label: "" },
      { value: "desc", label: "" },
    ],
  },
  {
    key: "email",
    label: "Email",
    sort: true,
    sortOptions: [
      { value: "asc", label: "" },
      { value: "desc", label: "" },
    ],
  },
  {
    key: "balance",
    label: "Баланс",
  },
  {
    key: "add_balance",
    label: "Додати баланс",
  },
  {
    key: "minus_balance",
    label: "Зняти з балансу",
  },
];

export const ADMIN_TABLE_HEADERS = [
  {
    key: "online",
    label: "",
  },
  {
    key: "username",
    label: "Логін",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "status",
    label: "Статус",
  },
  {
    key: "system_time",
    label: "Час у системі",
  },
  {
    key: "last_authorization",
    label: "Остання авторізація",
  },
  {
    key: "time",
    label: "",
  },
  {
    key: "role",
    label: "Посада",
  },
  {
    key: "allowed_functionality",
    label: "Дозволений функціонал",
  },
  {
    key: "lost_password",
    label: "Втратив пароль",
  },
  {
    key: "actions",
    label: "",
  },
];

export const HOURS_WORKED_TABLE_HEADERS = [
  {
    key: "loginDate",
    label: "Дата",
  },
  {
    key: "loginTime",
    label: "Вхід",
  },
  {
    key: "logoutTime",
    label: "Вихід",
  },
  {
    key: "sessionTime",
    label: "Час у системі",
  },
];

export const USER_ACTIONS_TABLE_HEADERS = [
  {
    key: "actionDate",
    label: "Дата",
  },
  {
    key: "actionTime",
    label: "Час",
  },
  {
    key: "action",
    label: "Дії",
  },
];
