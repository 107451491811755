import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUsersListReq,
  fetchUsersForAdminTbl,
  fetchUserForAdminTbl,
  createUserForAdminTbl,
  updateUserRoleForAdminTbl,
  searchUsersInAdminTbl,
  blockUserInAdminTbl,
  unblockUserInAdminTbl,
  deleteUserInAdminTbl,
  resetUserPassInAdminTbl,
  fetchUserActionsInAdminTbl,
  fetchUserhoursInAdminTbl,
  decrementCustomerBalanceReq,
  getCustomersListReq,
  incrementCustomerBalanceReq,
  downloadUserhoursInAdminTbl,
  downloadAdminActions,
  blockUserInUsersTbl,
  unblockUserInUsersTbl,
  deleteUserInUsersTbl,
  fetchUserBidsActivity,
} from "api/users";
import { NotificationManager } from "react-notifications";
import { toasterCreator } from "../../utils/toasterCreator";
import { saveAs } from "file-saver";

export const clearLoggedUser = () => {
  return {
    type: "users/CLEAR_LOGGED_USER",
  };
};

export const saveLoggedUserRole = (role) => {
  return {
    type: "users/SAVE_LOGGED_USER_ROLE",
    role,
  };
};

export const setPageSize = (pageSize) => {
  return {
    type: "users/SET_PAGE_SIZE",
    pageSize,
  };
};

export const setTotalPages = (totalPages) => {
  return {
    type: "users/SET_TOTAL_PAGES",
    totalPages,
  };
};

export const setCurrentPage = (currentPage) => {
  return {
    type: "users/SET_CURRENT_PAGE",
    currentPage,
  };
};

export const clearUserBids = () => {
  return {
    type: "users/CLEAR_USER_BIDS",
  };
};

export const getUsers = createAsyncThunk("users/getUsers", async (data, { rejectWithValue }) => {
  try {
    const response = await getUsersListReq(data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const getUsersForAdminTbl = createAsyncThunk(
  "users/getUsersForAdminTbl",
  async ({ page, size }, { rejectWithValue }) => {
    try {
      const { data } = await fetchUsersForAdminTbl(page, size);
      return data;
    } catch (error) {
      NotificationManager.error("", error?.response?.data?.error, 6000);
      return rejectWithValue("Something went wrong");
    }
  },
);

export const getUserForAdminTbl = createAsyncThunk("users/getUserForAdminTbl", async (id, { rejectWithValue }) => {
  try {
    const { data } = await fetchUserForAdminTbl(id);
    return data;
  } catch (error) {
    return rejectWithValue("Something went wrong");
  }
});

export const createUser = createAsyncThunk("users/createUser", async (userData, { rejectWithValue }) => {
  try {
    const { status, data } = await createUserForAdminTbl(userData);
    if (status === 200 || status === 201) {
      NotificationManager.success("", "User created sucesfully", 6000);
      return data;
    }
  } catch (error) {
    const errorMessage = error.response.data?.fieldErrors
      ? error.response.data?.fieldErrors?.email
      : error.response.data?.errorMessages[0]
        ? error.response.data?.errorMessages[0]
        : "Something went wrong, please try again later!";

    NotificationManager.error("", errorMessage, 6000);

    return rejectWithValue("Something went wrong");
  }
});

export const updateUser = createAsyncThunk("users/updateUser", async ({ id, newRole }, { rejectWithValue }) => {
  try {
    const { status, data } = await updateUserRoleForAdminTbl(id, newRole);
    if (status === 200 || status === 201) {
      NotificationManager.success("", "User updated successfully", 6000);
      return data;
    }
  } catch (error) {
    NotificationManager.error("", "Something went wrong, please try again later!", 6000);
    return rejectWithValue("Something went wrong");
  }
});

export const searchUsers = createAsyncThunk("users/searchUsers", async (keyword, { rejectWithValue }) => {
  try {
    const { data } = await searchUsersInAdminTbl(keyword);
    return data;
  } catch (error) {
    NotificationManager.error("", "Something went wrong, please try again later!", 6000);
    return rejectWithValue("ERROR TEST MOCK");
  }
});

export const blockUser = createAsyncThunk("users/blockUser", async (id, { rejectWithValue }) => {
  try {
    const { status, data } = await blockUserInAdminTbl(id);
    if (status === 200 || status === 201) {
      toasterCreator.users.userBlocked();
      return data;
    }
  } catch (error) {
    NotificationManager.error("", "Something went wrong, please try again later!", 6000);
    return rejectWithValue("Something went wrong");
  }
});

export const unblockUser = createAsyncThunk("users/unblockUser", async (id, { rejectWithValue }) => {
  try {
    const { status, data } = await unblockUserInAdminTbl(id);
    if (status === 200 || status === 201) {
      toasterCreator.users.userUnblocked();
      return data;
    }
  } catch (error) {
    NotificationManager.error("", "Something went wrong, please try again later!", 6000);

    return rejectWithValue("Something went wrong");
  }
});

export const deleteUser = createAsyncThunk("users/deleteUser", async (id, { rejectWithValue }) => {
  try {
    const { status, data } = await deleteUserInAdminTbl(id);
    if (status === 200 || status === 201) {
      toasterCreator.users.userDeleted();
      return data;
    }
  } catch (error) {
    NotificationManager.error("", "Something went wrong, please try again later!", 6000);

    return rejectWithValue("Something went wrong");
  }
});

export const resetUserPass = createAsyncThunk(
  "users/resetUserPass",
  async ({ userId, payload }, { rejectWithValue }) => {
    try {
      const { status, data } = await resetUserPassInAdminTbl(userId, payload);
      if (status === 200 || status === 201) {
        toasterCreator.users.passwordReset();
        return data;
      }
    } catch (error) {
      NotificationManager.error("", "Passwords dont match or size must be more than 8 characters.", 6000);

      return rejectWithValue("Something went wrong");
    }
  },
);

export const getUserActions = createAsyncThunk(
  "users/getUserActions",
  async ({ id, startDate, endDate }, { rejectWithValue }) => {
    try {
      const { status, data } = await fetchUserActionsInAdminTbl(id, startDate, endDate);
      if (status === 200 || status === 201) {
        return data;
      }
    } catch (error) {
      if (error.response.data === 400) {
        NotificationManager.error("", error.response.data.errorMessages[0], 6000);
        return;
      }

      NotificationManager.error("", "Unable to fetch user actions, please try again later!", 6000);

      return rejectWithValue("Something went wrong");
    }
  },
);

export const getUserHours = createAsyncThunk(
  "users/getUserHours",
  async ({ id, startDate, endDate }, { rejectWithValue }) => {
    try {
      const { status, data } = await fetchUserhoursInAdminTbl(id, startDate, endDate);
      if (status === 200 || status === 201) {
        return data;
      }
    } catch (error) {
      if (error.response.data === 400) {
        NotificationManager.error("", error.response.data.errorMessages[0], 6000);
        return;
      }

      NotificationManager.error("", "Unable to fetch user hours, please try again later!", 6000);

      return rejectWithValue("Something went wrong");
    }
  },
);

export const downloadUserHoursToFile = createAsyncThunk(
  "users/downloadUserHoursToFile",
  async ({ userId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const { status, data } = await downloadUserhoursInAdminTbl(userId, startDate, endDate);
      if (status === 200 || status === 201) {
        saveAs(data, "userHours.xlsx");
      }
    } catch (error) {
      if (error.response.data === 400) {
        NotificationManager.error("", error.response.data.errorMessages[0], 6000);
        return;
      }

      NotificationManager.error("", "Unable to download user hours, please try again later!", 6000);

      return rejectWithValue("Something went wrong");
    }
  },
);

export const downloadUserActionsToFile = createAsyncThunk(
  "users/downloadUserActionsToFile",
  async ({ userId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const { status, data } = await downloadAdminActions(userId, startDate, endDate);
      if (status === 200 || status === 201) {
        saveAs(data, "Actions_statistic.xlsx");
      }
    } catch (error) {
      if (error.response.data === 400) {
        NotificationManager.error("", error.response.data.errorMessages[0], 6000);
        return;
      }

      NotificationManager.error("", "Unable to download user actions, please try again later!", 6000);

      return rejectWithValue("Something went wrong");
    }
  },
);

export const getCustomers = createAsyncThunk("users/getCustomers", async (data, { rejectWithValue }) => {
  try {
    const response = await getCustomersListReq(data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const incrementCustomerBalance = createAsyncThunk(
  "users/incrementCustomerBalance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await incrementCustomerBalanceReq(data);
      if (response.status === 200 || response.status === 201) {
        toasterCreator.updateBalance.balanceAdded();
        return response.data;
      }
    } catch (error) {
      if (error?.response?.data?.errorMessages?.[0]) {
        NotificationManager.error("", error?.response?.data?.errorMessages?.[0], 6000);
      } else {
        NotificationManager.error("", "Щось пішло не так, спробуйте пізніше");
      }
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const decrementCustomerBalance = createAsyncThunk(
  "users/decrementCustomerBalance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await decrementCustomerBalanceReq(data);
      if (response.status === 200 || response.status === 201) {
        toasterCreator.updateBalance.balanceDec();
        return response.data;
      }
    } catch (error) {
      if (error?.response?.data?.errorMessages?.[0]) {
        NotificationManager.error("", error?.response?.data?.errorMessages?.[0], 6000);
      } else {
        NotificationManager.error("", "Щось пішло не так, спробуйте пізніше");
      }
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const blockUserThunk = createAsyncThunk("users/blockUserThunk", async (id, { rejectWithValue }) => {
  try {
    const { status, data } = await blockUserInUsersTbl(id);
    if (status === 200 || status === 201) {
      toasterCreator.users.userBlocked();
      return data;
    }
  } catch (error) {
    NotificationManager.error("", "Something went wrong, please try again later!", 6000);
    return rejectWithValue("Something went wrong");
  }
});

export const unblockUserThunk = createAsyncThunk("users/unblockUserThunk", async (id, { rejectWithValue }) => {
  try {
    const { status, data } = await unblockUserInUsersTbl(id);
    if (status === 200 || status === 201) {
      toasterCreator.users.userUnblocked();
      return data;
    }
  } catch (error) {
    NotificationManager.error("", "Something went wrong, please try again later!", 6000);
    return rejectWithValue("Something went wrong");
  }
});

export const deleteUserThunk = createAsyncThunk("users/deleteUserThunk", async (id, { rejectWithValue }) => {
  try {
    const { status, data } = await deleteUserInUsersTbl(id);
    if (status === 200 || status === 201) {
      toasterCreator.users.userDeleted();
      return data;
    }
  } catch (error) {
    NotificationManager.error("", "Something went wrong, please try again later!", 6000);

    return rejectWithValue("Something went wrong");
  }
});

export const userBidsActivityThunk = createAsyncThunk(
  "users/userBidsActivityThunk",
  async ({ username, currentPage }, { rejectWithValue }) => {
    try {
      const { status, data } = await fetchUserBidsActivity(username, currentPage);
      if (status === 200 || status === 201) {
        return data;
      }
    } catch (error) {
      NotificationManager.error("", "Unable to fetch user bids activity, please try again later!", 6000);

      return rejectWithValue("Something went wrong");
    }
  },
);

const initialState = {
  isLoading: false,
  isError: null,
  data: null,
  adminTableData: [],
  user: null,
  loggedUser: null,
  loggedUserRole: "",
  customers: [],
  pagination: {
    pageSize: 5,
    totalPages: 0,
    currentPage: 0,
    totalUsers: 0,
  },
  userActions: null,
  userHours: null,
  userBidsActivity: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase("users/CLEAR_LOGGED_USER", (state) => {
      state.loggedUser = null;
    });

    builder.addCase("users/SAVE_LOGGED_USER_ROLE", (state, action) => {
      state.loggedUserRole = action.role;
    });

    builder.addCase("users/SET_PAGE_SIZE", (state, action) => {
      state.pagination.pageSize = action.pageSize;
    });

    builder.addCase("users/SET_TOTAL_PAGES", (state, action) => {
      state.pagination.totalPages = action.totalPages;
    });

    builder.addCase("users/SET_CURRENT_PAGE", (state, action) => {
      state.pagination.currentPage = action.currentPage;
    });

    builder.addCase("users/CLEAR_USER_BIDS", (state) => {
      state.userBidsActivity = null;
    });

    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalUsers = action.payload.totalElements;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(getUsersForAdminTbl.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUsersForAdminTbl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.adminTableData = action.payload.content;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalUsers = action.payload.totalElements;
      })
      .addCase(getUsersForAdminTbl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(getUserForAdminTbl.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUserForAdminTbl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.loggedUser = action.payload;
      })
      .addCase(getUserForAdminTbl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(searchUsers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.adminTableData = action.payload.content;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalUsers = action.payload.totalElements;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(blockUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(blockUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(blockUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(unblockUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(unblockUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(unblockUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(resetUserPass.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(resetUserPass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(resetUserPass.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(getUserActions.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUserActions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.userActions = action.payload;
      })
      .addCase(getUserActions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(getUserHours.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUserHours.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.userHours = action.payload;
      })
      .addCase(getUserHours.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(downloadUserHoursToFile.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(downloadUserHoursToFile.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(downloadUserHoursToFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(downloadUserActionsToFile.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(downloadUserActionsToFile.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(downloadUserActionsToFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(getCustomers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.customers = action.payload;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalUsers = action.payload.totalElements;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(incrementCustomerBalance.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(incrementCustomerBalance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(incrementCustomerBalance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(decrementCustomerBalance.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(decrementCustomerBalance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(decrementCustomerBalance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(blockUserThunk.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(blockUserThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(blockUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(unblockUserThunk.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(unblockUserThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(unblockUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(userBidsActivityThunk.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(userBidsActivityThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.userBidsActivity = action.payload;
      })
      .addCase(userBidsActivityThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      });
  },
});

const { reducer } = usersSlice;

export const selectNotification = (state) => state.users;
export const selectUsersData = (state) => state.users.data;
export const selectUsersIsLoading = (state) => state.users.isLoading;
export const selectUsersError = (state) => state.users.isError;
export const adminTableUsers = (state) => state.users.adminTableData;
export const selectLoggedUserRole = (state) => state.users.loggedUserRole;
export const selectUserActions = (state) => state.users.userActions?.content;
export const selectUserHours = (state) => state.users.userHours?.adminTimeTrackingPage?.content;
export const selectUserTotalHours = (state) => state.users.userHours?.totalDuration;
export const selectUserBidsActivity = (state) => state.users.userBidsActivity?.content;

export const selectCustomersData = (state) => state.users.customers;
export const selectCustomersIsLoading = (state) => state.users.isLoading;
export const selectCustomersError = (state) => state.users.isError;

export const selectPageSize = (state) => state.users.pagination.pageSize;
export const selectTotalPages = (state) => state.users.pagination.totalPages;
export const selectCurrentPage = (state) => state.users.pagination.currentPage;
export const selectTotalUsers = (state) => state.users.pagination.totalUsers;

export const selectUsersBidsPageSize = (state) => state.users.userBidsActivity?.pageable?.pageSize;
export const selectUsersBidsTotalPages = (state) => state.users.userBidsActivity?.totalPages;
export const selectUsersBidsTotalBids = (state) => state.users.userBidsActivity?.totalElements;
export const selectUsersBidsCurrentPage = (state) => state.users.userBidsActivity?.pageable?.pageNumber;

export default reducer;
