import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

const StepButton = ({
  name,
  disabled,
  isDirty,
  isActive,
  isValid,
  isVisited,
  isStored,
  onClick,
}) => {
  return (
    <button
      className={cx(styles["steps-button"], {
        [styles.dirty]: isDirty,
        [styles.selected]: isActive,
        [styles.valid]: isValid,
        [styles.invalid]: !isValid,
        [styles.visited]: isVisited,
        [styles.stored]: isStored,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{name}</span>
    </button>
  );
};

export default StepButton;
