import React from "react";
import Button from "components/Button";

const CryptoCol = ({ data, headers, setType, setChildContractData, onStartChildTransfer }) => {
  return headers?.map((header, index) => {
    return (
      <td key={header.key} style={data?.blocked ? { background: "#444754" } : {}}>
        {header.key === "external" ? (
          <Button
            width={191}
            height={28}
            styleBtn={{
              padding: "2px 7px",
              fontSize: "20px",
              lineHeight: "normal",
              fontWeight: "400",
            }}
            onClick={() => {
              setChildContractData(data);
              setType("OTHER");
              onStartChildTransfer();
            }}
          >
            Сторонню адресу
          </Button>
        ) : header.key === "primary" ? (
          <Button
            width={180}
            height={28}
            styleBtn={{
              padding: "2px 8px",
              fontSize: "20px",
              lineHeight: "normal",
              fontWeight: "400",
            }}
            onClick={() => {
              setChildContractData(data);
              setType("MAIN");
              onStartChildTransfer();
            }}
          >
            Основну адресу
          </Button>
        ) : (
          <span>{data[header.key]}</span>
        )}
      </td>
    );
  });
};

export default CryptoCol;
