import API from "../api";

export const withdrawFromParentContractMethod = (data) => {
  return API.post("/admin/crypto/parent-wallet/withdraw", data);
};

export const withdrawFromChildContractMethod = (to, from, amount) => {
  const body = {
    to,
    from,
    operationTime: new Date(), // "2023-11-13T12:00:00Z"
    amount,
  };

  return API.post("/admin/crypto/child-wallet/withdraw", body);
};
