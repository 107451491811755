import React from "react";
import { formatDateForProfitTable } from "utils/date";
import TooltipComponent from "components/Tooltip";

const MainWalletCol = ({ data, headers }) => {
  return headers?.map((header, index) => {
    return (
      <td key={header.key}>
        {header.key === "status" ? (
          <>
            {data[header.key] === "CANCELED" ? (
              <span style={{ color: "#EB4755" }}>Помилка</span>
            ) : data[header.key] === "CONFIRMED" ? (
              <span style={{ color: "#4DB275" }}>Готово</span>
            ) : (
              <span style={{ color: "#FFD01F" }}>Очікується</span>
            )}
          </>
        ) : header.key === "operationTime" ? (
          <span>{formatDateForProfitTable(data?.operationTime)}</span>
        ) : header.key === "fromAddress" || header.key === "toAddress" ? (
          <>
            {data[header.key] ? (
              <span>
                <TooltipComponent line={2} count={50} trigger={data[header.key]} textTooltip={data[header.key]} />
              </span>
            ) : (
              "-"
            )}
          </>
        ) : header.key === "id" ? (
          <span>
            <TooltipComponent line={2} count={50} trigger={data[header.key]} textTooltip={data[header.key]} />
          </span>
        ) : header.key === "fee_trx" ? (
          <span>{data[header.key] ? data[header.key] : "-"}</span>
        ) : (
          <span>{data[header.key]}</span>
        )}
      </td>
    );
  });
};

export default MainWalletCol;
