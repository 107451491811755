import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import styles from "./TimeContainer.module.scss";
import Countdown, { zeroPad, calcTimeDelta } from "react-countdown";
import { EVENT_STATUS } from "components/Cards/EventCard/EventCard";
import { useTranslation } from "react-i18next";

const cx = classNames.bind(styles);

const TimeContainer = ({
  createTime,
  startTime,
  betEndTime,
  finishTime,
  isTimerDisabled,
  viewType = "cardType",
  eventStatus = "CREATED",
}) => {
  const { t } = useTranslation(["common"]);
  const countdownRef = useRef(null);
  const beginTime = Date.parse(
    eventStatus === EVENT_STATUS.CREATED ||
      eventStatus === EVENT_STATUS.PUBLISHED
      ? createTime
      : eventStatus === EVENT_STATUS.ACTIVE ||
        eventStatus === EVENT_STATUS.PASSIVE
      ? startTime
      : betEndTime
  );
  const endTime = Date.parse(
    eventStatus === EVENT_STATUS.CREATED ||
      eventStatus === EVENT_STATUS.PUBLISHED
      ? startTime
      : eventStatus === EVENT_STATUS.ACTIVE
      ? betEndTime
      : finishTime
  );

  const calculateReminderTime = () => {
    const currentTime = Date.parse(new Date());
    // const timeToStart = endTime - beginTime;
    // const operationTime = beginTime - beginTime;
    // const progress = (operationTime / timeToStart) * 100;
    const persent = (endTime - beginTime) / 100;
    const currentPersent = (endTime - currentTime) / persent;

    return (
      <div className={cx("timeContainer__progressLine")}>
        <div
          className={cx("progress-line")}
          style={{
            width: isTimerDisabled ? "100%" : `${100 - currentPersent}%`,
          }}
        ></div>
      </div>
    );
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const dd = days > 0 ? days + "d " : "";
    const hh = zeroPad(hours, 2);
    const mm = zeroPad(minutes, 2);
    const ss = zeroPad(seconds, 2);

    if (completed || isTimerDisabled) {
      return "00:00:00";
    } else {
      return `${dd}${hh}:${mm}:${ss}`;
    }
  };

  const getTitle = () => {
    switch (eventStatus) {
      case EVENT_STATUS.CREATED:
      case EVENT_STATUS.PUBLISHED:
        return t("timer.start");
      case EVENT_STATUS.ACTIVE:
        return t("timer.betEnd");
      case EVENT_STATUS.PASSIVE:
        return t("timer.expiration");
      default:
        return t("timer.finish");
    }
  };

  useEffect(() => {
    // FIX SOME CASE WHEN TIMER NOT START
    if (countdownRef && countdownRef.current) {
      countdownRef.current.api?.start();
    }
  }, [countdownRef, createTime, betEndTime, finishTime, eventStatus]);

  return (
    <div className={cx("timeContainer", viewType)}>
      <div className={cx("timeContainer__title")}>{getTitle()}</div>
      <div className={cx("timeContainer__time")}>
        <Countdown date={endTime} renderer={renderer} ref={countdownRef} />
      </div>
      {calculateReminderTime()}
    </div>
  );
};
export default TimeContainer;
