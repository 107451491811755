import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import GreenCheckMark from "assets/svg/GreenCheckMark";
import ReactDOM from "react-dom";
import classNames from "classnames/bind";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import { fixNestedModalsCloseTrigger } from "utils/fixNestedModalsCloseTrigger";
import ModalArrowBack from "assets/svg/ModalArrowBack";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const cx = classNames.bind(styles);

const ModalTransferSummary = ({
  isOpen,
  setIsOpenModal,
  type,
  isTransactionSuccesfull,
  fee,
  successHandler = () => {},
  cancelHandler = () => {},
  transferData,
  backBtnHandler,
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button"]);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  const setModalTitle = (type) => {
    switch (type) {
      case "TRX":
        return "Ваш запит на виведення TRON TRX прийнято";
      case "USDT":
        return "Ваш запит на виведення USDT TRC-20 прийнято";
      case "OTHER":
        return "Виведення на СТОРОННЮ адресу";
      case "MAIN":
        return "Виведення на ОСНОВНУ адресу";
      default:
        return "";
    }
  };

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div ref={innerRef} className={cx(styles["modal-transfer-summary"])} onClick={fixNestedModalsCloseTrigger}>
        <div className={styles["modal-transfer-summary__header-wrapper"]}>
          <div onClick={backBtnHandler} className={styles["modal-transfer-summary__arrow"]}>
            <ModalArrowBack />
          </div>

          {isTransactionSuccesfull ? (
            <span className={styles["modal-transfer-summary__header"]}>Ваша транзакція успішно відправлена</span>
          ) : (
            <span className={styles["modal-transfer-summary__header"]}>{setModalTitle(type)}</span>
          )}
        </div>

        <div className={styles["modal-transfer-summary__content"]}>
          <div className={cx(styles["content__title-container"])}>
            <div className={cx(styles["content__text-wrapper"])}>
              <span className={cx(styles["content__text"])}>{moment(new Date()).format("DD.MM.YYYY")}</span>

              <span className={cx(styles["content__text"])}>
                {transferData?.transactionId && `транзакції: ${transferData?.transactionId}`}
              </span>
            </div>

            <span className={cx(styles[("content__text", "yellow")])}>
              {isTransactionSuccesfull ? "В обробці" : "Очікує підтвердження"}
            </span>
          </div>

          <div className={styles["content__amount-container"]} style={{ width: "100%" }}>
            <div className={cx("content__line")}>
              <span className={cx("content__text")}>Сума виведення:</span>
              <span className={cx("content__text")}>{transferData?.sum} USDT</span>
            </div>

            <div className={cx("content__line")}>
              <span className={cx("content__text")}>Адреса отримувача:</span>
              <span className={cx("content__text")}>{transferData?.address}</span>
            </div>

            <div className={cx("content__line")}>
              <span className={cx("content__text")}>Мережеві збори:</span>
              <span className={cx("content__text")}>{fee?.trx ? fee.trx : 0} TRX</span>
            </div>

            <div className={cx("content__line")}>
              <span className={cx("content__text")}>Ви отримаєте:</span>
              <span className={cx("content__text")}>{transferData?.sum} USDT</span>
            </div>
          </div>

          {isTransactionSuccesfull ? (
            <div className={cx("content__checkmark")}>
              <GreenCheckMark />
            </div>
          ) : (
            <div className={cx("content__info-container")}>
              <span className={cx("content__info-text")}>
                Будь ласка, переконайтеся, що інформація, яку ви надаєте, є правильною.
              </span>

              <span className={cx("content__info-text")}>
                Ви несете відповідальність за правильність введених даних.
              </span>
            </div>
          )}
        </div>

        <div className={styles["modal-transfer-summary__btns-wrapper"]}>
          <Button
            styleBtn={{ width: "397px" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              successHandler();
            }}
          >
            {isTransactionSuccesfull ? t("main_page") : t("confirm")}
          </Button>
        </div>
      </div>
    </Modal>,
    document.getElementById("root"),
  );
};

export default ModalTransferSummary;
