import * as React from "react";

const AppleStore = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="50" viewBox="0 0 150 50" fill="none" {...props}>
    <path
      d="M6.09343 0H143.907C147.268 0 150 2.69 150 6V44C150 47.31 147.268 50 143.907 50H6.09343C2.73189 50 0 47.31 0 44V6C0 2.69 2.73189 0 6.09343 0Z"
      fill="white"
    />
    <path
      d="M32.3295 23.8584C32.2995 20.2784 35.2595 18.5384 35.3895 18.4584C33.7095 16.0184 31.1095 15.6784 30.1995 15.6484C28.0195 15.4184 25.8995 16.9584 24.7895 16.9584C23.6595 16.9584 21.9395 15.6784 20.0995 15.7084C17.7295 15.7484 15.4995 17.1184 14.2895 19.2584C11.7795 23.6084 13.6495 30.0084 16.0595 33.5284C17.2695 35.2484 18.6695 37.1784 20.5195 37.1084C22.3195 37.0384 22.9995 35.9584 25.1695 35.9584C27.3295 35.9584 27.9595 37.1084 29.8395 37.0684C31.7795 37.0384 32.9895 35.3384 34.1595 33.5984C35.5495 31.6284 36.1095 29.6784 36.1295 29.5784C36.0795 29.5584 32.3695 28.1384 32.3295 23.8584Z"
      fill="#121523"
    />
    <path
      d="M28.7796 13.3388C29.7496 12.1288 30.4096 10.4788 30.2296 8.79883C28.8296 8.85883 27.0696 9.76883 26.0596 10.9588C25.1696 12.0088 24.3696 13.7188 24.5696 15.3288C26.1496 15.4488 27.7696 14.5288 28.7796 13.3388Z"
      fill="#121523"
    />
    <path
      d="M58.4493 36.8618H55.9293L54.5493 32.5218H49.7493L48.4393 36.8618H45.9893L50.7393 22.0918H53.6793L58.4493 36.8618ZM54.1393 30.7018L52.8893 26.8518C52.7593 26.4518 52.5093 25.5318 52.1493 24.0718H52.0993C51.9493 24.7018 51.7193 25.6318 51.3993 26.8518L50.1693 30.7018H54.1393Z"
      fill="#121523"
    />
    <path
      d="M70.6594 31.4102C70.6594 33.2202 70.1694 34.6502 69.1794 35.7002C68.2994 36.6402 67.1994 37.1002 65.8994 37.1002C64.4894 37.1002 63.4794 36.6002 62.8594 35.5902V41.1802H60.4894V29.7102C60.4894 28.5702 60.4594 27.4102 60.3994 26.2102H62.4794L62.6094 27.9002H62.6494C63.4394 26.6302 64.6394 25.9902 66.2394 25.9902C67.4994 25.9902 68.5394 26.4902 69.3794 27.4802C70.2394 28.4802 70.6594 29.7802 70.6594 31.4102ZM68.2494 31.4902C68.2494 30.4502 68.0194 29.6002 67.5494 28.9302C67.0394 28.2302 66.3494 27.8802 65.4894 27.8802C64.9094 27.8802 64.3794 28.0802 63.8994 28.4602C63.4294 28.8502 63.1094 29.3502 62.9694 29.9802C62.8994 30.2702 62.8594 30.5102 62.8594 30.7002V32.4802C62.8594 33.2502 63.0994 33.9102 63.5694 34.4402C64.0394 34.9702 64.6594 35.2402 65.4194 35.2402C66.3094 35.2402 66.9994 34.8902 67.4994 34.2102C67.9994 33.5202 68.2494 32.6202 68.2494 31.4902Z"
      fill="#121523"
    />
    <path
      d="M82.8992 31.4102C82.8992 33.2202 82.4092 34.6502 81.4192 35.7002C80.5392 36.6402 79.4392 37.1002 78.1392 37.1002C76.7292 37.1002 75.7192 36.6002 75.0992 35.5902V41.1802H72.7292V29.7102C72.7292 28.5702 72.6992 27.4102 72.6392 26.2102H74.7192L74.8492 27.9002H74.8892C75.6792 26.6302 76.8792 25.9902 78.4792 25.9902C79.7392 25.9902 80.7792 26.4902 81.6192 27.4802C82.4792 28.4802 82.8992 29.7802 82.8992 31.4102ZM80.4892 31.4902C80.4892 30.4502 80.2592 29.6002 79.7892 28.9302C79.2792 28.2302 78.5892 27.8802 77.7292 27.8802C77.1492 27.8802 76.6192 28.0802 76.1392 28.4602C75.6692 28.8502 75.3592 29.3502 75.2092 29.9802C75.1392 30.2702 75.0992 30.5102 75.0992 30.7002V32.4802C75.0992 33.2502 75.3392 33.9102 75.8092 34.4402C76.2792 34.9702 76.8992 35.2402 77.6592 35.2402C78.5492 35.2402 79.2492 34.8902 79.7392 34.2102C80.2492 33.5202 80.4892 32.6202 80.4892 31.4902Z"
      fill="#121523"
    />
    <path
      d="M96.5992 32.7209C96.5992 33.9809 96.1592 35.0009 95.2892 35.7909C94.3292 36.6509 92.9892 37.0809 91.2692 37.0809C89.6792 37.0809 88.4092 36.7709 87.4492 36.1609L87.9992 34.1909C89.0392 34.8009 90.1792 35.1109 91.4192 35.1109C92.3092 35.1109 93.0092 34.9109 93.4992 34.5109C93.9992 34.1109 94.2492 33.5709 94.2492 32.9009C94.2492 32.3009 94.0392 31.8009 93.6392 31.3909C93.2292 30.9809 92.5492 30.6009 91.5992 30.2509C88.9892 29.2809 87.6992 27.8709 87.6992 26.0209C87.6992 24.8109 88.1592 23.8209 89.0692 23.0409C89.9792 22.2709 91.1892 21.8809 92.6992 21.8809C94.0392 21.8809 95.1592 22.1109 96.0492 22.5809L95.4492 24.5009C94.6092 24.0509 93.6692 23.8209 92.6092 23.8209C91.7792 23.8209 91.1192 24.0309 90.6492 24.4309C90.2592 24.8009 90.0592 25.2409 90.0592 25.7709C90.0592 26.3509 90.2892 26.8409 90.7392 27.2109C91.1292 27.5609 91.8492 27.9409 92.8892 28.3509C94.1592 28.8609 95.0892 29.4609 95.6892 30.1509C96.2992 30.8409 96.5992 31.7009 96.5992 32.7209Z"
      fill="#121523"
    />
    <path
      d="M104.439 27.9905H101.829V33.1605C101.829 34.4705 102.289 35.1305 103.209 35.1305C103.629 35.1305 103.979 35.0905 104.259 35.0205L104.329 36.8205C103.859 36.9905 103.249 37.0805 102.489 37.0805C101.549 37.0805 100.829 36.7905 100.299 36.2205C99.769 35.6505 99.509 34.6905 99.509 33.3505V27.9805H97.959V26.2105H99.509V24.2605L101.829 23.5605V26.2105H104.439V27.9905Z"
      fill="#121523"
    />
    <path
      d="M116.179 31.45C116.179 33.09 115.709 34.43 114.779 35.48C113.799 36.56 112.499 37.1 110.879 37.1C109.319 37.1 108.069 36.58 107.139 35.55C106.209 34.52 105.749 33.21 105.749 31.63C105.749 29.98 106.229 28.63 107.179 27.58C108.139 26.53 109.429 26 111.049 26C112.609 26 113.869 26.52 114.819 27.56C115.729 28.56 116.179 29.86 116.179 31.45ZM113.729 31.5C113.729 30.52 113.519 29.69 113.099 28.99C112.599 28.14 111.889 27.72 110.969 27.72C110.019 27.72 109.289 28.14 108.799 28.99C108.369 29.69 108.169 30.54 108.169 31.55C108.169 32.53 108.379 33.37 108.799 34.06C109.309 34.91 110.029 35.33 110.949 35.33C111.849 35.33 112.559 34.9 113.079 34.04C113.509 33.32 113.729 32.48 113.729 31.5Z"
      fill="#121523"
    />
    <path
      d="M123.879 28.2902C123.649 28.2502 123.399 28.2302 123.129 28.2302C122.299 28.2302 121.649 28.5402 121.199 29.1702C120.809 29.7202 120.609 30.4302 120.609 31.2702V36.8602H118.239V29.5602C118.239 28.3302 118.219 27.2102 118.169 26.2102H120.229L120.319 28.2502H120.389C120.639 27.5502 121.029 26.9802 121.569 26.5602C122.099 26.1802 122.669 25.9902 123.279 25.9902C123.499 25.9902 123.699 26.0102 123.869 26.0302L123.879 28.2902Z"
      fill="#121523"
    />
    <path
      d="M134.459 31.0309C134.459 31.4509 134.429 31.8109 134.369 32.1009H127.269C127.299 33.1509 127.639 33.9609 128.299 34.5109C128.899 35.0009 129.669 35.2509 130.619 35.2509C131.669 35.2509 132.629 35.0809 133.489 34.7509L133.859 36.3909C132.849 36.8309 131.659 37.0509 130.289 37.0509C128.639 37.0509 127.339 36.5609 126.399 35.5909C125.459 34.6209 124.989 33.3109 124.989 31.6809C124.989 30.0809 125.429 28.7409 126.309 27.6709C127.229 26.5309 128.469 25.9609 130.029 25.9609C131.559 25.9609 132.729 26.5309 133.509 27.6709C134.139 28.5909 134.459 29.7009 134.459 31.0309ZM132.199 30.4209C132.219 29.7209 132.059 29.1109 131.739 28.6009C131.329 27.9409 130.699 27.6109 129.859 27.6109C129.089 27.6109 128.459 27.9309 127.979 28.5709C127.589 29.0809 127.349 29.7009 127.279 30.4109L132.199 30.4209Z"
      fill="#121523"
    />
    <path
      d="M52.9996 16.8108H51.7596L51.0796 14.6808H48.7196L48.0696 16.8108H46.8696L49.1996 9.55078H50.6396L52.9996 16.8108ZM50.8696 13.7808L50.2596 11.8808C50.1996 11.6908 50.0796 11.2308 49.8896 10.5108H49.8696C49.7896 10.8208 49.6896 11.2708 49.5196 11.8808L48.9196 13.7808H50.8696Z"
      fill="#121523"
    />
    <path
      d="M58.8396 11.5703L56.8596 16.8103H55.7296L53.8096 11.5703H55.0596L55.9496 14.3403C56.0996 14.8003 56.2296 15.2403 56.3296 15.6603H56.3596C56.4496 15.2803 56.5796 14.8403 56.7396 14.3403L57.6196 11.5703H58.8396Z"
      fill="#121523"
    />
    <path
      d="M63.0596 16.8105L62.9696 16.2105H62.9396C62.5796 16.6905 62.0696 16.9305 61.4096 16.9305C60.4696 16.9305 59.8096 16.2705 59.8096 15.3905C59.8096 14.1005 60.9296 13.4305 62.8696 13.4305V13.3305C62.8696 12.6405 62.4996 12.3005 61.7796 12.3005C61.2596 12.3005 60.8096 12.4305 60.4096 12.6905L60.1696 11.9305C60.6496 11.6305 61.2596 11.4805 61.9696 11.4805C63.3396 11.4805 64.0296 12.2005 64.0296 13.6505V15.5805C64.0296 16.1105 64.0496 16.5205 64.1096 16.8305L63.0596 16.8105ZM62.8996 14.2005C61.6096 14.2005 60.9596 14.5105 60.9596 15.2605C60.9596 15.8105 61.2896 16.0805 61.7596 16.0805C62.3496 16.0805 62.8996 15.6305 62.8996 15.0105V14.2005Z"
      fill="#121523"
    />
    <path
      d="M66.6197 10.7103C66.2297 10.7103 65.9297 10.4103 65.9297 10.0103C65.9297 9.61031 66.2397 9.32031 66.6397 9.32031C67.0397 9.32031 67.3597 9.61031 67.3497 10.0103C67.3497 10.4303 67.0497 10.7103 66.6197 10.7103ZM66.0597 11.5703H67.2197V16.8003H66.0597V11.5703Z"
      fill="#121523"
    />
    <path d="M69.2896 9.16992H70.4496V16.7999H69.2896V9.16992Z" fill="#121523" />
    <path
      d="M75.3896 16.8088L75.2997 16.2088H75.2697C74.9097 16.6888 74.3996 16.9288 73.7396 16.9288C72.7996 16.9288 72.1396 16.2688 72.1396 15.3888C72.1396 14.0988 73.2596 13.4288 75.1997 13.4288V13.3288C75.1997 12.6388 74.8297 12.2988 74.1097 12.2988C73.5897 12.2988 73.1396 12.4288 72.7396 12.6888L72.4996 11.9188C72.9796 11.6188 73.5896 11.4688 74.2996 11.4688C75.6697 11.4688 76.3597 12.1888 76.3597 13.6388V15.5688C76.3597 16.0988 76.3796 16.5088 76.4296 16.8188H75.3896V16.8088ZM75.2296 14.1988C73.9396 14.1988 73.2896 14.5088 73.2896 15.2588C73.2896 15.8088 73.6196 16.0788 74.0896 16.0788C74.6796 16.0788 75.2296 15.6288 75.2296 15.0088V14.1988Z"
      fill="#121523"
    />
    <path
      d="M81.0796 16.9299C80.3396 16.9299 79.7896 16.6199 79.4296 15.9999H79.4096L79.3396 16.8099H78.3496C78.3796 16.3899 78.3896 15.9199 78.3896 15.3999V9.16992H79.5596V12.3399H79.5796C79.9296 11.7599 80.4796 11.4699 81.2496 11.4699C82.5096 11.4699 83.3896 12.5499 83.3896 14.1199C83.3896 15.7399 82.4096 16.9299 81.0796 16.9299ZM80.8396 12.3799C80.1696 12.3799 79.5596 12.9599 79.5596 13.7699V14.6899C79.5596 15.4099 80.1096 15.9999 80.8196 15.9999C81.6896 15.9999 82.2096 15.2899 82.2096 14.1599C82.2096 13.0999 81.6696 12.3799 80.8396 12.3799Z"
      fill="#121523"
    />
    <path d="M85.1094 9.16992H86.2694V16.7999H85.1094V9.16992Z" fill="#121523" />
    <path
      d="M92.5997 14.4692H89.1097C89.1297 15.4592 89.7897 16.0192 90.7597 16.0192C91.2797 16.0192 91.7497 15.9292 92.1697 15.7692L92.3497 16.5792C91.8597 16.7992 91.2797 16.8992 90.5997 16.8992C88.9597 16.8992 87.9897 15.8692 87.9897 14.2592C87.9897 12.6592 88.9797 11.4492 90.4697 11.4492C91.7997 11.4492 92.6497 12.4392 92.6497 13.9392C92.6497 14.1492 92.6397 14.3292 92.5997 14.4692ZM91.5397 13.6392C91.5397 12.8292 91.1297 12.2592 90.3897 12.2592C89.7197 12.2592 89.1997 12.8392 89.1197 13.6392H91.5397Z"
      fill="#121523"
    />
    <path
      d="M99.6499 16.9288C98.1199 16.9288 97.1299 15.7888 97.1299 14.2388C97.1299 12.6188 98.1399 11.4688 99.7399 11.4688C101.25 11.4688 102.26 12.5588 102.26 14.1488C102.26 15.7788 101.22 16.9288 99.6499 16.9288ZM99.6999 12.3188C98.8599 12.3188 98.3199 13.1088 98.3199 14.1988C98.3199 15.2788 98.8699 16.0588 99.6899 16.0588C100.51 16.0588 101.06 15.2188 101.06 14.1688C101.05 13.1088 100.52 12.3188 99.6999 12.3188Z"
      fill="#121523"
    />
    <path
      d="M108.51 16.8109H107.35V13.8009C107.35 12.8709 106.99 12.4109 106.29 12.4109C105.6 12.4109 105.13 13.0009 105.13 13.6909V16.8009H103.97V13.0709C103.97 12.6109 103.96 12.1109 103.93 11.5709H104.95L105 12.3809H105.03C105.34 11.8209 105.98 11.4609 106.69 11.4609C107.79 11.4609 108.51 12.3009 108.51 13.6709V16.8109Z"
      fill="#121523"
    />
    <path
      d="M116.12 12.4495H114.84V14.9895C114.84 15.6395 115.07 15.9595 115.52 15.9595C115.72 15.9595 115.9 15.9395 116.04 15.9095L116.07 16.7895C115.84 16.8795 115.54 16.9195 115.17 16.9195C114.25 16.9195 113.71 16.4095 113.71 15.0895V12.4495H112.95V11.5795H113.71V10.6195L114.85 10.2695V11.5695H116.13V12.4495"
      fill="#121523"
    />
    <path
      d="M122.28 16.8099H121.12V13.8199C121.12 12.8799 120.76 12.4099 120.06 12.4099C119.46 12.4099 118.9 12.8199 118.9 13.6499V16.7999H117.74V9.16992H118.9V12.3099H118.92C119.29 11.7399 119.81 11.4599 120.49 11.4599C121.6 11.4599 122.28 12.3199 122.28 13.6899V16.8099Z"
      fill="#121523"
    />
    <path
      d="M128.219 14.4692H124.729C124.749 15.4592 125.409 16.0192 126.379 16.0192C126.899 16.0192 127.369 15.9292 127.789 15.7692L127.969 16.5792C127.479 16.7992 126.899 16.8992 126.219 16.8992C124.579 16.8992 123.609 15.8692 123.609 14.2592C123.609 12.6592 124.599 11.4492 126.089 11.4492C127.419 11.4492 128.269 12.4392 128.269 13.9392C128.269 14.1492 128.259 14.3292 128.219 14.4692ZM127.159 13.6392C127.159 12.8292 126.749 12.2592 126.009 12.2592C125.339 12.2592 124.809 12.8392 124.739 13.6392H127.159Z"
      fill="#121523"
    />
  </svg>
);

export default AppleStore;
