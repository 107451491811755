import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import cx from "classnames";
import Modal from "components/CustomModal";
import { useOuterClick } from "utils/useOuterClick";
import Button from "components/Button";
import TextareaInput from "components/TextareaInput";
import CalendarInput from "components/DatePicker";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const initValue = (type, value) => {
  if (type === "date") {
    return new Date(value);
  }
  if (type === "number") {
    return Number(value);
  }
  return value;
};

const ModalEventEdit = ({
  title,
  isOpen,
  setIsOpenModal,
  editedValue,
  fieldName,
  type = "textarea",
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const { t } = useTranslation(["button"]);
  const [mounted, setMounted] = useState(false);
  const { control, handleSubmit, formState, getValues, setValue, reset } =
    useForm({
      reValidateMode: "onChange",
      mode: "onTouched",
      defaultValues: {
        [fieldName]: initValue(type, editedValue),
      },
    });

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      resetValues();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        resetValues();
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  const resetValues = () => {
    if (type === "date") {
      reset({ [fieldName]: new Date(editedValue) });
    } else if (type === "number") {
      reset({ [fieldName]: Number(editedValue) });
    } else {
      reset({ [fieldName]: editedValue });
    }
  };

  // useEffect(() => {
  //   console.log(type);
  //   console.log(editedValue);
  // }, [editedValue, fieldName]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div ref={innerRef} className={cx(styles["modal"], styles[type])}>
        <div className={styles["modal__title"]}>{title}</div>
        <div className={styles["modal__content"]}>
          <form action="" className={styles["modal__form"]}>
            {(type === "textarea" || type === "number") && (
              <TextareaInput
                control={control}
                name={fieldName}
                type="message"
                label="Message"
              />
            )}
            {type === "date" && (
              <div
                className={styles["datePickerWrap"]}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <CalendarInput
                  control={control}
                  name={fieldName}
                  placeholder={""}
                  isPlaceholderWhite
                  dateFormat={"dd.MM.yyyy, HH:mm 'GMT' XXX"}
                  customTimeInput
                  open
                />
              </div>
            )}
          </form>
        </div>
        <div className={styles["modal__btns"]}>
          <Button
            onClick={() => {
              successHandler(fieldName, getValues(fieldName));
              setIsOpenModal();
            }}
          >
            {t("save")}
          </Button>
          <Button
            onClick={() => {
              reset();
              cancelHandler();
              setIsOpenModal();
            }}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default ModalEventEdit;
