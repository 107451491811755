import React from "react";
import cx from "classnames";

import Button from "components/Button";
import Status from "components/Status";

import styles from "../index.module.scss";

const getShortText = (txt) => {
  if (txt?.toString().length > 98) {
    return txt.slice(0, 98) + "...";
  }
  return txt;
};

const NotificationCol = ({
  data,
  headers,
  deleteHandler = () => {},
  editHandler = () => {},
}) => {
  const isPastNotification = Math.floor(new Date().getTime() / 1000) > Math.floor(new Date(data.end_date_full).getTime() / 1000)
  return headers.map((header, index) => (
    <td
      key={header.key}
      className={cx({
        [styles.status]: header.key === "status",
      })}
      style={isPastNotification ? {background: "#444754"} : {}}
    >
      {header.key === "status" ? (
        <Status type={data[header.key]} />
      ) : header.key === "actions" ? (
        <div className={styles.actions}>
          <Button width={30} height={30} type="edit" onClick={() => editHandler(data)} />
          <Button
            width={30}
            height={30}
            type="delete"
            onClick={() => {
              deleteHandler(data);
            }}
          />
        </div>
      ) : data[header.key] === undefined ? (
        <span>-</span>
      ) : (
        <span>
          {index === 2 ? getShortText(data[header.key]) : data[header.key]}
        </span>
      )}
    </td>
  ));
};

export default NotificationCol;
