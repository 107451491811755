import React from "react";

const LikeImg = () => {
  return (
    <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.4473 24.61C44.4815 24.5931 41.7097 24.5566 40.745 24.61C39.4206 21.9125 37.3318 19.8293 34.5782 18.5548C32.8946 17.7752 30.8323 17.5484 29.4425 16.2354C28.9054 15.7287 28.51 15.0519 28.168 14.3381C26.9256 11.7438 26.2769 9.01901 25.3251 6.32097C24.8534 4.98223 24.2586 3.34339 23.0001 2.51907C20.4297 0.836004 18.0856 3.58798 17.5427 5.89288C16.5105 10.2786 17.7804 14.9845 19.8324 18.9036C15.3891 18.4744 10.8072 18.4112 6.4479 19.492C4.37984 20.0044 2.18152 21.172 2.01234 23.5283C1.89298 25.1851 2.64856 26.9454 3.88265 28.0626C4.28692 28.428 5.6108 29.2866 5.60405 29.2917C5.56357 29.322 4.86091 31.5881 4.70211 32.4859C4.22466 35.1865 6.06904 37.2224 8.62596 37.836C6.629 40.1414 8.1506 44.7632 11.6692 45.2268C11.5166 50.4219 17.6528 50.6897 21.3229 51.0083C31.2381 51.8691 34.3908 51.1352 41.0314 49.5132C41.7673 49.3334 44.9571 49.1239 45.7153 49.1223"
        stroke="#4DB275" strokeWidth="4" strokeMiterlimit="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M52.6952 20.8925C50.6467 20.8757 47.1742 19.9779 45.4639 21.4563C44.3998 22.3766 45.0902 25.6741 45.1113 26.969C45.1952 32.1849 45.1813 37.4018 45.1818 42.6183C45.1818 44.7937 44.2011 49.0482 45.953 50.7709C47.5814 52.3722 50.9267 51.925 53 51.9867"
        stroke="#4DB275" strokeWidth="4" strokeMiterlimit="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default LikeImg;
