import React from "react";
import { copyToBuffer } from "../../utils/copyToBuffer";
import cx from "classnames";

import styles from "./index.module.scss";
import Copy from "assets/svg/Copy";

const CopyToBufferButton = ({ getText = () => {}, sm, onCopy = () => {} }) => {
  return (
    <div
      className={cx({
        [styles.autocopy]: !sm,
        [styles.sm]: sm,
      })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        const txt = getText();
        copyToBuffer(txt);
        onCopy(txt);
      }}
      tabIndex={0}
    >
      {!sm ? (
        <>
          <span>Autocopy</span>
          <i className={styles["autocopy-icon"]}></i>
        </>
      ) : (
        <Copy />
      )}
    </div>
  );
};

export default CopyToBufferButton;
