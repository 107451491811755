import * as React from "react";

const GreenCheckMark = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="87" viewBox="0 0 120 87" fill="none" {...props}>
    <path
      d="M41.591 86.747C40.4653 86.7481 39.3504 86.5275 38.3105 86.0976C37.2705 85.6678 36.3259 85.0373 35.531 84.2423L2.61676 51.4163C1.7981 50.6278 1.14511 49.6845 0.695887 48.6415C0.246667 47.5986 0.0102135 46.4769 0.000323632 45.3418C-0.00956622 44.2068 0.207304 43.0811 0.638283 42.0305C1.06926 40.98 1.70572 40.0255 2.51051 39.2229C3.31531 38.4203 4.27232 37.7855 5.32572 37.3557C6.37912 36.9259 7.5078 36.7096 8.64591 36.7194C9.78402 36.7293 10.9088 36.9651 11.9545 37.4131C13.0003 37.8612 13.9461 38.5124 14.7368 39.3289L41.591 66.1111L105.474 2.39964C107.09 0.842479 109.256 -0.0191537 111.503 0.000323153C113.75 0.0198001 115.9 0.918831 117.489 2.50378C119.079 4.08873 119.98 6.23278 120 8.47415C120.019 10.7155 119.155 12.8749 117.594 14.4871L47.651 84.2423C46.8561 85.0373 45.9116 85.6678 44.8716 86.0976C43.8316 86.5275 42.7168 86.7481 41.591 86.747Z"
      fill="#4DB275"
    />
  </svg>
);

export default GreenCheckMark;
