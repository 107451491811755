import React from "react";
import "./index.scss";

const ArrowButton = ({ left = true, ref, ...props }) => (
  <>
    <button
      className={`arrow-button cleared-button ${left ? "left" : "right"}`}
      ref={ref}
      {...props}
    >
      <i className="arrow-button__icon" />
    </button>
  </>
);

export default ArrowButton;
