import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./index.module.scss";
import cx from "classnames";

// Components & Assets
import Button from "components/Button";
import Modal from "../../components/CustomModal";
import PinField from "components/PinField";
import GooglePlay from "assets/svg/GooglePlay";
import AppleStore from "assets/svg/AppleStore";
import Copy from "assets/svg/Copy";

// Libs and util funcs
import { useOuterClick } from "../../utils/useOuterClick";
import { useTranslation } from "react-i18next";
import { copyToBuffer } from "utils/copyToBuffer";

const ModalLoginTwoFa = ({
  qrImg,
  secretCode,
  twoFACode,
  setTwoFACode,
  isOpen,
  setIsOpenModal,
  cancelHandler = () => {},
  successHandler = () => {},
}) => {
  const { t } = useTranslation(["button"]);
  const [mounted, setMounted] = useState(false);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
        <div
          ref={innerRef}
          className={styles["modal"]}
          style={{
            pointerEvents: "auto",
          }}
        >
          <div className={cx(styles["modal-two-factor"])}>
            <div className={styles["modal-two-factor__title-wrapper"]}>
              <span className={styles["modal-two-factor__title"]}>Увімкнути 2FA</span>
            </div>

            <div className={styles["modal-two-factor__content"]}>
              <span className={cx(styles["content__text"])}>Відскануйте QR-код всередині мобільного застосунку</span>

              <div className={styles["links-container"]}>
                <div className={cx("link-wrapper")}>
                  <a
                    href={
                      "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US"
                    }
                    target={"blank"}
                  >
                    <GooglePlay />
                  </a>
                </div>
                <div className={cx("link-wrapper")}>
                  <a href={"https://apps.apple.com/ru/app/google-authenticator/id388497605"} target={"blank"}>
                    <AppleStore />
                  </a>
                </div>
              </div>

              <div className={styles["qr__container"]}>
                <div className={styles["qr__image-wrapper"]}>
                  <img src={`data:image/png;base64,${qrImg}`} alt="qr" />
                </div>

                <span className={styles["qr__text"]}>Секретний код</span>

                <div className={styles["code__wrapper"]}>
                  <input className={styles["code__text"]} value={secretCode} readOnly />

                  <div className={styles["copy-wrapper"]} onClick={() => copyToBuffer(secretCode)}>
                    <Copy width="24" height="24" />
                  </div>
                </div>
              </div>

              <div className={styles["code-container"]}>
                <span>Введіть ваш 2FA перевірочний код</span>
                <div className={cx("wrapperPin")}>
                  <PinField setCode={setTwoFACode} size={6} isTwoFA />
                </div>
              </div>
            </div>

            <div className={styles["modal-two-factor__btns-wrapper"]}>
              <Button
                autoFocus
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  successHandler(twoFACode);
                }}
                styleBtn={{ width: "168px" }}
                disabled={twoFACode?.length < 6}
              >
                {t("turnOn")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>,
    document.getElementById("root"),
  );
};

export default ModalLoginTwoFa;
