import React from "react";

import styles from "./index.module.scss";

const SettingsButton = ({ onClick, disabled }) => (
  <>
    {!disabled && (
      <div className={styles["settings-button"]}>
        <span className={styles["settings-button__text"]}>System settings</span>
        <button onClick={onClick} disabled={disabled}>
          <i className={styles["settings-button__icon"]} />
        </button>
      </div>
    )}
  </>
);

export default SettingsButton;
