// React && Styling
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  blockUserThunk,
  unblockUserThunk,
  deleteUserThunk,
  selectUsersData,
  selectUsersIsLoading,
  selectLoggedUserRole,
  selectUserBidsActivity,
  userBidsActivityThunk,
  clearUserBids,
} from "store/slices/users";
import {
  getUserDepositHistoryAction,
  getUserWithdrawHistoryAction,
  userDepositHistorySelector,
  userWithdrawHistorySelector,
  isLoadingSelector,
} from "store/slices/history";

// Components
import PaginationComponent from "components/PaginationComponent";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import Input from "components/Input";
import ModalUserHistory from "containers/ModalUserHistory";
import ModalWarning, { ErrorText, SuccessText } from "containers/ModalWarning";
import Loader from "components/Loader";

// Utils && Libs && Constants
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { USER_TABLE_HEADERS } from "constants/table";
import { numberFormat } from "utils/numberFormat";
import useDebounce from "../../hooks/useDebounce/useDebounce";

const mutationUserDataToTableFormat = (items = []) => {
  const tableData = {
    header: USER_TABLE_HEADERS,
    data: items?.map((item) => ({
      ...item,
      balance: numberFormat(item.balance) || [],
    })),
  };

  return tableData;
};

const UsersPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "modals"]);
  const [searchParams] = useSearchParams();
  const emailQuery = searchParams.get("email");
  const usernameQuery = searchParams.get("username");
  const langQuery = searchParams.get("lang");

  // Modal States
  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [openedModal, setOpenedModal] = useState({
    key: "",
    id: "",
  });
  const [modalHistoryType, setModalHistoryType] = useState("");
  const [warningModalType, setWarningModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  // Redux Selectors
  const isLoading = useSelector(selectUsersIsLoading);
  const isHistoryDataLoading = useSelector(isLoadingSelector);
  const listData = useSelector(selectUsersData);
  const loggedUserRole = useSelector(selectLoggedUserRole);
  const userBidsActivity = useSelector(selectUserBidsActivity);
  const userDepositHistoryData = useSelector(userDepositHistorySelector);
  const userWithdrawHistoryData = useSelector(userWithdrawHistorySelector);

  const [username, setUsername] = useState("");
  const [sortByObj, setSortByObj] = useState({ page: 0 });
  const [selectedPage, setSelectedPage] = useState(0);

  const { control, watch } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      search: "",
    },
  });

  const keyword = useDebounce(() => watch("search"), 700);

  const openModal = (type, item) => {
    if (type === "delete" || type === "status") {
      setWarningModalType(type);
      setIsOpenWarningModal(true);
    } else {
      setModalHistoryType(type);
      setIsOpenModalHistory(true);
    }
    setSelectedItem(item);
  };

  useEffect(() => {
    if (emailQuery || usernameQuery || langQuery) {
      return setSortByObj({
        sortField: usernameQuery ? "username" : emailQuery ? "email" : "",
        sortDirection: usernameQuery ? usernameQuery : emailQuery ? emailQuery : "",
        page: selectedPage,
        lang: langQuery ? langQuery?.toUpperCase() : "",
      });
    } else if (sortByObj?.page !== 0) {
      return setSortByObj({ page: selectedPage });
    }
  }, [emailQuery, usernameQuery, langQuery, selectedPage]);

  useEffect(() => {
    keyword ? dispatch(getUsers({ ...sortByObj, keyword })) : dispatch(getUsers(sortByObj));
  }, [sortByObj, dispatch, keyword]);

  useEffect(() => {
    document.title = t("common:user.users");
  }, []);

  const blockUserHandler = async () => {
    await dispatch(blockUserThunk(selectedItem.id));

    dispatch(getUsers());
  };

  const unblockUserHandler = async () => {
    await dispatch(unblockUserThunk(selectedItem.id));

    dispatch(getUsers());
  };

  const deleteUserHandler = async () => {
    await dispatch(deleteUserThunk(selectedItem.id));

    dispatch(getUsers());
  };

  const userBidsActivityHandler = (username) => {
    dispatch(
      userBidsActivityThunk({
        username,
      }),
    );
  };

  const userWithdrawHistoryModalHandler = (username) => {
    dispatch(getUserWithdrawHistoryAction({ username, page: 0 }));
  };

  const userDepositHistoryModalHandler = (username) => {
    dispatch(getUserDepositHistoryAction({ username, page: 0 }));
  };

  const handleUserHistoryModalChangePage = async (selectedPage) => {
    const currentPage = selectedPage - 1;

    switch (modalHistoryType) {
      case "out_history": {
        dispatch(getUserWithdrawHistoryAction({ username, currentPage }));
        break;
      }
      case "add_history": {
        dispatch(getUserDepositHistoryAction({ username, currentPage }));
        break;
      }
      default: {
        dispatch(userBidsActivityThunk({ username, currentPage }));
      }
    }
  };

  return (
    <div className={styles.users}>
      <div className={styles.header}>
        <span className={styles.title}>{t("common:user.users")}</span>
        <div className={styles.search}>
          <Input control={control} name="search" type="text" role="search" size="sm-2" />
        </div>
      </div>

      <div className={styles.body}>
        {!isLoading && (
          <>
            <div className={styles.table}>
              <TableComponent
                loggedUserRole={loggedUserRole}
                type={TABLE_VIEW.USERS}
                sort
                data={mutationUserDataToTableFormat(listData?.content)}
                openModal={(type, item) => {
                  setOpenedModal({
                    key: type,
                    id: item.id,
                  });
                  openModal(type, item);
                }}
                userHistoryOpenedModal={openedModal}
                deleteHandler={(item) => openModal("delete", item)}
                changeStatus={(item) => openModal("status", item)}
                userBidsActivityHandler={userBidsActivityHandler}
                setUsername={setUsername}
                userWithdrawHistoryModalHandler={userWithdrawHistoryModalHandler}
                userDepositHistoryModalHandler={userDepositHistoryModalHandler}
              />
            </div>
            <div className={styles.pagination}>
              <PaginationComponent
                itemsPerPage={1}
                totalPages={listData?.totalPages}
                forcePage={listData?.pageable?.pageNumber}
                onChanePage={(page) => {
                  setSelectedPage(page - 1);
                  dispatch(
                    getUsers({
                      ...sortByObj,
                      page: page - 1,
                    }),
                  );
                }}
              />
            </div>
          </>
        )}
        {isLoading && <Loader />}
      </div>

      <ModalUserHistory
        isOpen={isOpenModalHistory}
        type={modalHistoryType}
        data={selectedItem}
        setIsOpenModal={() => {
          setModalHistoryType("");
          setIsOpenModalHistory(false);
          setSelectedItem(null);
          setOpenedModal({
            key: "",
            id: "",
          });
        }}
        cancelHandler={() => {
          setModalHistoryType("");
          setOpenedModal({
            key: "",
            id: "",
          });
          setIsOpenModalHistory(false);
          setSelectedItem(null);
          dispatch(clearUserBids());
        }}
        successHandler={() => {}}
        title={t(`common:user.${modalHistoryType}`)}
        userBidsActivity={userBidsActivity}
        userDepositHistoryData={userDepositHistoryData}
        userWithdrawHistoryData={userWithdrawHistoryData}
        handleUserHistoryModalChangePage={handleUserHistoryModalChangePage}
        isLoading={isLoading}
        isHistoryDataLoading={isHistoryDataLoading}
        loggedUserRole={loggedUserRole}
        username={username}
      />

      <ModalWarning
        isOpen={isOpenWarningModal}
        setIsOpenModal={() => setIsOpenWarningModal(!isOpenWarningModal)}
        title={
          warningModalType === "delete" ? (
            <Trans i18nKey="modals:deleteUser">
              Ти впевнений, що хочеш <ErrorText isUpperCase>видалити</ErrorText> цього користувача?
            </Trans>
          ) : selectedItem?.blocked ? (
            <Trans i18nKey="modals:unblockUser">
              <SuccessText isUpperCase>розблокувати</SuccessText> цього користувача?
            </Trans>
          ) : (
            <Trans i18nKey="modals:blockUser">
              <ErrorText isUpperCase>заблокувати</ErrorText> цього користувача?
            </Trans>
          )
        }
        successHandler={
          warningModalType === "delete"
            ? deleteUserHandler
            : selectedItem?.blocked
              ? unblockUserHandler
              : blockUserHandler
        }
      />
    </div>
  );
};

export default UsersPage;
