import React from "react";
import cx from "classnames";
import Button from "components/Button";
import { roles } from "constants/roles";
import styles from "../index.module.scss";

const AdminCol = ({
  data,
  headers,
  setBlockModal,
  setUnblockModal,
  setDeleteModal,
  setResetPassModal,
  editHandler,
  setUserId,
  setAdminName,
  setUserEmail,
  setUserRole,
  userHoursHandler,
  userActionsHandler,
}) => {
  return headers?.map((header, index) => {
    return (
      <td
        key={header.key}
        style={data?.blocked ? { background: "#444754" } : {}}
      >
        {header.key === "online" ? (
          <span>
            {data.online ? (
              <div
                style={{
                  backgroundColor: "#16EC6B",
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                }}
              ></div>
            ) : (
              <div
                style={{
                  backgroundColor: "#EB4755",
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                }}
              ></div>
            )}
          </span>
        ) : header.key === "status" ? (
          <div>
            {data?.blocked ? "Banned" : data?.online ? "Active" : "Passive"}
          </div>
        ) : header.key === "system_time" ? (
          <div>{data?.sessionTime || "-"}</div>
        ) : header.key === "last_authorization" ? (
          <div>{data?.loginDataTime || "-"}</div>
        ) : header.key === "actions" ? (
          <div className={styles.actions}>
            <Button
              width={26}
              height={26}
              type="edit"
              onClick={() => {
                setUserId(data.id);
                setAdminName(data.username);
                setUserEmail(data.email);
                setUserRole(data.role);
                editHandler();
              }}
            />

            <Button
              width={26}
              height={26}
              type="show"
              onClick={() => {
                userActionsHandler(data.id);
                setUserId(data.id);
              }}
            />

            {data.blocked ? (
              <Button
                width={24}
                height={24}
                type="unblock"
                onClick={() => {
                  setUnblockModal();
                  setUserId(data.id);
                }}
              />
            ) : (
              <Button
                width={24}
                height={24}
                type="block"
                onClick={() => {
                  setBlockModal();
                  setUserId(data.id);
                }}
              />
            )}

            <Button
              width={34}
              height={38}
              type="delete"
              onClick={() => {
                setDeleteModal();
                setUserId(data.id);
              }}
            />
          </div>
        ) : header.key === "time" ? (
          <Button
            width={38}
            height={38}
            type="time"
            onClick={() => {
              userHoursHandler(data.id);
              setUserId(data.id);
            }}
          />
        ) : header.key === "role" ? (
          <span>
            {roles.length &&
              roles?.map((role) => {
                if (data[header.key][1]?.name === role.value) {
                  return role.label;
                }
                return null;
              })}
          </span>
        ) : header.key === "allowed_functionality" ? (
          <span>
            {data.role.length &&
              data.role?.map(({ name }) => {
                const matchedRole = roles.find((role) => role.value === name);

                if (matchedRole) {
                  return matchedRole.access;
                }
                return null;
              })}
          </span>
        ) : header.key === "lost_password" ? (
          <Button
            width={62}
            height={18}
            type="clear"
            styleBtn={{
              color: "#3ddde8",
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "700",
              lineHeight: "normal",
            }}
            onClick={() => {
              setResetPassModal();
              setUserId(data.id);
            }}
          >
            скинути
          </Button>
        ) : (
          <span>{data[header.key]}</span>
        )}
      </td>
    );
  });
};

export default AdminCol;
