import React from "react";

import { useSelector } from "react-redux";
import { selectEventCategoryList } from "../../../store/slices/eventCategory";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
import "./index.scss";

const CategoryList = ({ setIsOpenModal, categoryToEdit }) => {
  const categoryList = useSelector(selectEventCategoryList);
  const { t } = useTranslation(["button", "common"]);

  return (
    <div className="modal-event__list">
      <h2>{"Редагування категорії"}</h2>
      <div className="modal-event__list-inner">
        {categoryList.map((item, index) => {
          return (
            <div
              className="modal-event__list-item"
              key={`category${item.categoryUuid}`}
            >
              {!index && (
                <span className="label">{t("common:category.name")}</span>
              )}
              <Input
                name="categoryName"
                placeholder={""}
                value={item.categoryName}
                size="sm"
                disabled
              />
              <div className="edit-btn">
                <Button
                  type="edit"
                  width={18}
                  height={18}
                  onClick={() => categoryToEdit(item)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="modal-event__btns">
        <Button
          onClick={() => {
            setIsOpenModal();
          }}
        >
          {t("button:cancel")}
        </Button>
        <Button disabled={true} onClick={() => setIsOpenModal()}>
          {t("button:save")}
        </Button>
      </div>
    </div>
  );
};

export default CategoryList;
