import API from "../api";

export const signInAndCheckIf2faEnabledMethod = (credentials, token) => {
  return API.post("/admin/auth/before/signin", credentials, {
    headers: { recaptcha: token },
  });
};

export const enable2FaOnSigninMethod = (body) => API.post("/admin/auth/signin/on/2fa", body);

export const signinReq = (data) => API.post("/admin/auth/signin", data);

export const logoutReq = () => API.get("/admin/logout/");

export const verifyGoogleRecaptcha = (token) => API.post(`/api/recaptcha/submit?recaptcha=${token}`);
