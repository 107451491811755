import FR from "assets/svg/FR";
import ES from "assets/svg/ES";
import KZ from "assets/svg/KZ";
import PL from "assets/svg/PL";
import UA from "assets/svg/UA";
import DE from "assets/svg/DE";
import EN from "assets/svg/EN";

export const LANG_ARRAY = [
  {
    value: "uk",
    text: "Українська",
    icon: <UA />,
  },
  {
    value: "en",
    text: "Англійська",
    icon: <EN />,
  },
  {
    value: "de",
    text: "Німецька",
    icon: <DE />,
  },
  {
    value: "fr",
    text: "Французька",
    icon: <FR />,
  },
  {
    value: "es",
    text: "Іспанська",
    icon: <ES />,
  },
  {
    value: "kk",
    text: "Казахська",
    icon: <KZ />,
  },
  {
    value: "pl",
    text: "Польська",
    icon: <PL />,
  },
];

export const LANGS = {
  uk: "Українська",
  en: "Англійська",
  de: "Німецька",
  fr: "Французька",
  es: "Іспанська",
  kk: "Казахська",
  pl: "Польська",
};
