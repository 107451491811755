import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";

const AddButton = ({ onClick, disabled, border }) => (
  <>
    <button
      className={cx(styles["add-button"], {
        [styles.border]: border,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <i className={styles["add-button__icon"]} />
    </button>
  </>
);

export default AddButton;
