import Tg from "../assets/svg/telegram";

export const notificationTypes = [
  {
    value: "CRITICAL",
    label: "Критичне",
    color: "#FF0000",
  },
  {
    value: "IMPORTANT",
    label: "Важливе",
    color: "#EBFF00",
  },
  {
    value: "INFORMATIONAL",
    label: "Інформаційне",
    color: "#0BB2FA",
  },
  {
    value: "TELEGRAM",
    label: "Telegram",
    color: "#32B1E2",
  },
];

export const notificationFilters = [
  {
    id: -1,
    value: "ALL",
    title: "Всі",
    color: "#3DDDE8",
  },
  {
    id: 1,
    value: "CRITICAL",
    title: "Критичне",
    color: "#FF0000",
  },
  {
    id: 2,
    value: "IMPORTANT",
    title: "Важливе",
    color: "#EBFF00",
  },
  {
    id: 3,
    value: "INFORMATIONAL",
    title: "Інформаційне",
    color: "#0BB2FA",
  },
  {
    id: 4,
    value: "TELEGRAM",
    title: "Telegram",
    color: "#32B1E2",
    icon: <Tg />,
  },
];
