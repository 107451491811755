import React from "react";

const CheckedImg = () => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27 52C40.8071 52 52 40.8071 52 27C52 13.1929 40.8071 2 27 2C13.1929 2 2 13.1929 2 27C2 40.8071 13.1929 52 27 52Z"
        stroke="#53D888" strokeWidth="3" />
      <path d="M10.9824 32.7719L21.8816 41.013L41.1405 13.9774" stroke="#53D888" strokeWidth="3" />
    </svg>

  );
};

export default CheckedImg;
