import React from "react";

import Icon1 from "../../assets/tips/icon-1.svg";
import Icon0 from "../../assets/tips/icon-2.svg";
import TipsClickSvg from "../../assets/tips/tips_click.svg";
import ArrowRight from "../../assets/tips/arrow-right.svg";
import { useTranslation } from "react-i18next";

import "./index.scss";

export const TIPS_TYPE = {
  CHOOSE: "choose",
  CHOOSE_CATEGORY: "choose_category",
  CHOOSE_PART: "choose_part",
  CLICK: "click",
  SAVE: "save",
};

const Tips = ({ title, type }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="tips">
      {type === TIPS_TYPE.CHOOSE && (
        <div className="tips__choose">
          <img className="image-left" src={Icon0} alt="choose-0" />
          <span className="tips__text">{t("tips.choose")}</span>
          <img className="image-right" src={Icon1} alt="choose-1" />
        </div>
      )}
      {type === TIPS_TYPE.CHOOSE_PART && (
        <div className="tips__part">
          <img className="image-left" src={Icon0} alt="choose-icon-0" />
          <span className="tips__text">{t("tips.choosePart")}</span>
        </div>
      )}
      {type === TIPS_TYPE.CHOOSE_CATEGORY && (
        <div className="tips__category">
          <span className="tips__title">{title}</span>
          <span className="tips__text">{t("tips.chooseCategory")}</span>
          <img className="image-right" src={Icon1} alt="choose-icon-1" />
        </div>
      )}
      {type === TIPS_TYPE.CLICK && (
        <div className="tips__click">
          <img className="image-left" src={TipsClickSvg} alt="click-icon-1" />
          <span className="tips__text">{t("tips.click")}</span>
        </div>
      )}
      {type === TIPS_TYPE.SAVE && (
        <div className="tips__save">
          <span className="tips__text">{t("tips.save")}</span>
          <img className="image-right" src={ArrowRight} alt="click-icon-1" />
        </div>
      )}
    </div>
  );
};

export default Tips;
