import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./index.module.scss";

// Components and Containers
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import TranslatedTextForm from "containers/TranslatedTextForm";

// Utils and Libs
import { useOuterClick } from "../../utils/useOuterClick";
import { fixNestedModalsCloseTrigger } from "utils/fixNestedModalsCloseTrigger";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import cx from "classnames";

const ModalCancellingReason = ({
  isOpen,
  setIsOpenModal,
  reasonType,
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button"]);

  const { control, formState, getValues, setValue, trigger, reset } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
    },
  });

  const { errors, isValid } = formState;

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        uk: "",
        en: "",
        de: "",
        fr: "",
        es: "",
        kk: "",
        pl: "",
      });

      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div ref={innerRef} className={cx(styles["translate-text"])} onClick={fixNestedModalsCloseTrigger}>
        <div className={cx(styles["translate-text__title-wrapper"])}>
          <h2 className={cx(styles["translate-text__title"])}>
            {reasonType === "delayExpiry" ? "Введіть причину затримки експіріції" : "Введіть причину скасування ставок"}
          </h2>
        </div>

        <TranslatedTextForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          reset={reset}
          errors={errors}
          size="sm"
          autoFill
        />

        <div className={cx(styles["translate-text__btns"])}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              cancelHandler();
              setIsOpenModal();
            }}
            styleBtn={{ width: "198px" }}
          >
            {t("cancel")}
          </Button>

          <Button
            disabled={!isValid}
            autoFocus
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              successHandler(getValues());
              setIsOpenModal();
            }}
            styleBtn={{ width: "198px" }}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </Modal>,
    document.getElementById("root"),
  );
};

export default ModalCancellingReason;
