import React from "react";

const CoinsImg = () => {
  return (
    <svg width="58" height="54" viewBox="0 0 58 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1011 13.7753C28.5458 13.7753 36.2022 11.1393 36.2022 7.88764C36.2022 4.63599 28.5458 2 19.1011 2C9.65643 2 2 4.63599 2 7.88764C2 11.1393 9.65643 13.7753 19.1011 13.7753Z"
        stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M36.2135 15.5393C36.2135 18.7865 28.5506 21.4269 19.1011 21.4269C9.65168 21.4269 2 18.7865 2 15.5393"
            stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M23.9438 28.6066C23.4944 28.6066 19.5618 28.6066 19.1011 28.6066C9.65168 28.6066 2 25.9662 2 22.719"
            stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M21.1685 35.9887C20.4944 35.9887 19.7978 35.9887 19.1011 35.9887C9.65168 35.9887 2 33.3595 2 30.1011"
            stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M22.0562 43.3258C21.0738 43.4135 20.0871 43.4435 19.1011 43.4157C9.65169 43.4157 2 40.7753 2 37.5281"
            stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M2 37.5843V7.91016" stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M36.2131 24.7641V7.8877" stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M39.1684 37.1798C48.6131 37.1798 56.2695 34.5438 56.2695 31.2922C56.2695 28.0405 48.6131 25.4045 39.1684 25.4045C29.7237 25.4045 22.0673 28.0405 22.0673 31.2922C22.0673 34.5438 29.7237 37.1798 39.1684 37.1798Z"
        stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M56.2698 38.9438C56.2698 42.2023 48.6181 44.8315 39.1686 44.8315C29.7192 44.8315 22.0563 42.2023 22.0563 38.9438"
        stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M56.2698 46.1011C56.2698 49.3595 48.6181 52 39.1686 52C29.7192 52 22.0563 49.3707 22.0563 46.1011"
            stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M22.0563 46.0113V30.4495" stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
      <path d="M56.2695 46.337V31.0898" stroke="#FFD01F" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
};

export default CoinsImg;
