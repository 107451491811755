import * as React from "react";

const X = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M12.3477 9.99716L19.5075 2.85406C19.821 2.54053 19.9972 2.11528 19.9972 1.67187C19.9972 1.22846 19.821 0.803216 19.5075 0.48968C19.1939 0.176143 18.7687 0 18.3253 0C17.8819 0 17.4566 0.176143 17.1431 0.48968L10 7.64943L2.8569 0.48968C2.54337 0.176143 2.11812 -3.30364e-09 1.67471 0C1.2313 3.30365e-09 0.806058 0.176143 0.492521 0.48968C0.178985 0.803216 0.00284159 1.22846 0.00284159 1.67187C0.00284158 2.11528 0.178985 2.54053 0.492521 2.85406L7.65227 9.99716L0.492521 17.1403C0.336458 17.295 0.212588 17.4792 0.128055 17.6821C0.0435221 17.885 0 18.1026 0 18.3224C0 18.5423 0.0435221 18.7599 0.128055 18.9628C0.212588 19.1657 0.336458 19.3498 0.492521 19.5046C0.64731 19.6607 0.831467 19.7846 1.03437 19.8691C1.23727 19.9536 1.4549 19.9972 1.67471 19.9972C1.89452 19.9972 2.11215 19.9536 2.31505 19.8691C2.51796 19.7846 2.70211 19.6607 2.8569 19.5046L10 12.3449L17.1431 19.5046C17.2979 19.6607 17.482 19.7846 17.6849 19.8691C17.8878 19.9536 18.1055 19.9972 18.3253 19.9972C18.5451 19.9972 18.7627 19.9536 18.9656 19.8691C19.1685 19.7846 19.3527 19.6607 19.5075 19.5046C19.6635 19.3498 19.7874 19.1657 19.8719 18.9628C19.9565 18.7599 20 18.5423 20 18.3224C20 18.1026 19.9565 17.885 19.8719 17.6821C19.7874 17.4792 19.6635 17.295 19.5075 17.1403L12.3477 9.99716Z"
      fill="#3DDDE8"
    />
  </svg>
);

export default X;
