import API from "../api";

export const getImageListReq = (query) =>
  API.get("/admin/image/list", {
    params: {
      ...query,
      page: query?.page || 0,
      size: query?.size || 9,
    },
  });

export const getImageItemReq = (id) => API.get(`/admin/image/${id}`);

export const uploadImageReq = (data, config) =>
  API.post("/admin/image", data, {
    ...config,
  });

export const updateImageReq = (id, data, config) =>
  API.put(`/admin/image/${id}`, data, {
    ...config,
  });

export const deleteImageReq = (id) => API.delete(`/admin/image/${id}`);
