import React from "react";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
const cx = classNames.bind(styles);

const DeleteButton = ({ onClick, disabled, width, height, autoFocus }) => (
  <>
    <button
      className={styles["delete-button"]}
      onClick={onClick}
      disabled={disabled}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      autoFocus={autoFocus}
    >
      <i className={styles["delete-button__icon"]} />
    </button>
  </>
);

const EditButton = ({ onClick, disabled, width, height, autoFocus }) => (
  <>
    <button
      className={styles["edit-button"]}
      onClick={onClick}
      disabled={disabled}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      autoFocus={autoFocus}
    >
      <i className={styles["edit-button__icon"]} />
    </button>
  </>
);

const ShowButton = ({ onClick, disabled, width, height, autoFocus }) => (
  <>
    <button
      className={styles["show-button"]}
      onClick={onClick}
      disabled={disabled}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      autoFocus={autoFocus}
    >
      <i className={styles["show-button__icon"]} />
    </button>
  </>
);

const BlockButton = ({ onClick, disabled, width, height, autoFocus }) => (
  <>
    <button
      className={styles["block-button"]}
      onClick={onClick}
      disabled={disabled}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      autoFocus={autoFocus}
    >
      <i className={styles["block-button__icon"]} />
    </button>
  </>
);

const UnBlockButton = ({ onClick, disabled, width, height, autoFocus }) => (
  <>
    <button
      className={styles["unblock-button"]}
      onClick={onClick}
      disabled={disabled}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      autoFocus={autoFocus}
    >
      <i className={styles["unblock-button__icon"]} />
    </button>
  </>
);

const TimeButton = ({ onClick, disabled, width, height, autoFocus }) => (
  <>
    <button
      className={styles["time-button"]}
      onClick={onClick}
      disabled={disabled}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      autoFocus={autoFocus}
    >
      <i className={styles["time-button__icon"]} />
    </button>
  </>
);

const Button = ({
  children,
  onClick,
  disabled,
  type = "default",
  width,
  height,
  autoFocus,
  styleBtn,
  customBox = false,
}) => {
  if (type === "delete") {
    return <DeleteButton onClick={onClick} disabled={disabled} width={width} height={height} autoFocus={autoFocus} />;
  }

  if (type === "edit") {
    return <EditButton onClick={onClick} disabled={disabled} width={width} height={height} autoFocus={autoFocus} />;
  }

  if (type === "show") {
    return <ShowButton onClick={onClick} disabled={disabled} width={width} height={height} autoFocus={autoFocus} />;
  }

  if (type === "block") {
    return <BlockButton onClick={onClick} disabled={disabled} width={width} height={height} autoFocus={autoFocus} />;
  }

  if (type === "unblock") {
    return <UnBlockButton onClick={onClick} disabled={disabled} width={width} height={height} autoFocus={autoFocus} />;
  }

  if (type === "time") {
    return <TimeButton onClick={onClick} disabled={disabled} width={width} height={height} autoFocus={autoFocus} />;
  }

  if (type === "clear") {
    return (
      <div className={styles["button-clear"]}>
        <button
          disabled={disabled}
          type={type}
          onClick={onClick}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            ...styleBtn,
          }}
          autoFocus={autoFocus}
        >
          {children}
        </button>
      </div>
    );
  }

  return (
    <>
      <div
        className={cx("button", {
          customBox: customBox,
        })}
      >
        <button
          disabled={disabled}
          type={type}
          onClick={onClick}
          autoFocus={autoFocus}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            ...styleBtn,
          }}
        >
          {children}
        </button>
      </div>
    </>
  );
};

export default Button;
