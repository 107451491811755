import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteImageReq,
  getImageItemReq,
  getImageListReq,
  updateImageReq,
  uploadImageReq,
} from "../../api/image";
import { toasterCreator } from "../../utils/toasterCreator";

// const initCahcedImages = sessionStorage.getItem("cacheImages");

export const getImageList = createAsyncThunk(
  "image/getImageList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getImageListReq(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getImage = createAsyncThunk(
  "image/getImageItem",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getImageItemReq(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "image/uploadImage",
  async (data, { rejectWithValue }) => {
    try {
      const res = await uploadImageReq(data.formData, data.config);
      if (res.status === 200 || res.status === 201) {
        toasterCreator.images.imgAdded()
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const editImage = createAsyncThunk(
  "image/editImage",
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateImageReq(data.id, data.formData, data.config);
      if (res.status === 200 || res.status === 201) {
        toasterCreator.images.imgUpdated()
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "image/deleteImage",
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteImageReq(data.id);
      if (res.status === 200 || res.status === 201) {
        toasterCreator.images.imgDeleted()
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteFromCacheImage = (imageUuid) => {
  return {
    type: "image/DELETE_FROM_CACHE",
    imageUuid,
  };
};

const initialState = {
  imagesData: null,
  isLoading: false,
  isError: null,
  isImageUploading: false,
  isUploadError: false,
  cacheImage: {},
  // cacheImage: initCahcedImages ? JSON.parse(initCahcedImages) : {},
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getImageList.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getImageList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imagesData = { ...action.payload };
      })
      .addCase(getImageList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })

      .addCase(getImage.fulfilled, (state, action) => {
        state.cacheImage = {
          ...state.cacheImage,
          [action.payload.imageUuid]: action.payload.imageData,
        };
        // sessionStorage.setItem("cacheImages", JSON.stringify(state.cacheImage));
      })

      .addCase(uploadImage.pending, (state) => {
        state.isImageUploading = true;
        state.isUploadError = false;
      })
      .addCase(uploadImage.fulfilled, (state) => {
        state.isImageUploading = false;
        state.isUploadError = false;
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.isImageUploading = false;
        state.isUploadError = action.payload;
      })

      .addCase(editImage.pending, (state) => {
        state.isImageUploading = true;
        state.isUploadError = false;
      })
      .addCase(editImage.fulfilled, (state, action) => {
        console.log(action);
        state.isImageUploading = false;
        state.isUploadError = false;
      })
      .addCase(editImage.rejected, (state, action) => {
        state.isImageUploading = false;
        state.isUploadError = action.payload;
      })

      .addCase(deleteImage.pending, (state) => {
        state.isImageUploading = true;
        state.isUploadError = false;
      })
      .addCase(deleteImage.fulfilled, (state) => {
        state.isImageUploading = false;
        state.isUploadError = false;
      })
      .addCase(deleteImage.rejected, (state, action) => {
        state.isImageUploading = false;
        state.isUploadError = action.payload;
      })
      .addCase("image/DELETE_FROM_CACHE", (state, action) => {
        const temp = {};
        for (const key of Object.keys(state.cacheImage)) {
          if (key !== action.imageUuid) {
            temp[key] = state.cacheImage[key];
          }
        }
        state.cacheImage = temp;
        // sessionStorage.setItem("cacheImages", JSON.stringify(temp));
      });
  },
});

const { reducer } = imageSlice;

export const selectImage = (state) => state.image;
export const selectImagesData = (state) => state.image.imagesData;
export const selectImageLoading = (state) => state.image.isLoading;
export const selectIsImageUploading = (state) => state.image.isImageUploading;
export const selectIsUploadError = (state) => state.image.isUploadError;
export const selectImageError = (state) => state.image.isError;
export const selectCacheImages = (state) => state.image.cacheImage;

export default reducer;
