import Stop from "assets/svg/Stop";
import React, { useState, useEffect } from "react";

import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import { Trans, useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const ModalEditWinDirection = ({
  isOpen,
  bidUpCoefficient,
  bidDownCoefficient,
  setIsOpenModal,
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button", "modals"]);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div ref={innerRef} className={styles["modal-warning"]}>
        <div className={styles["modal-warning__stop"]}>
          <Stop />
        </div>
        <div className={styles["modal-warning__text"]}>
          <Trans i18nKey="modals:expirationBidChange">
            Обирай позицію на яку <span>хочеш змінити</span>!
          </Trans>
        </div>
        <div className={styles["modal-warning__btns"]}>
          <div
            className={styles["up-btn"]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              successHandler("BID_UP");
              setIsOpenModal();
            }}
          >
            {t("button:yes")}
            <br />
            {bidUpCoefficient}X
          </div>
          <div
            className={styles["down-btn"]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              successHandler("BID_DOWN");
              setIsOpenModal();
            }}
          >
            {t("button:no")}
            <br />
            {bidDownCoefficient}X
          </div>
        </div>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default ModalEditWinDirection;
