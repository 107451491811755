import * as React from "react";

const Download = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      d="M4.33203 17.668H17.6654"
      stroke="#3DDDE8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9983 4.33203V13.2209M10.9983 13.2209L14.8872 9.33203M10.9983 13.2209L7.10938 9.33203"
      stroke="#3DDDE8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 20.3333V1.66667C1 1.29848 1.29848 1 1.66667 1H20.3333C20.7016 1 21 1.29848 21 1.66667V20.3333C21 20.7016 20.7016 21 20.3333 21H1.66667C1.29848 21 1 20.7016 1 20.3333Z"
      stroke="#3DDDE8"
      stroke-width="2"
    />
  </svg>
);

export default Download;
