import React from "react";
import Copy from "../../../assets/svg/Copy";
import { copyToBuffer } from "utils/copyToBuffer";
import { formatDateForProfitTable } from "utils/date";
import TooltipComponent from "components/Tooltip";

const ChildWalletCol = ({ data, headers }) => {
  return headers?.map((header, index) => {
    return (
      <td key={header.key}>
        {header.key === "status" ? (
          <>
            {data[header.key] === "CANCELED" ? (
              <span style={{ color: "#EB4755" }}>Помилка</span>
            ) : data[header.key] === "CONFIRMED" ? (
              <span style={{ color: "#4DB275" }}>Готово</span>
            ) : data[header.key] === "UNCERTAIN" ? (
              <span style={{ color: "#EB4755" }}>Невизначений</span>
            ) : data[header.key] === "NOT_CONFIRMED" ? (
              <span style={{ color: "#EB4755" }}>Не оплачено</span>
            ) : (
              <span style={{ color: "#FFD01F" }}>Очікується</span>
            )}
          </>
        ) : header.key === "username" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              copyToBuffer(data[header.key]);
            }}
          >
            <span>{data[header.key]}</span>
            <Copy width={24} height={24} />
          </div>
        ) : header.key === "operationTime" ? (
          <span>{formatDateForProfitTable(data?.operationTime)}</span>
        ) : header.key === "fromAddress" || header.key === "toAddress" ? (
          <>
            {data[header.key] ? (
              <span>
                <TooltipComponent line={2} count={50} trigger={data[header.key]} textTooltip={data[header.key]} />
              </span>
            ) : (
              "-"
            )}
          </>
        ) : header.key === "id" ? (
          <span>
            <TooltipComponent line={2} count={50} trigger={data[header.key]} textTooltip={data[header.key]} />
          </span>
        ) : header.key === "fee_trx" ? (
          <span>{data[header.key] ? data[header.key] : "-"}</span>
        ) : (
          <span>{data[header.key]}</span>
        )}
      </td>
    );
  });
};

export default ChildWalletCol;
