import React from "react";
import "./index.scss";

const GearButton = ({ onClick, disabled }) => (
  <>
    <button
      className="gear-button cleared-button"
      onClick={onClick}
      disabled={disabled}
    >
      <i className="gear-button__icon" />
    </button>
  </>
);

export default GearButton;
