import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useOuterClick } from "../../../utils/useOuterClick";
import { HOURS_ARRAY, MINUTE_ARRAY } from "../../../constants/time";

import styles from "./index.module.scss";

const TimeSlider = (props) => {
  const { hh, setHH, mm, setMM, setSliderOpen, isOpen } = props;
  const [mounted, setMounted] = useState(false);

  const innerRef = useOuterClick((ev) => {
    if (isOpen && mounted) {
      setSliderOpen(false);
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return (
    <div
      className={cx(styles["time-dropdown"], {
        [styles.opened]: isOpen,
      })}
      ref={innerRef}
    >
      <div className={styles["time-dropdown__inner"]}>
        <div className={styles["time-dropdown__list"]}>
          {HOURS_ARRAY.map((item) => {
            return (
              <div
                key={item}
                className={cx(styles["time-dropdown__item"], {
                  [styles["selected"]]: Number(item) === Number(hh),
                })}
                onClick={() => {
                  setHH(item);
                }}
                tabIndex="0"
              >
                {item}
              </div>
            );
          })}
        </div>
        <div className={styles["time-dropdown__list"]}>
          {MINUTE_ARRAY.map((item) => {
            return (
              <div
                key={item}
                className={cx(styles["time-dropdown__item"], {
                  [styles["selected"]]: Number(item) === Number(mm),
                })}
                onClick={() => {
                  setMM(item);
                }}
                tabIndex="0"
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TimeSlider);
