/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// Styling
import styles from "./index.module.scss";

// Redux
import { useSelector } from "react-redux";
import { selectNotifLoading } from "../../store/slices/notification";

// Components and Containers
import DatePickerDropdown from "components/DatePickerDropdown";
import SelectInput from "components/SelectInput";
import Button from "components/Button";
import ModalWarning, { ErrorText } from "containers/ModalWarning";
import Loader from "../../components/Loader";
import TranslatedTextForm from "containers/TranslatedTextForm";

// Constants and Utils
import { notificationTypes } from "../../constants/notificationTypes";
import { formatDateForTelegramApiCall } from "utils/date";

// Libraries
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

const NotificationForm = ({
  isEditForm,
  notification,
  filterQuery,
  submitHandler = () => {},
  cancelHandler = () => {},
}) => {
  const { t } = useTranslation(["button", "modals", "common", "notification"]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const navigate = useNavigate();

  const isLoading = useSelector(selectNotifLoading);
  const dateNow = new Date();

  const { control, handleSubmit, formState, getValues, setValue, trigger, reset } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
      status: isEditForm
        ? notificationTypes.find((el) => el.value === notification?.statusName)
        : filterQuery === "TELEGRAM"
          ? notificationTypes[3]
          : notificationTypes[0],
      datePickerValue: isEditForm ? new Date(notification?.endDate) : dateNow,
    },
  });

  const { errors, isValid } = formState;

  const validateNotificationInputs = () => {
    if (filterQuery === "TELEGRAM") {
      return Object.values(getValues())
        .splice(0, 7)
        .some((value) => value);
    } else {
      return isValid;
    }
  };

  const onSubmitHandler = () => {
    setIsCancelModal(false);
    setIsOpenModal(true);
  };

  const cancel = (e) => {
    e.preventDefault();
    setIsCancelModal(true);
    setIsOpenModal(true);
  };

  const getSubmitModalText = () => {
    if (isCancelModal) {
      return (
        <span>
          <Trans i18nKey="modals:cancelAllChanges">
            Ти впевнений, що хочеш <ErrorText>скасувати всі дії?</ErrorText>
          </Trans>
        </span>
      );
    }
    if (validateNotificationInputs()) {
      return (
        <span>
          <Trans i18nKey="modals:allEnteredDataCorrect">
            Ти точно впевнений, що всі <ErrorText isUpperCase>дані правильно введені?</ErrorText>
          </Trans>
        </span>
      );
    }
    return (
      <span>
        <Trans i18nKey="modals:cannotSaveNoFullData">
          Усі поля не заповнені, тому ти <ErrorText>не можеш зберегти!</ErrorText>
        </Trans>
      </span>
    );
  };

  const handleNotificationTypeChange = (notification) => {
    if (notification.value === "TELEGRAM") {
      navigate("/notification/new?status=TELEGRAM");
    }
  };

  useEffect(() => {
    if (isEditForm && notification) {
      setValue(
        "status",
        notificationTypes.find((el) => el.value === notification?.status),
      );
      setValue("datePickerValue", new Date(notification?.endDate));
      setValue("uk", notification?.messages?.uk);
      setValue("en", notification?.messages?.en);
      setValue("de", notification?.messages?.de);
      setValue("fr", notification?.messages?.fr);
      setValue("es", notification?.messages?.es);
      setValue("kk", notification?.messages?.kk);
      setValue("pl", notification?.messages?.pl);
      trigger();
    }
  }, [notification, isEditForm]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles["form-container"]}>
        <form className={styles["form"]} onSubmit={handleSubmit(onSubmitHandler)}>
          <div className={styles["form__header"]}>
            <div className={styles["form__header-inner"]}>
              <Button
                type="submit"
                onClick={() => {
                  setIsCancelModal(false);
                  setIsOpenModal(true);
                }}
              >
                {t("button:save")}
              </Button>

              <Button type="cancel" onClick={cancel}>
                {t("button:cancel")}
              </Button>

              <div onClick={(e) => e.stopPropagation()}>
                <DatePickerDropdown
                  label={
                    isEditForm
                      ? t("notification:expirationDate")
                      : filterQuery === "TELEGRAM"
                        ? "Дата відправлення"
                        : t("notification:expirationDate_2")
                  }
                  control={control}
                  name="datePickerValue"
                  initValue={isEditForm ? new Date(notification?.endDate) : dateNow}
                  rules={{
                    required: t("common:form.validation.required"),
                  }}
                  errors={errors.datePickerValue}
                  minDate={new Date()}
                />
              </div>

              {filterQuery !== "TELEGRAM" && (
                <SelectInput
                  control={control}
                  name="status"
                  label={isEditForm ? t("notification:statusNotification") : t("notification:statusNotification_2")}
                  options={notificationTypes}
                  rules={{
                    required: t("common:form.validation.required"),
                  }}
                  errors={errors.status}
                  handleChange={handleNotificationTypeChange}
                />
              )}
            </div>
          </div>

          <TranslatedTextForm
            size="md"
            control={control}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
            reset={reset}
            title={t("notification:enterNotification")}
            autoFill
          />
        </form>
      </div>

      <ModalWarning
        isOpen={isOpenModal}
        hideCancelBtn={!validateNotificationInputs() && !isCancelModal}
        type={validateNotificationInputs() || isCancelModal ? "isQuestion" : null}
        setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
        cancelHandler={() => {
          setIsCancelModal(false);
        }}
        successHandler={() => {
          if (!isCancelModal && validateNotificationInputs()) {
            const values = getValues();

            submitHandler({
              status: values?.status?.value,
              messages: {
                uk: values.uk,
                en: values.en,
                de: values.de,
                fr: values.fr,
                es: values.es,
                kk: values.kk,
                pl: values.pl,
              },
              sentDate: filterQuery === "TELEGRAM" ? formatDateForTelegramApiCall(values.datePickerValue) : null,
              endDate: filterQuery !== "TELEGRAM" ? values?.datePickerValue : null,
            });
          }

          if (!isCancelModal && !validateNotificationInputs()) {
            setIsOpenModal(false);
          }

          if (isCancelModal) {
            cancelHandler();
          }
        }}
        title={getSubmitModalText()}
      />
    </>
  );
};

export default NotificationForm;
