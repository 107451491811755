import * as React from "react";

const VectorDown = (props) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57355 17.2162V4.72656"
      stroke="#EB4755"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.2722 12.1797L9.57399 17.2172L4.875 12.1797"
      stroke="#EB4755"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default VectorDown;
