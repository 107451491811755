import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../index.module.scss";

const AdditionalCol = ({ data }) => {
  const { t } = useTranslation(["common"]);

  const { registrationDate, lastAuthorizationDate, numberOfBets, status, ip } =
    data;

  return (
    <td colSpan={11}>
      <div className={styles["addition-info"]}>
        <div className={styles["addition-info__row"]}>
          <span>{t("user.registrationDate")}</span>
          <div>{registrationDate}</div>
        </div>
        <div className={styles["addition-info__row"]}>
          <span>{t("user.ipDate")}</span>
          <div>{ip ? ip : "xxx.xxx.xxx"}</div>
        </div>
        <div className={styles["addition-info__row"]}>
          <span>{t("user.lastAuthDate")}</span>
          <div>{lastAuthorizationDate}</div>
        </div>
        <div className={styles["addition-info__row"]}>
          <span>{t("user.countBet")}</span>
          <div>{numberOfBets}</div>
        </div>
        <div className={styles["addition-info__row"]}>
          <span>{t("user.status")}</span>
          <div>{status}</div>
        </div>
      </div>
    </td>
  );
};

export default AdditionalCol;
