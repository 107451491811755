import axios from "axios";
import { formatDateTimeForAxiosRequest } from "utils/date";

const config = {
  baseURL: process.env.REACT_APP_API_URL,
};

const api = axios.create({
  baseURL: config.baseURL,
  headers: {
    Accept: "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang");

    if (token) {
      config.headers["Authorization"] = `Bearer_${token}`;
    }

    config.headers["X-localization"] = !lang ? "uk" : lang.toLowerCase();
    localStorage.setItem("lang", !lang ? "uk" : lang.toLowerCase());

    config.headers["X-Local-DateTime"] = formatDateTimeForAxiosRequest(new Date(), config.url);

    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status !== 401 && error.response?.status !== 403) {
      return Promise.reject(error);
    } else if (
      error?.response?.data?.message === "User is blocked" ||
      error?.response?.data?.message === "User is deleted"
    ) {
      localStorage.clear();
      window.location = "/sign-in";
      return;
    }

    if ((error.response?.data?.message === "Token invalid or expired" || error.response?.status === 403) && !error.config._retry) {
      const refreshToken = localStorage.getItem("refreshToken");
      try {
        const res = await axios.post(
          "api/auth/refreshtoken",
          { refreshToken },
          {
            baseURL: config.baseURL,
            headers: {
              "Is-Admin": "true",
            },
          },
        );
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        return api.request({...error.config, _retry: true});
      } catch (err) {
        if (err.response?.status === 401 || err.response?.status === 403) {
          localStorage.clear();
          window.location = "/sign-in";
        } else {
          return Promise.reject(error);
        }
      }
    }

    if (error.response?.status === 401) {
      return Promise.reject(error);
    }
  },
);

export default api;
