import React, { useState, useEffect } from "react";

import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";

import styles from "./index.module.scss";
import Button from "components/Button";
import Close from "assets/svg/Close";
import { useTranslation } from "react-i18next";

const ModalCancelDetail = ({
  isOpen,
  setIsOpenModal,
  data,
  description,
  closeHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["event"]);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      closeHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div ref={innerRef} className={styles["modal"]}>
        <div className={styles["modal__btn"]}>
          <Button
            type="clear"
            onClick={() => {
              closeHandler();
              setIsOpenModal();
            }}
            widht="20"
            height="20"
          >
            <Close />
          </Button>
        </div>
        <div className={styles["modal__info"]}>
          <div className={styles["modal__info-row"]}>
            <span>{t("subCategory")}</span>
            <span>{data.subCategory}</span>
          </div>
          <div className={styles["modal__info-row"]}>
            <span>{t("eventDescription")}</span>
            <span>{data.title}</span>
          </div>
        </div>
        <div className={styles["modal__desc"]}>
          <span className={styles["modal__text"]}>{description}</span>
        </div>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default ModalCancelDetail;
