import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import styles from "./index.module.scss";
import Button from "components/Button";
import X from "assets/svg/X";
import Download from "assets/svg/Download";
import { Trans } from "react-i18next";
import classNames from "classnames/bind";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import ModalRangeDatePicker from "containers/ModalRangeDatePicker";
import { formatDateToCustomFormat } from "utils/date";
import Calendar from "../../assets/controls/Calendar";
import PaginationComponent from "components/PaginationComponent";
import { getPeriodProfitAction, downloadPeriodProfitAction } from "store/slices/crypto";

const cx = classNames.bind(styles);

const ModalProfitTable = ({ isOpen, setIsOpenModal, data, totalProfit, cancelHandler = () => {} }) => {
  const [mounted, setMounted] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date("2023-08-23T00:00"));
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();

  const mutateDataForPeriodProfitTable = (data) => {
    return {
      header: [
        {
          key: "finishTime",
          label: "Дата завершення",
        },

        {
          key: "eventTitle",
          label: "Події",
        },

        {
          key: "profit",
          label: "Прибуток у вигляді 10% комісії",
        },

        {
          key: "status",
          label: "Статус",
        },
      ],

      data: data?.map(({ finishTime, eventTitle, profit, status }) => {
        return {
          finishTime,
          eventTitle,
          profit,
          status,
        };
      }),
    };
  };

  const resetDates = () => {
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const onDateChange = (dates) => {
    if (dates.length) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
    }
  };

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  const handlePaginationClick = (pageNum) => {
    dispatch(getPeriodProfitAction({ query: { size: 8, page: pageNum - 1 } }));
  };

  return ReactDOM.createPortal(
    <>
      <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
        <div
          ref={innerRef}
          className={styles["modal"]}
          style={{
            pointerEvents: "auto",
          }}
        >
          <div className={styles["modal__header"]}>
            <div className={styles["modal__btn"]}>
              <button
                className={styles["modal__date-btn"]}
                onClick={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
              >
                <Calendar />
                {formatDateToCustomFormat(startDate)} - {formatDateToCustomFormat(endDate)}
              </button>
            </div>

            <div className={styles["modal__btn"]}>
              <Button
                type="clear"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(downloadPeriodProfitAction({ startDate, endDate }));
                }}
              >
                <Download />
              </Button>
            </div>

            <div className={styles["modal__btn"]}>
              <Button
                type="clear"
                onClick={(e) => {
                  e.stopPropagation();
                  cancelHandler();
                  setIsOpenModal();
                }}
              >
                <X />
              </Button>
            </div>
          </div>

          <div
            className={cx(styles["modal__table"], {
              [styles["expanded-table"]]: isExpanded,
            })}
          >
            <TableComponent type={TABLE_VIEW.PROFIT} data={mutateDataForPeriodProfitTable(data?.content)} />
          </div>

          <div className={styles["modal__table-total"]}>
            <span>Загальна сума за обраний період:</span>
            <span>{totalProfit}</span>
          </div>

          <PaginationComponent isAdminTbl totalPages={data?.totalPages} onChanePage={handlePaginationClick} />
        </div>
      </Modal>

      <ModalRangeDatePicker
        isOpen={isCalendarModalOpen}
        setIsOpenModal={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
        isUserHours
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
        resetDates={resetDates}
        successHandler={async () => {
          await dispatch(getPeriodProfitAction({ query: { size: 8 }, startDate, endDate }));

          setIsCalendarModalOpen(!isCalendarModalOpen);
        }}
        cancelHandler={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
        title={<Trans i18nKey="modals:chooseTime">Оберіть проміжок часу</Trans>}
      />
    </>,
    document.getElementById("root"),
  );
};

export default ModalProfitTable;
