import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import NotificationForm from "containers/NotificationForm";
import { useDispatch, useSelector } from "react-redux";
import { addNotification, selectNotifLoading, createTgNotificationAction } from "store/slices/notification";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const AddNotification = () => {
  const { t } = useTranslation(["common"]);
  const isLoading = useSelector(selectNotifLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const filterQuery = searchParams.get("status") || null;

  const onSubmit = async (data) => {
    if (filterQuery === "TELEGRAM") {
      try {
        await dispatch(createTgNotificationAction(data)).unwrap();
        navigate("/notification?page=1");
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await dispatch(addNotification(data)).unwrap();
        navigate("/notification?page=1");
      } catch (e) {
        navigate(-1);
      }
    }
  };

  const cancel = () => {
    navigate("/notification?page=1");
  };

  useEffect(() => {
    document.title = t("common:notificationPage.newEvent");
  }, [t]);

  return (
    <div className={styles.notification}>
      {filterQuery === "TELEGRAM" ? (
        <span className={styles["notification__title"]}>{t(`common:notificationPage.addEventTelegram`)}</span>
      ) : (
        <span className={styles["notification__title"]}>{t(`common:notificationPage.addEvent`)}</span>
      )}

      <div className={styles["notification__body"]}>
        <NotificationForm cancelHandler={cancel} submitHandler={onSubmit} filterQuery={filterQuery} />
      </div>
    </div>
  );
};

export default AddNotification;
