import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { toasterCreator } from "../../utils/toasterCreator";
import { getEventNotifications } from "../../store/slices/event";
import { useDispatch } from "react-redux";

const AuthGuard = ({ isAuth }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    let timerId = setInterval(async () => {
      try {
        token && dispatch(getEventNotifications());
      } catch (e) {
        console.log(e);
      }
    }, 300000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return isAuth ? (
    <>
      <Outlet />
    </>
  ) : (
    <>
      <Navigate to="/sign-in" state={{ from: location }} replace />
    </>
  );
};

export default AuthGuard;
