import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import ReactDOM from "react-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/CustomModal";
import Input from "../../../components/Input";
import { useOuterClick } from "../../../utils/useOuterClick";
import { CATEGORY_COLORS } from "../../../constants/colors";
import { useTranslation } from "react-i18next";

import "../index.scss";

const BlockColour = ({ selectedHandler }) => {
  const [selected, setSelected] = useState();

  return CATEGORY_COLORS.map((item) => (
    <div
      className={`block-with-color__item ${
        selected === item.id ? "selected" : ""
      }`}
      tabIndex="0"
      style={{ background: item.color }}
      onClick={() => {
        setSelected(item.id);
        selectedHandler(item.color);
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          setSelected(item.id);
          selectedHandler(item.color);
        }
      }}
    ></div>
  ));
};

const CreateEvent = ({
  isOpen,
  setIsOpenModal,
  itsCreate = true,
  saveHandler,
}) => {
  const { t } = useTranslation(["button", "common"]);
  const [mounted, setMounted] = useState(false);
  const [selectedColor, setSelectedColor] = useState();
  const { control, handleSubmit, formState, reset } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      categoryName: "",
    },
  });
  const { errors, isValid } = formState;

  const onSubmit = async (formData) => {
    saveHandler({ formData, color: selectedColor });
    clearAllData();
  };

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      setIsOpenModal();
      clearAllData();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  const clearAllData = () => {
    reset({
      categoryName: "",
    });
    setSelectedColor(null);
  };

  return ReactDOM.createPortal(
    <Modal
      title={t("common:category.edit")}
      setIsOpenModal={setIsOpenModal}
      isOpen={isOpen}
    >
      <div ref={innerRef} className="modal-event">
        <form onSubmit={handleSubmit(null)}>
          <h2>{t("common:category.add")}</h2>
          <span className="label">{t("common:category.name")}</span>
          <Input
            control={control}
            name="categoryName"
            placeholder={""}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (isValid && selectedColor) {
                  handleSubmit(onSubmit)();
                }
              }
            }}
            rules={{
              required: t("common:form.validation.required"),
            }}
            errors={errors.categoryName}
            size="sm"
            bordered
          />
          <span className="label mt-15">
            {t("common:category.chooseColor")}
          </span>
          <div className="block-with-color">
            <BlockColour selectedHandler={setSelectedColor} />
          </div>
          <div className="modal-event__btns">
            <Button
              onClick={() => {
                clearAllData();
                setIsOpenModal();
              }}
            >
              {t("button:cancel")}
            </Button>
            <Button
              disabled={!isValid || !selectedColor}
              onClick={handleSubmit(onSubmit)}
            >
              {t("button:save")}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default CreateEvent;
