import React, { useEffect, useState } from "react";
import cx from "classnames";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accessMatrixGenerator } from "../../constants/menu-items";
import ArrowDown from "../../assets/arrowdown.svg";
import ArrowUp from "../../assets/arrowup.svg";
import LogOutButton from "../../components/LogOutButton";

import ModaLogOut from "containers/ModaLogOut";
import SettingsButton from "../../components/SettingsButton";
import { logout } from "../../store/slices/auth";
import { clearLoggedUser } from "store/slices/users";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const translateMenu = (menu, t) => {
  if (!menu) {
    return menu;
  }

  return menu.map((item) => ({
    ...item,
    label: t(`common:sidebar.${item.translateKey}`),
    childrenLinks: translateMenu(item.childrenLinks, t),
  }));
};

const Item = ({ label, childrenLinks, link, isTwoFaEnabled }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showChildrenList, setShowChildrenList] = useState(false);

  const onPressLink = (link) => {
    if (link) {
      navigate(link);
    }
  };

  useEffect(() => {
    setShowChildrenList(location.pathname.includes(link) && Boolean(childrenLinks?.length));
  }, [location.pathname]);

  return (
    <>
      <div
        onClick={() => {
          if (isTwoFaEnabled) {
            setShowChildrenList(!showChildrenList);
            onPressLink(link);
          }
        }}
        className={cx(styles["menu-list__item"], {
          [styles["active"]]: location.pathname.includes(link),
          [styles["has-childrens"]]: childrenLinks?.length,
          [styles["disabled"]]: !isTwoFaEnabled,
        })}
      >
        <div className={styles.label}>{label}</div>
        {Boolean(childrenLinks?.length) && (
          <div className={styles["menu-list__item-arrow"]}>
            {showChildrenList ? <img src={ArrowUp} alt="arrow-up" /> : <img src={ArrowDown} alt="arrow-down" />}
          </div>
        )}
      </div>
      {location.pathname.includes(link) && Boolean(childrenLinks?.length) && (
        <div
          className={cx(styles["menu-list__children-wrapp"], {
            [styles["submenu-expanded"]]: showChildrenList,
          })}
        >
          <div className={styles["menu-list__children"]}>
            {childrenLinks.map((item, index) => {
              return (
                <div
                  key={item.id + index}
                  onClick={() => onPressLink(item.link)}
                  className={cx(styles["menu-list__children-item"], {
                    [styles["active"]]: location.pathname.includes(item.link),
                  })}
                >
                  <div className={styles.label}>{item.label}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const MenuItems = ({ children, isTwoFaEnabled, loggedUserRole }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  return (
    <div className={styles["menu-wrapper"]}>
      <div className={styles["menu-list"]}>
        {translateMenu(accessMatrixGenerator(loggedUserRole), t).map((item, index) => (
          <Item {...item} key={item.id + index} isTwoFaEnabled={isTwoFaEnabled} />
        ))}
      </div>

      <div className={styles["menu-footer"]}>
        <div className={styles["menu-footer__btns"]}>
          <LogOutButton
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
          <SettingsButton disabled={!isTwoFaEnabled} onClick={() => navigate("/settings")} />
        </div>
      </div>

      <ModaLogOut
        isOpen={isModalOpen}
        setIsOpenModal={() => setIsModalOpen(!isModalOpen)}
        cancelHandler={() => {
          setIsModalOpen(false);
        }}
        successHandler={async (navigate) => {
          await dispatch(logout());
          dispatch(clearLoggedUser());
          navigate("/sign-in");
        }}
      />
    </div>
  );
};

export default MenuItems;
