import React from "react";

const UnblockImg = () => {
  return (
    <svg width="57" height="54" viewBox="0 0 57 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M53.4698 20.3538H16.4157C15.5705 20.3538 14.8854 21.0404 14.8854 21.8875V50.4663C14.8854 51.3133 15.5705 52 16.4157 52H53.4698C54.315 52 55.0001 51.3133 55.0001 50.4663V21.8875C55.0001 21.0404 54.315 20.3538 53.4698 20.3538Z"
        stroke="#53D888" strokeWidth="3" />
      <path
        d="M25.0364 20.3538V13.544C25.0364 12.028 24.7385 10.5269 24.1596 9.12629C23.5808 7.72571 22.7323 6.45311 21.6628 5.38115C20.5932 4.3092 19.3235 3.45887 17.926 2.87873C16.5286 2.29859 15.0308 2 13.5182 2C10.4634 2 7.53368 3.21624 5.3736 5.38115C3.21352 7.54607 2 10.4823 2 13.544V17.0818"
        stroke="#53D888" strokeWidth="3" />
      <path
        d="M34.9427 41.9182C37.0612 41.9182 38.7787 40.1969 38.7787 38.0736C38.7787 35.9503 37.0612 34.229 34.9427 34.229C32.8241 34.229 31.1067 35.9503 31.1067 38.0736C31.1067 40.1969 32.8241 41.9182 34.9427 41.9182Z"
        stroke="#53D888" strokeWidth="3" />
      <path d="M34.9427 41.9283V47.276" stroke="#53D888" strokeWidth="3" />
    </svg>
  );
};

export default UnblockImg;
