import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDepositHistoryMethod, getUserWithdrawHistoryMethod } from "../../api/history";

const initialState = {
  depositHistory: null,
  withdrawHistory: null,
  isLoading: false,
  error: null,
};

export const getUserDepositHistoryAction = createAsyncThunk(
  "history/getUserDepositHistoryAction",
  async ({ username, currentPage }, { rejectWithValue }) => {
    try {
      const res = await getUserDepositHistoryMethod(username, currentPage);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      rejectWithValue(error);
      console.error(error);
    }
  },
);

export const getUserWithdrawHistoryAction = createAsyncThunk(
  "history/getUserWithdrawHistoryAction",
  async ({ username, currentPage }, { rejectWithValue }) => {
    try {
      const res = await getUserWithdrawHistoryMethod(username, currentPage);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      rejectWithValue(error);
      console.error(error);
    }
  },
);

const histroySlice = createSlice({
  name: "history",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserDepositHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserDepositHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.depositHistory = action.payload;
      })
      .addCase(getUserDepositHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getUserWithdrawHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserWithdrawHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.withdrawHistory = action.payload;
      })
      .addCase(getUserWithdrawHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const userDepositHistorySelector = (state) => state?.history?.depositHistory?.content;

export const userWithdrawHistorySelector = (state) => state?.history?.withdrawHistory?.content;

export const isLoadingSelector = (state) => state?.history?.isLoading;

export const selectWithdrawPageSize = (state) => state.history.withdrawHistory?.size;
export const selectWithdrawTotalPages = (state) => state.history.withdrawHistory?.totalPages;

export const selectDepositPageSize = (state) => state.history.depositHistory?.size;
export const selectDepositTotalPages = (state) => state.history.depositHistory?.totalPages;

export default histroySlice.reducer;
