import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

const Modal = ({ isOpen, children, title, background }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles["modal"]}>
      <div
        className={cx(styles["modal-content"], {
          [styles.background]: background,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
