/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedEventCategoryId } from "../../../store/slices/eventCategory";
import { getFilteredEvent, selectEventList, selectIsEventLoading } from "store/slices/event";
import Loader from "../../../components/Loader";
import Tips, { TIPS_TYPE } from "../../../containers/Tips";
import EventList from "containers/EventList";
import { useTranslation } from "react-i18next";
import { clearEventState } from "store/slices/event";
import dogTip1 from "../../../assets/events/dog-1.png";

import styles from "./index.module.scss";

const ExpireEvent = () => {
  const { t } = useTranslation(["event"]);
  const dispatch = useDispatch();
  const [tipType, setTipType] = useState();
  const isEventCategoryLoading = useSelector((state) => state.eventCategory.isEventCategoryLoading);
  const selectedCategory = useSelector(selectSelectedEventCategoryId);
  const expiredEventList = useSelector(selectEventList);
  const isEventListLoading = useSelector(selectIsEventLoading);

  const setTip = () => {
    if (selectedCategory) {
      setTipType(TIPS_TYPE.CLICK);
    } else {
      setTipType(TIPS_TYPE.CHOOSE_CATEGORY);
    }
  };

  const getList = (page) => {
    dispatch(
      getFilteredEvent({
        params: {
          include: "FINISHED",
          category: selectedCategory,
          page,
        },
      }),
    );
  };

  useEffect(() => {
    if (selectedCategory) {
      getList();
      return;
    }
    setTip();
  }, [selectedCategory]);

  useEffect(() => {
    document.title = t("expireEvent");

    dispatch(clearEventState());
  }, []);

  return (
    <div className={styles["active-event"]}>
      <div className={styles.content}>
        {!selectedCategory && (
          <div className={styles["content__tips"]}>
            <Tips type={tipType} title={t("expireEvent")} />
          </div>
        )}

        {selectedCategory && (
          <div className={styles["content__page"]}>
            <EventList
              getEvents={getList}
              eventList={expiredEventList}
              isEventListLoading={isEventListLoading}
              emptyListText={t("noExpireEvent")}
              listReload={() => {
                getList();
              }}
              pageChangeHandler={(page) => {
                getList(page - 1);
              }}
            />
          </div>
        )}
      </div>

      {!selectedCategory && (
        <div className="add-event__img">
          <img src={dogTip1} alt="choose-icon-2" />
        </div>
      )}

      {(isEventCategoryLoading || (selectedCategory && isEventListLoading)) && <Loader />}
    </div>
  );
};

export default ExpireEvent;
