/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Button from "../../components/Button";
import StepButton from "./StepButton";

import { useSelector } from "react-redux";
import { selectStepsItems } from "../../store/slices/steps";
import ModalWarning, { ErrorText } from "containers/ModalWarning";
import { Trans, useTranslation } from "react-i18next";

import "./index.scss";

const StepsNav = ({ activeStep, onStepChange, onCancel, disabledBtn }) => {
  const stepsItems = useSelector(selectStepsItems);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation(["button", "modals"]);

  const isCanNavToNextStep = (nextItem, index) => {
    if (index === 0) {
      return true;
    }

    for (const item of stepsItems) {
      if (item.id !== nextItem.id) {
        if (!item.isValid) {
          return false;
        }
      }
      if (item.id === nextItem.id) {
        return true;
      }
    }
  };

  return (
    <div
      className="steps-nav"
      onKeyDown={(e) => {
        if (e.keyCode === 27) {
          e.preventDefault();
          setIsOpenModal(true);
        }
      }}
    >
      <div className="steps-nav__list">
        {stepsItems?.map((item, index) => (
          <div className="steps-nav__list-item" key={item.id}>
            <StepButton
              name={item.name}
              isDirty={item.isDirty}
              isVisited={item.isVisited}
              isValid={item.isValid}
              isStored={item.isStored}
              isActive={item.id === activeStep}
              onClick={() => {
                if (isCanNavToNextStep(item, index)) {
                  onStepChange?.(item.id);
                }
              }}
            />
          </div>
        ))}
      </div>
      <div className="steps-nav__cancel">
        <Button disabled={disabledBtn} onClick={() => setIsOpenModal(true)}>
          {t("button:cancel")}
        </Button>
      </div>
      <ModalWarning
        isOpen={isOpenModal}
        setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
        successHandler={() => {
          onCancel();
        }}
        title={
          <span>
            <Trans i18nKey="modals:cancelEvent">
              Ти точно впевнений, що хочеш{" "}
              <ErrorText>СКАСУВАТИ ПОДІЮ</ErrorText>?
            </Trans>
          </span>
        }
      />
    </div>
  );
};

export default StepsNav;
