import * as React from "react";

const EditIcon = (props) => (
  <svg
    width={38}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      d="m15.106 15.272-2.284 7.615a1.839 1.839 0 0 0 2.29 2.289l7.615-2.283c.291-.088.557-.245.772-.461L37.461 8.471a1.84 1.84 0 0 0 0-2.6L32.128.538a1.838 1.838 0 0 0-2.6 0L15.568 14.5c-.216.216-.374.48-.462.772Zm3.39 1.5L30.827 4.44 33.56 7.17 21.227 19.505l-3.901 1.17 1.17-3.902Z"
      fill="#3DDDE8"
    />
    <path
      d="M36.161 17.161A1.839 1.839 0 0 0 34.323 19v12.258a3.068 3.068 0 0 1-3.065 3.065H6.742a3.068 3.068 0 0 1-3.065-3.065V6.742a3.068 3.068 0 0 1 3.065-3.065H19A1.839 1.839 0 0 0 19 0H6.742A6.75 6.75 0 0 0 0 6.742v24.516A6.75 6.75 0 0 0 6.742 38h24.516A6.75 6.75 0 0 0 38 31.258V19a1.839 1.839 0 0 0-1.839-1.839Z"
      fill="#3DDDE8"
    />
  </svg>
);

export default EditIcon;
