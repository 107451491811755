import React from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import AutoResizeTextArea from "components/AutoResizeTextArea";
import { LANG_ARRAY, LANGS } from "constants/lang";
import CopyToBufferButton from "components/CopyToBufferButton";
import AutoTranslateButton from "components/AutoTranslateButton";

const cx = classNames.bind(styles);

const TranslatedTextForm = ({ control, getValues, setValue, trigger, reset, errors, size, title, autoFill = true }) => {
  const { t } = useTranslation(["button"]);
  const [searchParams] = useSearchParams();
  const filterQuery = searchParams.get("status") || null;

  return (
    <div className={styles["form__body"]}>
      {LANG_ARRAY.map((item, index) => (
        <div className={styles["form__body-row"]} key={item.value}>
          <div>
            {index === 0 && filterQuery !== "TELEGRAM" && (
              <div className={cx("form__body-title")}>
                <span>{title ? title : null}</span>
              </div>
            )}

            <div className={styles["form__text-wrapper"]}>
              {filterQuery === "TELEGRAM" && item.icon}
              <AutoResizeTextArea
                control={control}
                name={item.value}
                placeholder={item.text}
                isPlaceholderWhite
                rules={{
                  required: t("common:form.validation.required"),
                }}
                errors={errors[item.value]}
                size={size}
              />
            </div>
          </div>

          <CopyToBufferButton
            getText={() => getValues(item.value)}
            onCopy={(txt) => {
              if (autoFill) {
                reset({
                  uk: txt,
                  en: txt,
                  de: txt,
                  fr: txt,
                  es: txt,
                  kk: txt,
                  pl: txt,
                });
              }
              trigger();
            }}
          />

          <AutoTranslateButton
            getText={() => ({
              currentText: getValues(item.value),
              allTextValues: getValues(),
              langs: LANGS,
            })}
            onTranslate={(data) => {
              const updatedFormValues = {
                ...getValues(),
                ...data,
              };
              reset(updatedFormValues);
              trigger();
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default TranslatedTextForm;
