/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useNavigate, useParams } from "react-router";
import { getEventReq } from "api/event";
import EventDetail from "containers/EventDetail";
import ModalWarning, { ErrorText } from "containers/ModalWarning";
import { useDispatch, useSelector } from "react-redux";
import { cancelEvent, updateEvent } from "store/slices/event";
import { NotificationManager } from "react-notifications";
import ModalFormEvent from "containers/ModalFormEvent";
import Loader from "components/Loader";
import { Trans, useTranslation } from "react-i18next";
import { lang } from "store/slices/langSlice";
import { selectEventsContent } from "store/slices/event";
import { updateEventDataByKey } from "store/slices/event";
import { parseDateToFormat } from "utils/date";

import styles from "./index.module.scss";
const cx = classNames.bind(styles);

const PublishedEventEdit = () => {
  const { t } = useTranslation(["event", "notification", "modals"]);
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [cancelingInfo, setCancelingInfo] = useState(null);
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const langValue = useSelector(lang);
  const eventList = useSelector(selectEventsContent);
  const [requestData, setRequestData] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();

  const showModal = (type) => {
    setModalType(type);
    if (type === "save") {
      setIsOpenSaveModal(true);
    } else {
      setIsOpenCancelModal(true);
    }
  };

  const update = () => {
    setIsLoading(true);
    setModalType(null);
    setIsOpenSaveModal(false);
    dispatch(updateEvent({ id, data: editData }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        navigate("/events/published");
      })
      .catch((err) => {
        setIsLoading(false);
        NotificationManager.error("", t("notification:errorUpdateTitle"), 6000);
      });
  };

  const cancelHandler = (data) => {
    setIsLoading(true);
    setModalType(null);
    setIsOpenCancelModal(false);
    dispatch(cancelEvent({ eventUuid: id, cancelingInfo: data["uk"] }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        navigate("/events/published");
      })
      .catch((err) => {
        setIsLoading(false);
        NotificationManager.error("", t("notification:errorCancelTitle"), 6000);
      });
  };

  const getEventsMethod = async () => {
    setIsLoading(true);
    getEventReq(id)
      .then((res) => {
        setEditData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error1: ", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    !editData && getEventsMethod();
  }, []);

  useEffect(() => {
    if (editData) {
      document.title = editData.title;
    }
  }, [editData]);

  useEffect(() => {
    if (editData) {
      getEventsMethod();
    }
  }, [langValue]);

  return (
    <div className={cx("event")}>
      {editData && (
        <EventDetail
          data={editData}
          setData={setEditData}
          setRequestData={setRequestData}
          requestData={requestData}
          saveHandler={(data) => {
            setEditData(data);
            showModal("save");
          }}
          deleteBtn={false}
          cancelBtn
          showTimer
          cancelHandler={() => showModal("cancel")}
          onFieldChange={(key, value) => {
            let fixedValue = value;
            if (
              key === "betEndTime" ||
              key === "finishTime" ||
              key === "startTime"
            ) {
              fixedValue = parseDateToFormat(value);
            }
            dispatch(updateEventDataByKey(key, fixedValue, editData.uuid));
          }}
        />
      )}
      <ModalWarning
        isOpen={isOpenSaveModal}
        setIsOpenModal={() => setIsOpenSaveModal(!isOpenSaveModal)}
        successHandler={update}
        cancelHandler={() => {
          setModalType(null);
          setIsOpenSaveModal(false);
        }}
        title={
          <Trans i18nKey="modals:allEnteredDataCorrect">
            Ти точно впевнений, що всі{" "}
            <ErrorText isUpperCase>Дані правильно введені</ErrorText>?
          </Trans>
        }
      />
      <ModalFormEvent
        title={t("modals:cancelReason")}
        isOpen={isOpenCancelModal}
        setIsOpenModal={() => setIsOpenCancelModal(!isOpenCancelModal)}
        successHandler={cancelHandler}
        cancelHandler={() => {
          setModalType(null);
          setIsOpenCancelModal(false);
        }}
      />
      {isLoading && <Loader />}
    </div>
  );
};

export default PublishedEventEdit;
