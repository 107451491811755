import { useTranslation, UseTranslationResponse } from "react-i18next";
import React, { createContext, PropsWithChildren, useContext } from "react";

const I18nContext = createContext<{
  t: UseTranslationResponse<any, any>["t"];
  i18n: UseTranslationResponse<any, any>["i18n"];
}>(null as any);

export const useTranslations = () => useContext(I18nContext);

export const I18nProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t, i18n } = useTranslation();

  return <I18nContext.Provider value={{ t: t, i18n: i18n }}>{children}</I18nContext.Provider>;
};
