import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";
import {getEventStat} from "../../api/eventStat";



const initialState = {
    isLoading: false,
    isError: false,
    eventReport: null,
};
export const getEventReport = createAsyncThunk(
    "eventStat/getEventReport",
    async ({ page, size }, { rejectWithValue }) => {
        try {
            const response = await getEventStat(page, size);
            if (response.status === 200 || response.status === 201) {
                return response.data;
            }
        } catch (error) {
            NotificationManager.error("Error", "Unable to fetch event report", 6000);
            return rejectWithValue("Something went wrong");
        }
    }
);

const eventStatSlice = createSlice({
    name: "eventStat",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getEventReport.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getEventReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.eventReport = action.payload;
            })
            .addCase(getEventReport.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = action.payload;
            });
    },
});

export const selectEventData = (state) => state.eventStat.eventReport;
export const selectEventIsLoading = (state) => state.eventStat.isLoading;

export default eventStatSlice.reducer;
