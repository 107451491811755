import * as React from "react";

const ArrowUp = (props) => (
  <svg
    width="24"
    height="13"
    viewBox="0 0 24 13"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0186 10.9858L11.9533 2.51487L1.92186 11.0144"
      stroke="#3DDDE8"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowUp;
