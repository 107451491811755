/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedEventCategoryId,
  setEventCategorySelected,
} from "../../../store/slices/eventCategory";
import {
  clearPagesReduxState,
  initPageData,
  selectIsReadyToSubmit,
  selectStoredCategoryId,
  setToDefault,
} from "../../../store/slices/steps";

import Loader from "../../../components/Loader";
import Tips, { TIPS_TYPE } from "../../../containers/Tips";
import StepsNav from "../../../containers/StepsNav";

import SubcategoryStepPage from "../../../containers/StepsPage/SubcategoryStepPage";
import EventDescStepPage from "../../../containers/StepsPage/EventDescStepPage";
import ContextStepPage from "../../../containers/StepsPage/ContextStepPage";
import TimeRangeStepPage from "../../../containers/StepsPage/TimeRangeStepPage";
import StartRefPage from "../../../containers/StepsPage/StartRefPage";
import EndRefPage from "../../../containers/StepsPage/EndRefPage";
import ImageStepPage from "../../../containers/StepsPage/ImageStepPage";
import FinishStepPage from "containers/StepsPage/FinishStepPage";
import { useTranslation } from "react-i18next";

import dogTip1 from "../../../assets/events/dog-1.png";
import dogTip2 from "../../../assets/events/dog-2.png";

import "./index.scss";

const AddEvent = () => {
  const { t } = useTranslation(["event"]);
  const dispatch = useDispatch();
  const [tipType, setTipType] = useState();
  const [activeStep, setActiveStep] = useState();
  const isEventCategoryLoading = useSelector(
    (state) => state.eventCategory.isEventCategoryLoading
  );
  const selectedCategory = useSelector(selectSelectedEventCategoryId);
  const isRedyToSubmitData = useSelector(selectIsReadyToSubmit);
  const storedCategoryId = useSelector(selectStoredCategoryId);

  const cancelHandler = () => {
    setActiveStep(null);
    dispatch(setToDefault());
    dispatch(setEventCategorySelected(null));
  };

  const isNeedToClearSelectedStep = () => {
    if (!selectedCategory && activeStep) {
      setActiveStep(null);
    }
  };

  const setTip = () => {
    if (selectedCategory) {
      setTipType(TIPS_TYPE.CLICK);
    } else {
      setTipType(TIPS_TYPE.CHOOSE_CATEGORY);
    }
  };

  useEffect(() => {
    if (selectedCategory && activeStep) {
      return;
    }
    isNeedToClearSelectedStep();
    setTip();
  }, [selectedCategory]);

  useEffect(() => {
    document.title = t("addEvent");
    dispatch(initPageData());

    return () => {
      dispatch(clearPagesReduxState());
    };
  }, []);

  useEffect(() => {
    if (storedCategoryId) {
      dispatch(setEventCategorySelected(storedCategoryId));
    }
  }, [storedCategoryId]);

  return (
    <div className="add-event">
      <div className="content">
        <div className="content__steps">
          {selectedCategory && !isRedyToSubmitData && (
            <StepsNav
              activeStep={activeStep}
              onStepChange={setActiveStep}
              onCancel={cancelHandler}
            />
          )}
          {!activeStep && !isRedyToSubmitData && (
            <div className="content__tips">
              <Tips type={tipType} title={t("addEvent")} />
            </div>
          )}
          {(activeStep || isRedyToSubmitData) && (
            <div className="content__pages">
              {activeStep === 1 && <SubcategoryStepPage id={1} />}
              {activeStep === 2 && <EventDescStepPage id={2} />}
              {activeStep === 3 && <ContextStepPage id={3} />}
              {activeStep === 4 && <TimeRangeStepPage id={4} />}
              {activeStep === 5 && <StartRefPage id={5} />}
              {activeStep === 6 && <EndRefPage id={6} />}
              {activeStep === 7 && (
                <ImageStepPage id={7} setActiveStep={setActiveStep} />
              )}
              {isRedyToSubmitData && (
                <FinishStepPage setActiveStep={setActiveStep} />
              )}
            </div>
          )}
        </div>
      </div>
      {!activeStep && !isRedyToSubmitData && (
        <div className="add-event__img">
          <img
            src={tipType === TIPS_TYPE.CLICK ? dogTip2 : dogTip1}
            alt="choose-icon-2"
            style={{
              left: tipType === TIPS_TYPE.CLICK ? "471px" : "unset",
            }}
          />
        </div>
      )}
      {isEventCategoryLoading && <Loader />}
    </div>
  );
};

export default AddEvent;
