import React from "react";

const FolderImg = () => {
  return (
    <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.7146 46.196H4.39498C3.75979 46.196 3.15062 45.9428 2.70147 45.4921C2.25233 45.0414 2 44.4301 2 43.7927V4.40326C2 3.76588 2.25233 3.1546 2.70147 2.7039C3.15062 2.2532 3.75979 2 4.39498 2H20.7766L27.279 9.67843H58.0305C58.6657 9.67843 59.2749 9.93163 59.724 10.3823C60.1732 10.833 60.4255 11.4443 60.4255 12.0817V30.4667"
        stroke="#FF0000" strokeWidth="3" />
      <path
        d="M53.0131 52C59.6333 52 65 46.6148 65 39.9717C65 33.3286 59.6333 27.9434 53.0131 27.9434C46.393 27.9434 41.0262 33.3286 41.0262 39.9717C41.0262 46.6148 46.393 52 53.0131 52Z"
        stroke="#FF0000" strokeWidth="3" strokeLinecap="round" />
      <path d="M46.4508 39.9717H59.5633" stroke="#FF0000" strokeWidth="3" />
    </svg>

  );
};

export default FolderImg;
