import * as React from "react";

const ModalArrowBack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="30"
    viewBox="0 0 20 30"
    fill="none"
    {...props}
  >
    <path
      d="M6.40657 14.9961L19.1025 4.4169C19.6092 3.99125 19.9266 3.38213 19.9852 2.72294C20.0438 2.06374 19.8388 1.40819 19.4151 0.899833C18.9913 0.39148 18.3834 0.0717588 17.7244 0.0106826C17.0655 -0.0503936 16.4091 0.152153 15.8992 0.573968L0.898502 13.0746C0.617228 13.3096 0.390975 13.6035 0.235721 13.9355C0.080468 14.2675 0 14.6296 0 14.9961C0 15.3626 0.080468 15.7247 0.235721 16.0567C0.390975 16.3887 0.617228 16.6826 0.898502 16.9176L15.8992 29.4182C16.4089 29.842 17.066 30.0462 17.7262 29.9859C18.3863 29.9255 18.9956 29.6056 19.42 29.0964C19.8445 28.5872 20.0494 27.9303 19.9899 27.2701C19.9303 26.6099 19.6112 26.0003 19.1025 25.5752L6.40657 14.9961Z"
      fill="#3DDDE8"
    />
  </svg>
);

export default ModalArrowBack;
