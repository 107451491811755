import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import ReactDOM from "react-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/CustomModal";
import Input from "../../../components/Input";
import { useOuterClick } from "../../../utils/useOuterClick";
import { CATEGORY_COLORS } from "../../../constants/colors";
import { useTranslation } from "react-i18next";
import CategoryList from "../CategoryList";

import "../index.scss";

const BlockColour = ({ selectedHandler }) => {
  const [selected, setSelected] = useState();

  return CATEGORY_COLORS.map((item) => (
    <div
      tabIndex="0"
      className={`block-with-color__item ${
        selected === item.id ? "selected" : ""
      }`}
      style={{ background: item.color }}
      onClick={() => {
        setSelected(item.id);
        selectedHandler(item.color);
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          setSelected(item.id);
          selectedHandler(item.color);
        }
      }}
    ></div>
  ));
};

const EditEvent = ({ isOpen, setIsOpenModal, saveHandler }) => {
  const { t } = useTranslation(["button", "common"]);
  const [mounted, setMounted] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [editProcess, setEditProcess] = useState(false);

  const [selectedColor, setSelectedColor] = useState();
  const { control, handleSubmit, formState, reset } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      categoryName: "",
    },
  });
  const { errors, isValid, isDirty } = formState;

  const onSubmit = async (formData) => {
    saveHandler({
      formData,
      color: selectedColor,
      id: categoryToEdit.categoryUuid,
    });
    clearAllData();
    setCategoryToEdit(null);
  };

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      setIsOpenModal();
      clearAllData();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  useEffect(() => {
    if (categoryToEdit) {
      setEditProcess(true);
      reset({
        categoryName: categoryToEdit.categoryName,
      });
    } else {
      setEditProcess(false);
    }
  }, [categoryToEdit]);

  const clearAllData = () => {
    reset({
      categoryName: "",
    });
    setSelectedColor(null);
    setCategoryToEdit(null);
  };

  return ReactDOM.createPortal(
    <Modal
      title={t("common:category.edit")}
      setIsOpenModal={setIsOpenModal}
      isOpen={isOpen}
    >
      <div ref={innerRef} className="modal-event">
        {!editProcess && (
          <CategoryList
            setIsOpenModal={setIsOpenModal}
            categoryToEdit={setCategoryToEdit}
          />
        )}
        {editProcess && (
          <form onSubmit={handleSubmit(null)}>
            <h2>{t("common:category.edit")}</h2>
            <span className="label">{t("common:category.name")}</span>
            <Input
              control={control}
              name="categoryName"
              placeholder={""}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  if (isValid && selectedColor) {
                    handleSubmit(onSubmit)();
                  }
                }
              }}
              rules={{
                required: t("common:form.validation.required"),
              }}
              errors={errors.categoryName}
              size="sm"
            />
            <span className="label mt-15">
              {t("common:category.chooseColor")}
            </span>
            <div className="block-with-color">
              <BlockColour selectedHandler={setSelectedColor} />
            </div>
            <div className="modal-event__btns">
              <Button
                onClick={() => {
                  clearAllData();
                  setCategoryToEdit(null);
                }}
              >
                {t("button:cancel")}
              </Button>
              <Button
                disabled={
                  (!isValid && !selectedColor) || (!isDirty && !selectedColor)
                }
                onClick={handleSubmit(onSubmit)}
              >
                {t("button:save")}
              </Button>
            </div>
          </form>
        )}
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default EditEvent;
