import * as React from "react";

const DeleteIcon = (props) => (
  <svg
    width={34}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 38"
    {...props}
  >
    <path
      d="M13.222 30.4c.501 0 .982-.2 1.336-.556.354-.357.553-.84.553-1.344V17.1c0-.504-.199-.987-.553-1.344a1.884 1.884 0 0 0-2.671 0c-.355.357-.554.84-.554 1.344v11.4c0 .504.2.987.554 1.343.354.357.834.557 1.335.557Zm18.89-22.8h-7.556V5.7a5.717 5.717 0 0 0-1.66-4.03A5.65 5.65 0 0 0 18.889 0H15.11a5.65 5.65 0 0 0-4.007 1.67 5.717 5.717 0 0 0-1.66 4.03v1.9H1.89c-.501 0-.982.2-1.336.556a1.906 1.906 0 0 0 0 2.688c.354.356.835.556 1.336.556h1.889v20.9c0 1.512.597 2.962 1.66 4.03A5.65 5.65 0 0 0 9.444 38h15.112a5.65 5.65 0 0 0 4.006-1.67 5.717 5.717 0 0 0 1.66-4.03V11.4h1.89c.5 0 .98-.2 1.335-.556a1.906 1.906 0 0 0 0-2.688A1.883 1.883 0 0 0 32.11 7.6Zm-18.89-1.9c0-.504.2-.987.553-1.344a1.883 1.883 0 0 1 1.336-.556h3.778c.5 0 .981.2 1.335.556.355.357.554.84.554 1.344v1.9h-7.556V5.7Zm13.222 26.6c0 .504-.199.987-.553 1.344a1.883 1.883 0 0 1-1.335.556H9.444c-.5 0-.981-.2-1.335-.556a1.906 1.906 0 0 1-.553-1.344V11.4h18.888v20.9Zm-5.666-1.9c.5 0 .981-.2 1.335-.556.355-.357.554-.84.554-1.344V17.1c0-.504-.2-.987-.554-1.344a1.883 1.883 0 0 0-2.67 0c-.355.357-.554.84-.554 1.344v11.4c0 .504.199.987.553 1.343.354.357.835.557 1.336.557Z"
      fill="#3DDDE8"
    />
  </svg>
);

export default DeleteIcon;
