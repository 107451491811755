import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import NotificationForm from "containers/NotificationForm";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  selectNotifItem, updateNotification
} from "store/slices/notification";

import styles from "./index.module.scss";

const EditNotification = () => {
  const { t } = useTranslation(["common"]);
  const notification = useSelector(selectNotifItem);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const onSubmit = async (data) => {
    try {
      await dispatch(updateNotification({ data, id }));
      navigate(-1);
    } catch (e) {
      navigate(-1);
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getNotification(id))
    document.title = t("common:notificationPage.editEvent");
  }, []);

  return (
    <div className={styles.notification}>
      <span className={styles["notification__title"]}>
        {t("common:notificationPage.editEvent")}
      </span>
      <div className={styles["notification__body"]}>
        <NotificationForm
          isEditForm
          notification={notification}
          cancelHandler={cancel}
          submitHandler={onSubmit}
        />
      </div>
    </div>
  );
};

export default EditNotification;
