// React Redux Styling
import { useEffect } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  getCommissionsAction,
  setSystemSettingAction,
  selectIsLoading,
  selectCommissions,
  selectDepositWithdrawSettings,
  getDepositWithdrawSettingAction,
  toggleDepositWithdrawSettingAction,
} from "store/slices/settings";

// Components
import Loader from "components/Loader";
import FeeSettingForm from "components/FeeSettingForm";
import TransferToggleSettings from "components/TransferToggleSettings";

// Utils & Constants
import { useTranslation } from "react-i18next";

const SettingsPage = () => {
  const { t } = useTranslation(["common", "button"]);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const commissions = useSelector(selectCommissions, shallowEqual);
  const depositWithdrawSettings = useSelector(selectDepositWithdrawSettings, shallowEqual);

  useEffect(() => {
    dispatch(getCommissionsAction());
    dispatch(getDepositWithdrawSettingAction());
  }, []);

  const commissionSettingBtnHandler = async (setting, value, reset) => {
    await dispatch(setSystemSettingAction({ field: setting, value })).unwrap();
    reset();
    dispatch(getCommissionsAction());
  };

  const toggleBtnHandler = async (setting, toggle) => {
    await dispatch(toggleDepositWithdrawSettingAction({ setting, toggle })).unwrap();
    dispatch(getDepositWithdrawSettingAction());
  };

  return (
    <div className={styles["settings"]}>
      <div className={styles["settings__header"]}>
        <h1 className={styles["settings__title"]}>{t("common:settings.settings")}</h1>
      </div>

      <div className={styles["settings__content"]}>
        <FeeSettingForm commissions={commissions} commissionSettingBtnHandler={commissionSettingBtnHandler} />

        <div className={styles["toggles-container"]}>
          <TransferToggleSettings
            title="Зупинити поповнення балансу"
            type="deposit"
            toggleBtnHandler={toggleBtnHandler}
            depositWithdrawSettings={depositWithdrawSettings}
          />

          <TransferToggleSettings
            title="Зупинити вивід коштів"
            type="withdraw"
            toggleBtnHandler={toggleBtnHandler}
            depositWithdrawSettings={depositWithdrawSettings}
          />
        </div>
      </div>

      {isLoading && <Loader />}
    </div>
  );
};

export default SettingsPage;
