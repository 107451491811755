import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItems from "../MenuItems";
import Login from "../Login";
import TopBar from "../TopBar";

import "./index.scss";

const MenuLayout = ({ children, isAuth, isTwoFaEnabled, loggedUserRole }) => {
  const location = useLocation();
  const navigation = useNavigate();

  return (
    <>
      <div className="menu">
        <div className="menu-icon" onClick={() => navigation("/")}>
          <img src={require("../../assets/Logo1.png")} alt="logo" />
        </div>
        <div className="menu-content">
          {isAuth ? <MenuItems isTwoFaEnabled={isTwoFaEnabled} loggedUserRole={loggedUserRole} /> : <Login />}
        </div>
      </div>
      <div className="content">
        {isAuth && (location.pathname.includes("/events") || location.pathname.includes("/events/")) && (
          <div className="menu-upper">
            <TopBar />
            <div className="menu-upper-button"></div>
          </div>
        )}
        <>{children}</>
      </div>
    </>
  );
};

export default MenuLayout;
