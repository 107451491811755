/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { NotificationManager } from "react-notifications";
import { IMAGE_TYPES } from "../../constants/file-types";
import { getFileType } from "../../utils/getFileType";

import styles from "./index.module.scss";
import ImageContainer from "containers/ImageContainer";
import { useTranslation } from "react-i18next";

const FileUploader = (props) => {
  const [focus, setFocus] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(props.initImagePreview || null);
  const [isFileInvalid, setIsFileInvalid] = useState(false);
  const [dragEnter, setDragEnter] = useState(false);
  const { t } = useTranslation(["notification"]);
  const ref = useRef(null);

  const onSelectChange = (value) => {
    const file = ref?.current?.files?.[0];
    const fileSize = file.size / 1024 / 1024;

    const isFileSizeCorrect = checkFileSize(fileSize);

    if (!isFileSizeCorrect) {
      setSelectedFiles(false);
      props.onChange?.(null);
      return;
    }

    props.onChange?.(file);

    setSelectedFiles(true);
    setPreviewUrl(URL.createObjectURL(file));
    setIsFileInvalid(false);
  };

  const onDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setDragEnter(false);

    if (ev.dataTransfer.files && ev.dataTransfer.files?.[0]) {
      const file = ev.dataTransfer.files[0];
      const type = getFileType(file.name);
      const fileSize = file.size / 1024 / 1024;

      const isFileSizeCorrect = checkFileSize(fileSize);
      const isFileTypeCorrect = checkFileType(file, type);

      if (!isFileSizeCorrect || !isFileTypeCorrect) {
        setSelectedFiles(false);
        props.onChange?.(null);
        return;
      }

      props.onChange?.(file);
      setPreviewUrl(URL.createObjectURL(file));
      setSelectedFiles(true);
      setIsFileInvalid(false);
    }
  };

  const checkFileType = (file, type) => {
    if (file && !IMAGE_TYPES[type]) {
      setIsFileInvalid(true);
      NotificationManager.error(t("errorImageType"), t("errorTryAgain"), 5000);
      return false;
    }
    return true;
  };

  const checkFileSize = (fileSize) => {
    if (fileSize > 20) {
      setIsFileInvalid(true);
      NotificationManager.error(t("errorImageSize"), t("errorTryAgain"), 5000);
      return false;
    }
    return true;
  };

  useEffect(() => {
    function event(ev) {
      ev.preventDefault();
    }
    document.addEventListener("dragover", event, false);

    return () => {
      document.removeEventListener("dragover", event, false);
    };
  }, []);

  useEffect(() => {
    if (props.isResponseSuccessful) {
      setSelectedFiles(null);
      props.onChange?.(null);
    }
  }, [props.isResponseSuccessful]);

  return (
    <div
      className={cx(styles["file-upload"], {
        [styles["selected"]]: selectedFiles,
        [styles["invalid-type"]]: isFileInvalid,
      })}
    >
      {!selectedFiles && !previewUrl && (
        <i className={styles["file-upload__icon"]}></i>
      )}
      {selectedFiles && (
        <img
          className={styles["file-upload__preview"]}
          src={previewUrl}
          alt="uploaded-preview"
        />
      )}
      {!selectedFiles && previewUrl && (
        <ImageContainer imageLink={previewUrl} imageUuid={previewUrl} />
      )}
      <input
        {...props}
        className={styles["file-upload__input"]}
        type={props.type}
        placeholder={props.placeholder}
        disabled={props.disabled}
        value={props.value}
        onBlur={() => setFocus(false)}
        onDragStart={(ev) => {
          ev.dataTransfer.effectAllowed = "all";
          ev.dataTransfer.dropEffect = "move";
          ev.preventDefault();
          ev.stopPropagation();
        }}
        onChange={onSelectChange}
        ref={ref}
        accept={props.fileTypes}
        onDrop={onDrop}
        onDragOver={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
        onDragLeave={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setDragEnter(false);
        }}
        onDragEnter={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setDragEnter(true);
        }}
      />
    </div>
  );
};

export default FileUploader;
