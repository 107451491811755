import * as React from "react";

const ArrowDown = (props) => (
  <svg
    width="26"
    height="16"
    viewBox="0 0 26 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L13.5123 13L24 2"
      stroke="#3DDDE8"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowDown;
