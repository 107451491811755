import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import { useTranslation } from "react-i18next";
import CalendarInput from "components/DatePicker";
import styles from "./index.module.scss";
import Button from "components/Button";

const ModalTimeAndDatePicker = ({
  isOpen,
  setIsOpenModal,
  control,
  errors,
  name,
  title,
  cancelHandler = () => {},
  successHandler = () => {},
  isDatePickerValueValid,
}) => {
  const { t } = useTranslation(["button", "common"]);
  const [mounted, setMounted] = useState(false);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        // ADD GET INFO BY data.id LOGIC
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
        <div
          ref={innerRef}
          className={styles["modal-time"]}
          style={{
            pointerEvents: "auto",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles["modal-time__title"]}>{title}</div>

          <div className={styles["modal-time__calendar-wrapper"]}>
            <CalendarInput
              control={control}
              name={name}
              placeholder={""}
              isPlaceholderWhite
              dateFormat={"dd.MM.yyyy, HH:mm 'GMT' XXX"}
              customTimeInput
              open
              isPopper
              rules={{
                required: t("common:form.validation.required"),
              }}
              errors={errors[name]}
            />
          </div>

          <div className={styles["modal-time__btns"]}>
            <Button
              disabled={!isDatePickerValueValid}
              autoFocus
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                successHandler();
              }}
            >
              {t("save")}
            </Button>

            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                cancelHandler();
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Modal>
    </>,
    document.getElementById("root"),
  );
};

export default ModalTimeAndDatePicker;
