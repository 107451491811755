import * as React from "react";

const ArrowBack = (props) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.707 0.957031H7.707V1.9562H11.707C13.085 1.9562 14.207 3.07728 14.207 4.45413C14.207 5.83099 13.085 6.95207 11.707 6.95207H1.914L5.06 3.80867L4.354 3.10126L0 7.45165L4.354 11.802L5.061 11.0956L1.914 7.95124H11.707C13.637 7.95124 15.207 6.38254 15.207 4.45413C15.207 2.52573 13.637 0.957031 11.707 0.957031Z"
      fill="#3DDDE8"
    />
  </svg>
);

export default ArrowBack;
