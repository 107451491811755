import * as React from "react";

const Telegram = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
      fill="url(#paint0_linear_8253_4393)"
    />
    <path
      d="M22.3008 8.63129L19.623 22.1371C19.623 22.1371 19.248 23.0746 18.2168 22.6235L12.0352 17.8832L9.78515 16.7993L6 15.5278C6 15.5278 5.41992 15.3227 5.36133 14.8715C5.30273 14.4204 6.01757 14.1801 6.01757 14.1801L21.0645 8.27973C21.0645 8.27387 22.3008 7.72894 22.3008 8.63129Z"
      fill="white"
    />
    <path
      d="M11.5547 21.9844C11.5547 21.9844 11.373 21.9668 11.1504 21.2578C10.9277 20.5488 9.78516 16.7988 9.78516 16.7988L18.873 11.0273C18.873 11.0273 19.4004 10.7109 19.377 11.0273C19.377 11.0273 19.4707 11.0859 19.1895 11.3438C18.9082 11.6074 12.0527 17.7715 12.0527 17.7715"
      fill="#D2E4F0"
    />
    <path
      d="M14.4023 19.6992L11.959 21.9316C11.959 21.9316 11.7656 22.0781 11.5605 21.9844L12.0293 17.8418"
      fill="#B5CFE4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8253_4393"
        x1="15"
        y1="0"
        x2="15"
        y2="29.8906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#41BCE7" />
        <stop offset="1" stop-color="#22A6DC" />
      </linearGradient>
    </defs>
  </svg>
);

export default Telegram;
