/* eslint-disable react-hooks/exhaustive-deps */
import AddButton from "components/AddButton";
import FilterItem from "components/FilterItem";
import React from "react";
import { useNavigate } from "react-router";

import styles from "./index.module.scss";

const NotificationFilter = ({ items, onChange }) => {
  const navigate = useNavigate();

  return (
    <div className={styles["notification-filter"]}>
      <div className={styles["notification-filter__inner"]}>
        <ul className={styles["notification-filter__list"]}>
          {items.map((item) => {
            return (
              <li className={styles["notification-filter__list-item"]} key={item.id}>
                <FilterItem data={item} onChange={onChange} />
              </li>
            );
          })}
        </ul>
        <div className={styles["notification-filter__add"]}>
          <AddButton
            border
            onClick={() => {
              navigate("new");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationFilter;
