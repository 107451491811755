import React, { useRef, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";

import styles from "./index.module.scss";
import TimeSlider from "../TimeSlider";

const formatHHTime = (value) => {
  const val = Number(value);

  if (val <= 0 || !Number.isInteger(val)) {
    return "00";
  }

  if (val < 10) {
    return `0${Number(val)}`;
  }

  if (val > 23) {
    return 23;
  }

  return val;
};

const formatMMTime = (value) => {
  const val = Number(value);

  if (val <= 0 || !Number.isInteger(val)) {
    return "00";
  }

  if (val < 10) {
    return `0${Number(val)}`;
  }

  if (val > 59) {
    return 59;
  }

  return val;
};

const CustomTimeInput = (props) => {
  const { date, onChange } = props;

  const [hh, setHH] = useState(formatHHTime(new Date(date).getHours()));
  const [mm, setMM] = useState(formatMMTime(new Date(date).getMinutes()));

  const [sliderOpen, setSliderOpen] = useState(false);

  const hhRef = useRef(null);
  const mmRef = useRef(null);

  const triggerOnChange = (hh, mm) => {
    const formattedHH = formatHHTime(hh);
    const formattedMM = formatMMTime(mm);
    onChange(`${formattedHH}:${formattedMM}`);
  };

  return (
    <div className={styles["custom-timeinput"]}>
      <div className={styles["custom-timeinput__range"]}>
        <div className={styles["custom-timeinput__range-hh"]}>
          <input
            ref={hhRef}
            value={hh}
            type="text"
            onChange={(e) => {
              setHH(formatHHTime(e.target.value));
            }}
            onBlur={(e) => {
              triggerOnChange(e.target.value, mm);
            }}
            tabIndex="0"
          />
        </div>
        <span className={styles["separator"]}>:</span>
        <div className={styles["custom-timeinput__range-mm"]}>
          <input
            ref={mmRef}
            value={mm}
            type="text"
            max={59}
            onChange={(e) => {
              setMM(formatMMTime(e.target.value));
            }}
            onBlur={(e) => {
              triggerOnChange(hh, e.target.value);
            }}
            tabIndex="0"
          />
        </div>
      </div>
      <div
        className={styles["custom-timeinput__range-controls"]}
        onClick={(e) => {
          setSliderOpen(true);
        }}
      >
        <div className={styles["btn-up"]}></div>
        <div className={styles["btn-down"]}></div>
      </div>
      <TimeSlider
        hh={hh}
        setHH={(hh) => {
          triggerOnChange(hh, mm);
        }}
        mm={mm}
        setMM={(mm) => {
          triggerOnChange(hh, mm);
        }}
        setSliderOpen={setSliderOpen}
        isOpen={sliderOpen}
      />
    </div>
  );
};

export default React.memo(CustomTimeInput);
