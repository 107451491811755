import React from "react";
import cx from "classnames";
import CopyToBufferButton from "components/CopyToBufferButton";
import { copyToBuffer } from "utils/copyToBuffer";
import Copy from "../../../assets/svg/Copy";

import VectorUp from "assets/svg/VectorUp";
import VectorDown from "assets/svg/VectorDown";

import styles from "../index.module.scss";
import ArrowBack from "assets/svg/ArrowBack";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { formatDateForHistoryTable } from "utils/date";

// MOVED TO LOCALIZATION DICTIONARY
const STATUS = {
  win: "Виграш",
  fail: "Ставка не зіграла",
  cancel: "Відмінено",
  pending: "Очікується",
  done: "Готово",
};

const HistoryCol = ({
  data,
  headers,
  deleteHandler = () => {},
  editHandler = () => {},
  openModal = () => {},
  loggedUserRole,
}) => {
  const { t } = useTranslation(["event"]);


  return headers.map((header, index) => (
   
    <td
      key={header.key}
      className={cx({
        [styles[`${data[header.key]}`]]: header.key === "bidStatus",
        [styles.copy]: header.key === "support_code",
      })}
    >
      {header.key === "actions" ? (
        <Button
          disabled={loggedUserRole === "ROLE_TECH_SUPPORT" || loggedUserRole === "ROLE_MARKETER"}
          type="clear"
          width={18}
          height={12}
          onClick={() => openModal(header.key, data.eventUuid)}
        >
          <ArrowBack />
        </Button>
      ) : header.key === "eventBidDirection" ? (
        <div>
          {data[header.key] === "BID_UP" && <VectorUp />}
          {data[header.key] === "BID_DOWN" && <VectorDown />}
        </div>
        ) : header.key === "value" ? (<span>{ data.amount ? data.amount : data.value}</span>) : header.key === "userBalance" && !data["userBalance"]? (<span>0</span>) :  !data[header.key]  ? (
            <span>-</span>
      ) : header.key === "bidStatus" ? (
        <span
          className={cx({
            [styles["green"]]: data.bidStatus === "WIN",
            [styles["red"]]: data.bidStatus === "CANCELED" || data.bidStatus === "LOSE",
          })}
        >
          {t(`event:status.${data[header.key]}`)}
        </span>
      ) : header.key === "status" ? (
        <>
          {data[header.key] === "CANCELED" ? (
            <span style={{ color: "#EB4755" }}>Отменен</span>
          ) : data[header.key] === "CONFIRMED" ? (
            <span style={{ color: "#4DB275" }}>Готово</span>
          ) : (
            <span style={{ color: "#FFD01F" }}>Ожидается</span>
          )}
        </>
      ) : header.key === "supportCode" ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              copyToBuffer(data[header.key]);
            }}
          >
            <span>{data[header.key]}</span>
            <Copy width={15} height={15} />
          </div>
        </>
      ) : header.key === "operationTime" ? (
        <span>{formatDateForHistoryTable(data[header.key])}</span>
      ) : (
        <span>{data[header.key]}</span>
      )}
    </td>
  ));
};

export default HistoryCol;
