import React from "react";
import jwtDecode from "jwt-decode";
import { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Page from "./components/Page";
import AuthGuard from "./containers/AuthGuard";
import { PRIVATE_ROUTES } from "routes/private-routes";
import { selectIsTwoFaEnabled } from "store/slices/crypto";
import { selectLoggedUserRole, saveLoggedUserRole } from "store/slices/users";

import "./index.scss";

function App() {
  const auth = useSelector((state: any) => state.auth.isAuthorized);
  const isTwoFaEnabled = useSelector(selectIsTwoFaEnabled);
  const loggedUserRole = useSelector(selectLoggedUserRole);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const dispatch = useDispatch();

  const decodeToken = () => {
    try {
      const decoded: any = jwtDecode(token!);
      dispatch(saveLoggedUserRole(decoded.roles[1]));
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };

  React.useEffect(() => {
    decodeToken();
  }, [token]);

  return (
    <>
      <Page isAuth={auth} isTwoFaEnabled={isTwoFaEnabled} loggedUserRole={loggedUserRole}>
        <Routes>
          <Route path="/">
            {!auth && <Route index element={<Navigate to="sign-in" state={{ from: location }} replace />} />}
            {!auth && <Route path="sign-in" element={<Login />} />}
            <Route element={<AuthGuard isAuth={auth} />}>
              <Route index element={<Home />} />
              {PRIVATE_ROUTES.map((route) => (
                <Route key={route.id + route.path} path={route.path} element={route.component} />
              ))}
            </Route>
            <Route path="*" element={<Navigate to="/" state={{ from: location }} replace />} />
          </Route>
        </Routes>
      </Page>
      <Toaster position="top-right" />
    </>
  );
}

export default App;
