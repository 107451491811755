// React && Styling
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomers,
  selectCustomersData,
  selectCustomersIsLoading,
  decrementCustomerBalance,
  incrementCustomerBalance,
} from "../../store/slices/users";

// Components
import Input from "components/Input";
import Loader from "components/Loader";
import PaginationComponent from "components/PaginationComponent";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import ModalTwoFactorPin from "containers/ModalTwoFactorPin";

// Utils && Libs && Constants
import { BALANCE_HEADERS } from "constants/table";
import { numberFormat } from "utils/numberFormat";
import { useTranslation } from "react-i18next";
import useDebounce from "../../hooks/useDebounce/useDebounce";
import { useForm } from "react-hook-form";

const mutationUserDataToTableFormat = (items = []) => {
  const tableData = {
    header: BALANCE_HEADERS,
    data: items?.map((item) => ({
      ...item,
      balance: numberFormat(item.balance) || "",
    })),
  };

  return tableData;
};

const EditBalance = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "modals"]);
  const [searchParams] = useSearchParams();
  const emailQuery = searchParams.get("email");
  const usernameQuery = searchParams.get("username");

  // Redux Selectors
  const isLoading = useSelector(selectCustomersIsLoading);
  const listData = useSelector(selectCustomersData);

  // React States
  const [sortByObj, setSortByObj] = useState({ page: 0 });
  const [selectedPage, setSelectedPage] = useState(0);
  const [isPinCodeModalOpen, setIsPinCodeModalOpen] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({
    operation: null,
    username: "",
    addedAmount: null,
    subtractedAmount: null,
  });

  const { control, watch } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      search: "",
    },
  });

  const keyword = useDebounce(() => watch("search"), 700);

  const incrementUserBalance = async (username, addedBalance, code) => {
    const body = {
      query: {
        username,
        amount: addedBalance,
      },
      code,
    };

    return dispatch(incrementCustomerBalance(body)).unwrap();
  };

  const decrementUserBalance = async (username, subtractedBalance, code) => {
    const body = {
      query: {
        username,
        amount: subtractedBalance,
      },
      code,
    };

    return await dispatch(decrementCustomerBalance(body)).unwrap();
  };

  const editUserBalancePinCodeBtnHandler = async (code) => {
    const { operation, username, addedAmount, subtractedAmount } = transactionDetails;

    if (username && operation === "add_balance") {
      try {
        await incrementUserBalance(username, addedAmount, code);
        setIsPinCodeModalOpen(!isPinCodeModalOpen);
        keyword ? dispatch(getCustomers({ ...sortByObj, keyword })) : dispatch(getCustomers(sortByObj));
      } catch (error) {
        console.log(error);
      }
    } else if (username && operation === "minus_balance") {
      try {
        await decrementUserBalance(username, subtractedAmount, code);
        setIsPinCodeModalOpen(!isPinCodeModalOpen);
        keyword ? dispatch(getCustomers({ ...sortByObj, keyword })) : dispatch(getCustomers(sortByObj));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const pinCodeModalBtnHandler = (code) => {
    editUserBalancePinCodeBtnHandler(code);
  };

  useEffect(() => {
    if (emailQuery || usernameQuery) {
      return setSortByObj({
        sortField: usernameQuery ? "username" : emailQuery ? "email" : "",
        sortDirection: usernameQuery ? usernameQuery : emailQuery ? emailQuery : "",
        page: selectedPage,
      });
    } else if (sortByObj?.page !== 0) {
      return setSortByObj({ page: selectedPage });
    }
  }, [emailQuery, usernameQuery, selectedPage]);

  useEffect(() => {
    keyword ? dispatch(getCustomers({ ...sortByObj, keyword })) : dispatch(getCustomers(sortByObj));
  }, [sortByObj, dispatch, keyword]);

  useEffect(() => {
    document.title = t("common:editBalance.title");
  }, [t]);

  return (
    <div className={styles.balance}>
      <div className={styles.header}>
        <span className={styles.title}>Редагування балансу</span>
        <div className={styles.search}>
          <Input control={control} name="search" type="text" role="search" size="sm-2" />
        </div>
      </div>
      <div className={styles.body}>
        {!isLoading && (
          <>
            <div className={styles.table}>
              <TableComponent
                type={TABLE_VIEW.BALANCE}
                sort
                data={mutationUserDataToTableFormat(listData?.content)}
                keyword={keyword}
                sortByObj={sortByObj}
                setUserBalancePinCodeModal={setIsPinCodeModalOpen}
                setUserBalanceTransactionDetails={setTransactionDetails}
              />
            </div>
            <div className={styles.pagination}>
              <PaginationComponent
                itemsPerPage={1}
                totalPages={listData?.totalPages}
                forcePage={listData?.pageable?.pageNumber}
                onChanePage={(page) => {
                  setSelectedPage(page - 1);
                  dispatch(
                    getCustomers({
                      ...sortByObj,
                      page: page - 1,
                    }),
                  );
                }}
              />
            </div>
          </>
        )}
        {isLoading && <Loader />}
      </div>

      <ModalTwoFactorPin
        isOpen={isPinCodeModalOpen}
        setIsOpenModal={setIsPinCodeModalOpen}
        type
        successHandler={pinCodeModalBtnHandler}
        cancelHandler={() => setIsPinCodeModalOpen(!isPinCodeModalOpen)}
      />
    </div>
  );
};

export default EditBalance;
