import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CalendarInput from "components/DatePicker";
import styles from "./index.module.scss";
import Button from "components/Button";

const ModalRangeDatePicker = ({
  title,
  isUserHours,
  data,
  userId,
  isOpen,
  setIsOpenModal,
  type,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onDateChange = () => {},
  resetDates = () => {},
  cancelHandler = () => {},
  successHandler = () => {},
}) => {
  const { t } = useTranslation(["button", "common"]);
  const [mounted, setMounted] = useState(false);
  const dateNow = new Date();

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  const { control, handleSubmit, formState, setValue, watch } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      time: new Date(),
    },
  });
  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        // ADD GET INFO BY data.id LOGIC
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
        <div
          ref={innerRef}
          className={styles["modal-time"]}
          style={{
            pointerEvents: "auto",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles["modal-time__title"]}>{title}</div>

          <div className={styles["modal-time__calendar-wrapper"]}>
            <CalendarInput
              control={control}
              selected={dateNow}
              name={"userHoursRange"}
              placeholder={""}
              open
              customTimeInput
              isUserHours={isUserHours}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onChange={onDateChange}
              resetDates={resetDates}
              dateFormat={"dd.MM.yyyy, HH:mm"}
              rules={{
                required: t("common:form.validation.required"),
              }}
              errors={errors.userHoursRange}
            />
          </div>

          <div className={styles["modal-time__btns"]}>
            <Button
              autoFocus
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                successHandler();
              }}
            >
              {t("save")}
            </Button>

            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                cancelHandler();
                resetDates();
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Modal>
    </>,
    document.getElementById("root"),
  );
};

export default ModalRangeDatePicker;
