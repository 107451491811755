import React, { useState, useEffect } from "react";

import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import Input from "../../components/Input";
import { ACCEPT_IMAGE_MIME_TYPES } from "../../constants/file-types";
import {
  deleteFromCacheImage,
  editImage,
  selectIsImageUploading,
  uploadImage,
} from "../../store/slices/image";
import { useOuterClick } from "../../utils/useOuterClick";
import FileUploader from "../FileUploader";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const FileUploadModal = ({
  isOpen,
  setIsOpenModal,
  imageToEdit,
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const [imageFile, setImageFile] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [abortControllr, setAbortController] = useState(null);
  const [progress, setProgress] = useState(0);

  const dispatch = useDispatch();
  const isUploading = useSelector(selectIsImageUploading);
  const { t } = useTranslation(["notification", "common"]);

  const { control, handleSubmit, formState, reset, setValue, trigger } =
    useForm({
      reValidateMode: "onChange",
      mode: "onTouched",
      defaultValues: {
        label: "",
      },
    });
  const { errors, isValid } = formState;

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      reset({
        label: "",
      });
      setImageFile(null);
      setIsOpenModal();
      cancelHandler();
    }
  });

  const requestConfig = () => {
    const controller = new AbortController();
    setAbortController(controller);
    return {
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total;
        const persent = total / 100;
        const uploaded = progressEvent.loaded / persent;
        setProgress(Number(uploaded.toFixed()));
      },
      signal: controller.signal,
    };
  };

  const createImage = (formData) => {
    dispatch(
      uploadImage({
        formData,
        config: requestConfig(),
      })
    )
      .unwrap()
      .then((res) => {
        successHandler();
        setIsOpenModal();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorTryAgain"), 10000);
      });
  };

  const editImg = (formData) => {
    dispatch(
      editImage({
        id: imageToEdit.imageUuid,
        formData,
        config: requestConfig(),
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(deleteFromCacheImage(res.imageUuid));
        successHandler();
        setIsOpenModal();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorTryAgain"), 10000);
      });
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("label", data.label);
    if (imageFile) {
      formData.append("file", imageFile, imageFile.name.split(".")[0]);
    }

    if (!imageToEdit) {
      createImage(formData);
    } else {
      editImg(formData);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setValue("label", "");
      setImageFile(null);
      setTimeout(() => {
        if (imageToEdit) {
          setValue("label", imageToEdit?.label);
          forseCheckValidation();
        }
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  const forseCheckValidation = async () => {
    await trigger(["label"]);
  };

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div ref={innerRef} className={styles["file-modal"]}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles["file-modal__form"]}
        >
          <div className={styles["file-modal__form-row"]}>
            <FileUploader
              name="file"
              type="file"
              id="file"
              isUploading={isUploading}
              onAboart={() => {
                abortControllr?.abort();
                setProgress(0);
              }}
              onChange={(e) => setImageFile(e)}
              fileTypes={ACCEPT_IMAGE_MIME_TYPES}
              initImagePreview={imageToEdit?.imageLink}
            />
          </div>
          <div className={styles["file-modal__form-row"]}>
            <Input
              control={control}
              id="label"
              name="label"
              size="sm"
              label={t("common:form.label.imageName")}
              rules={{
                required: t("common:form.validation.required"),
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  if (!isUploading && (isValid || (imageFile && imageToEdit))) {
                    handleSubmit(onSubmit)();
                  }
                }
              }}
              errors={errors.label}
            />
          </div>
          <div className={styles["file-modal__form-row"]}>
            <div className={styles.btns}>
              <Button
                type="cancel"
                disabled={isUploading}
                onClick={(e) => {
                  abortControllr?.abort();
                  setProgress(0);
                  reset({
                    label: "",
                  });
                  setImageFile(null);
                  cancelHandler();
                  setIsOpenModal();
                }}
              >
                {t("button:cancel")}
              </Button>
              <Button
                type="submit"
                disabled={
                  !isValid || (!imageFile && !imageToEdit) || isUploading
                }
              >
                {t("button:save")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default FileUploadModal;
