import * as React from "react";

const Error = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="15" cy="15" r="14" stroke="#E63232" stroke-width="2" />
    <line
      x1="8.22949"
      y1="21.5252"
      x2="21.8783"
      y2="7.87633"
      stroke="#E63232"
      stroke-width="3"
      stroke-linecap="round"
    />
    <line
      x1="8.12132"
      y1="7.87598"
      x2="21.7701"
      y2="21.5248"
      stroke="#E63232"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
);

export default Error;
