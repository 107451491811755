import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    document.title = "MyCoin - " + t("common:auth");
  }, []);

  return (
    <div className={styles.login}>
      <img src={require("../../assets/Gerb.png")} />
    </div>
  );
};

export default Login;
