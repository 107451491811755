import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

const ImageContainer = ({ imageLink, imageUuid }) => {

  return (
    <div
      className={cx(styles["box"], {
        [styles["fallback-bg"]]: !imageLink,
      })}
    >
      {imageLink && <img src={imageLink} alt={imageUuid} />}
    </div>
  );
};

export default React.memo(ImageContainer);
