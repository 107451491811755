import React from "react";
import cx from "classnames";

import Important from "assets/svg/Important";
import Info from "assets/svg/Info";

import styles from "./index.module.scss";
import Critical from "assets/svg/Critical";

const Status = ({ type }) => {
  const getStatusIcon = () => {
    switch (type) {
      case 1:
        return <Important />;
      case 2:
        return <Info />;
      case 3:
        return <Critical />;
      default:
        return <Info />;
    }
  };

  return (
    <div
      className={cx(styles.status, {
        [styles[type]]: !!type,
      })}
    >
      {getStatusIcon()}
    </div>
  );
};

export default Status;
