import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import NotificationFilter from "containers/NotificationFilter";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import PaginationComponent from "components/PaginationComponent";
import LangComponent from "components/LangComponent";
import ModalWarning, { ErrorText } from "containers/ModalWarning";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification, getNotifications, selectNotifListData } from "store/slices/notification";

import styles from "./index.module.scss";
import { notificationFilters } from "../../constants/notificationTypes";
import { NOTIFICATION_TABLE_HEADERS } from "../../constants/table";
import { parseDateToFormat } from "../../utils/date";
import { lang } from "../../store/slices/langSlice";

const NotificationPage = () => {
  const { t } = useTranslation(["common", "modals"]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const listData = useSelector(selectNotifListData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get("page") || 1;
  const stateLang = useSelector(lang);

  const mutationNotificationsDataToTableFormat = (items = []) => {
    return items?.length
      ? {
          header: NOTIFICATION_TABLE_HEADERS,
          data: items?.map((item) => ({
            create_date: parseDateToFormat(item?.creationDate, "DD.MM.YYYY"),
            end_date: parseDateToFormat(item?.endDate, "DD.MM.YYYY"),
            end_date_full: item?.endDate,
            notification_text: item?.message,
            status:
              item?.status === "CRITICAL"
                ? 3
                : item?.status === "IMPORTANT"
                ? 1
                : item?.status === "INFORMATIONAL"
                ? 2
                : item?.status === "TELEGRAM"
                ? 4
                : "",
            id: item?.id,
          })),
        }
      : [];
  };

  const navigateToPage = (page) => {
    const params = new URLSearchParams(searchParams);
    params.set("page", page);

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  };

  const fetchList = () => {
    const params = new URLSearchParams(searchParams);
    const status = params.get("status");
    const page = params.get("page");
    const promise = dispatch(getNotifications({ status, page: page ? page - 1 : "0" }));
    return promise;
  };

  useEffect(() => {
    const promise = fetchList();

    return () => {
      promise.abort();
    };
  }, [searchParams, stateLang]);

  useEffect(() => {
    document.title = t("common:notificationPage.title");
  }, []);

  const [notificationId, setNotificationId] = useState("");

  return (
    <div className={styles.notification}>
      <div className={styles.notification__inner}>
        <div className={styles["notification__header"]}>
          <span className={styles["notification__title"]}>{t("common:notificationPage.title")}</span>
          <NotificationFilter items={notificationFilters} />
        </div>

        <div className={styles["notification__body"]}>
          <TableComponent
            data={mutationNotificationsDataToTableFormat(listData?.content || listData)}
            type={TABLE_VIEW.NOTIFICATION}
            deleteHandler={(data) => {
              setIsOpenModal(true);
              setNotificationId(data.id);
            }}
            editHandler={(data) => {
              navigate(`/notification/edit/${data.id}`);
            }}
          />
        </div>

        <div className={styles["notification__pagination"]}>
          <PaginationComponent
            itemsPerPage={1}
            totalPages={listData?.totalPages ?? 0}
            forcePage={pageQuery - 1}
            onChanePage={(page) => {
              navigateToPage(page);
            }}
          />
        </div>
      </div>

      <div className={styles.notification__lang}>
        <LangComponent viewType="vertical" />
      </div>

      <ModalWarning
        isOpen={isOpenModal}
        setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
        successHandler={async () => {
          await dispatch(deleteNotification(notificationId));
          fetchList();
          setIsOpenModal(false);
        }}
        type="isQuestion"
        title={
          <Trans i18nKey="modals:deleteNotification">
            Ти впевнений, що хочеш <ErrorText>Видалити повідомлення?</ErrorText>
          </Trans>
        }
      />
    </div>
  );
};

export default NotificationPage;
