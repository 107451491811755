/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import cx from "classnames";
import { useWindowWidth } from "@react-hook/window-size";
import ReactPaginate from "react-paginate";

import styles from "./index.module.scss";

const NextBtn = () => {
  return (
    <div className={cx(styles["pagination-bnt"], styles.next)}>
      <i></i>
    </div>
  );
};

const PrevBtn = () => {
  return (
    <div className={cx(styles["pagination-bnt"])}>
      <i></i>
    </div>
  );
};

const PaginationComponent = ({
  isAdminTbl,
  itemsPerPage,
  totalPages,
  forcePage,
  onChanePage = () => {},
  wrapp,
}) => {
  const [currentItems, setCurrentItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  let items = [];

  const width = useWindowWidth();
  const isMobile = () => width < 1024;

  for (let number = 1; number <= totalPages; number++) {
    items.push(number);
  }

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, totalPages]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    onChanePage(event.selected + 1);
  };

  return (
    <ReactPaginate
      className={cx(styles["pagination-wrapper"], {
        [styles.wrapp]: wrapp,
      })}
      breakLabel="..."
      nextLabel={<NextBtn />}
      previousLabel={<PrevBtn />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={1}
      marginPagesDisplayed={isMobile() ? 1 : 3}
      pageCount={isAdminTbl ? totalPages : pageCount}
      renderOnZeroPageCount={null}
      forcePage={forcePage}
    />
  );
};
export default PaginationComponent;
