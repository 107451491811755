import * as React from "react";

const Close = (props) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 2L51.4463 52" stroke="#3DDDE8" stroke-width="3" />
    <path d="M52 2L2.55371 52" stroke="#3DDDE8" stroke-width="3" />
  </svg>
);

export default Close;
