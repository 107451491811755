import * as React from "react";

const Info = (props) => (
  <svg
    width="20"
    height="34"
    viewBox="0 0 20 34"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0049 2.69573V2"
      stroke="#0BB2FA"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M10.0062 31.5231V10.3086H5.7915"
      stroke="#0BB2FA"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M2 32H18"
      stroke="#0BB2FA"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default Info;
