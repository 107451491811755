import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import styles from "./index.module.scss";
import Button from "components/Button";
import X from "assets/svg/X";
import Download from "assets/svg/Download";
import { Trans } from "react-i18next";
import classNames from "classnames/bind";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import ModalRangeDatePicker from "containers/ModalRangeDatePicker";
import { formatDateToCustomFormat } from "utils/date";
import Calendar from "../../assets/controls/Calendar";
import PaginationComponent from "components/PaginationComponent";
import { getMainWalletsAction, downloadMainWalletsAction } from "store/slices/crypto";
import { calculateDateOneWeekEarlier } from "utils/date";

const cx = classNames.bind(styles);

const ModalMainWalletHistoryTable = ({ isOpen, setIsOpenModal, data, cancelHandler = () => {}, totalPages }) => {
  const [mounted, setMounted] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState("all");

  const createTableHeaders = (type) => {
    switch (type) {
      case "DEPOSIT":
        return [
          {
            key: "id",
            label: "ID транзакції",
          },

          {
            key: "operationTime",
            label: "Дата",
          },

          {
            key: "active",
            label: "Актив",
          },

          {
            key: "amount",
            label: "Сума",
          },

          {
            key: "fee_trx",
            label: "Комісія",
          },

          {
            key: "toAddress",
            label: "На адресу",
          },

          {
            key: "status",
            label: "Статус",
          },
        ];
      case "WITHDRAW":
        return [
          {
            key: "id",
            label: "ID транзакції",
          },

          {
            key: "operationTime",
            label: "Дата",
          },

          {
            key: "active",
            label: "Актив",
          },

          {
            key: "amount",
            label: "Сума",
          },

          {
            key: "fee_trx",
            label: "Комісія",
          },

          {
            key: "fromAddress",
            label: "З адреси",
          },

          {
            key: "status",
            label: "Статус",
          },
        ];

      default:
        return [
          {
            key: "id",
            label: "ID транзакції",
          },

          {
            key: "operationTime",
            label: "Дата",
          },

          {
            key: "active",
            label: "Актив",
          },

          {
            key: "amount",
            label: "Сума",
          },

          {
            key: "fee_trx",
            label: "Комісія",
          },

          {
            key: "fromAddress",
            label: "З адреси",
          },

          {
            key: "toAddress",
            label: "На адресу",
          },

          {
            key: "status",
            label: "Статус",
          },
        ];
    }
  };

  const mutateDataForMainWalletsTable = (mainWalletsArray) => {
    return {
      header: createTableHeaders(activeLink),

      data: mainWalletsArray,
    };
  };

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  const handleSelect = (selectedKey) => {
    dispatch(getMainWalletsAction({ query: { size: 8 }, startDate, endDate, type: selectedKey }));
    setActiveLink(selectedKey);
  };

  const setDatePickerDefaultDates = () => {
    const { todaysDate, sevenDaysAgoDate } = calculateDateOneWeekEarlier();
    setStartDate(sevenDaysAgoDate);
    setEndDate(todaysDate);
  };

  const onDateChange = (dates) => {
    if (dates.length) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
    }
  };

  const handlePaginationClick = (pageNum) => {
    dispatch(getMainWalletsAction({ query: { size: 8, page: pageNum - 1 }, startDate, endDate, type: activeLink }));
  };

  const modalCloseBtnHandler = (e) => {
    e.stopPropagation();
    cancelHandler();
    setIsOpenModal();
  };

  useEffect(() => {
    setDatePickerDefaultDates();
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
        <div
          ref={innerRef}
          className={styles["modal"]}
          style={{
            pointerEvents: "auto",
          }}
        >
          <div className={styles["modal__header"]}>
            <ul className={styles["header__nav-menu"]}>
              <li
                className={cx(styles["menu-item"], {
                  [styles["active"]]: activeLink === "all",
                })}
                onClick={() => {
                  handleSelect("all");
                }}
              >
                Всі
              </li>

              <li
                className={activeLink === "DEPOSIT" ? styles.active : ""}
                onClick={() => {
                  handleSelect("DEPOSIT");
                }}
              >
                Поповнення
              </li>

              <li
                className={activeLink === "WITHDRAW" ? styles.active : ""}
                onClick={() => {
                  handleSelect("WITHDRAW");
                }}
              >
                Вивід
              </li>
            </ul>

            <div className={styles["header__calendar-container"]}>
              <div className={styles["modal__btn"]}>
                <button
                  className={styles["modal__date-btn"]}
                  onClick={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
                >
                  <Calendar />
                  {formatDateToCustomFormat(startDate)} - {formatDateToCustomFormat(endDate)}
                </button>
              </div>
              <div className={styles["modal__btn"]}>
                <Button
                  type="clear"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(downloadMainWalletsAction({ startDate, endDate, type: activeLink }));
                  }}
                >
                  <Download />
                </Button>
              </div>
            </div>

            <div className={styles["modal__btn"]}>
              <Button type="clear" onClick={modalCloseBtnHandler}>
                <X />
              </Button>
            </div>
          </div>

          <div
            className={cx(styles["modal__table"], {
              [styles["expanded-table"]]: isExpanded,
            })}
          >
            <TableComponent type={TABLE_VIEW.MAINWALLET} data={mutateDataForMainWalletsTable(data)} />
          </div>

          <PaginationComponent isAdminTbl totalPages={totalPages} onChanePage={handlePaginationClick} />
        </div>
      </Modal>

      <ModalRangeDatePicker
        isOpen={isCalendarModalOpen}
        setIsOpenModal={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
        isUserHours
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
        resetDates={setDatePickerDefaultDates}
        successHandler={async () => {
          await dispatch(getMainWalletsAction({ query: { size: 8 }, startDate, endDate, type: activeLink }));

          setIsCalendarModalOpen(!isCalendarModalOpen);
        }}
        cancelHandler={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
        title={<Trans i18nKey="modals:chooseTime">Оберіть проміжок часу</Trans>}
      />
    </>,
    document.getElementById("root"),
  );
};

export default ModalMainWalletHistoryTable;
