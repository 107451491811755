// React
import React, { useEffect, useState } from "react";
import "./index.scss";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsLoading,
  enableTwoFaAction,
  disableTwoFaAction,
  checkIf2FAEnabledAction,
  selectIsTwoFaEnabled,
} from "store/slices/crypto";
// Components
import ModalWarning from "containers/ModalWarning";
import GoogleIcon from "../../assets/controls/google";
import Button from "components/Button";
import ModalTwoFactorPin from "containers/ModalTwoFactorPin";
import ModalTwoFactor from "containers/ModalTwoFactor";
import Loader from "components/Loader";

// Libs
import { NotificationManager } from "react-notifications";

const Home = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const isTwoFaEnabled = useSelector(selectIsTwoFaEnabled);
  const isLoading = useSelector(selectIsLoading);
  const [isTwoFaEnabledInfoModalOpen, setIsTwoFaEnabledInfoModalOpen] = useState(false);
  const [isTwoFaDisabledInfoModalOpen, setIsTwoFaDisabledInfoModalOpen] = useState(false);
  const [isTwoFactorModalOpen, setIsTwoFactorModalOpen] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  useEffect(() => {
    document.title = "MyCoin Admin Panel";
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    localStorage.setItem("lang", !lang ? "uk" : lang.toLowerCase());
  }, [token]);

  useEffect(() => {
    dispatch(checkIf2FAEnabledAction());
  }, [dispatch]);

  const handle2FAHeaderBtn = () => {
    if (isTwoFaEnabled) {
      return setIsPinModalOpen(true);
    }
    setIsTwoFactorModalOpen(true);
  };

  const twoFaBtnHandler = async (code) => {
    try {
      await dispatch(enableTwoFaAction({ code })).unwrap();
      dispatch(checkIf2FAEnabledAction());
      setIsTwoFactorModalOpen(!isTwoFactorModalOpen);
      setIsTwoFaEnabledInfoModalOpen(!isTwoFaEnabledInfoModalOpen);
    } catch (error) {
      console.log(error);
    }
  };

  const pinCodeBtnHandler = async (code) => {
    try {
      await dispatch(disableTwoFaAction({ code })).unwrap();
      dispatch(checkIf2FAEnabledAction());
      setIsPinModalOpen(!isPinModalOpen);
      setIsTwoFaDisabledInfoModalOpen(!isTwoFaDisabledInfoModalOpen);
    } catch (error) {
      console.log(error);
      NotificationManager.error("", error?.response?.data.message, 5000);
    }
  };

  return (
    <div className="home">
      <div className="header-wrapper">
        <div>
          <GoogleIcon />
        </div>
        <span>Аутентифікація через Google Authenticator</span>
        <Button
          disabled={isLoading}
          width={141}
          height={43}
          styleBtn={{
            padding: "12px 24px",
            fontSize: "16px",
            lineHeight: "normal",
            fontWeight: "400",
          }}
          onClick={handle2FAHeaderBtn}
        >
          {isTwoFaEnabled ? "Відключити" : "Підключити"}
        </Button>
      </div>

      <img src={require("../../assets/Gerb.png")} alt="main logo" />

      <ModalWarning
        type="isTwoFactor"
        isOpen={isTwoFaEnabledInfoModalOpen}
        setIsOpenModal={setIsTwoFaEnabledInfoModalOpen}
        hideCancelBtn
        title="Двофакторна автентифікація включена"
        text="Ми наполегливо рекомендуємо не відключати цю
        функцію, оскільки це знизить безпеку вашого облікового
        запису."
      />

      <ModalWarning
        type="isTwoFactor"
        isOpen={isTwoFaDisabledInfoModalOpen}
        setIsOpenModal={setIsTwoFaDisabledInfoModalOpen}
        hideCancelBtn
        title="Двофакторна автентифікація вимкнена"
        text="Ми наполегливо рекомендуємо увімкнути цю функцію,
        оскільки це підвищить рівень безпеки вашого облікового запису."
      />

      <ModalTwoFactorPin
        isOpen={isPinModalOpen}
        setIsOpenModal={setIsPinModalOpen}
        type={false}
        cancelHandler={() => setIsPinModalOpen(false)}
        successHandler={pinCodeBtnHandler}
      />

      <ModalTwoFactor
        isOpen={isTwoFactorModalOpen}
        setIsOpenModal={setIsTwoFactorModalOpen}
        successHandler={twoFaBtnHandler}
      />

      {isLoading && <Loader />}
    </div>
  );
};

export default Home;
