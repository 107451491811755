import React, { useEffect, useState } from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Button from "components/Button";
import ArrowUp from "assets/svg/ArrowUp";
import ArrowDown from "assets/svg/ArrowDown";
import { useOuterClick } from "utils/useOuterClick";
import SortArrowUp from "assets/svg/SortArrowUp";
import SortArrowDown from "assets/svg/SortArrowDown";

const DefaultSort = ({ type }) => {
  return (
    <div className={styles[`${type}`]}>
      {type === "asc" ? (
        <>
          A<br />Z <SortArrowUp />
        </>
      ) : (
        <>
          Z<br />A <SortArrowDown />
        </>
      )}
    </div>
  );
};

const SortButton = ({ sortKey, sortOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sort = searchParams.get(sortKey);
  const applySort = (val) => {
    setIsOpen(false);
    const params = new URLSearchParams(searchParams);
    if (sort === val) {
      params.delete(sortKey);
    } else {
     if (sortKey === "username" && searchParams.get("email")) {
       params.delete("email");
     }
      if (sortKey === "email" && searchParams.get("username")) {
        params.delete("username");
      }
      params.set(sortKey, val);
    }

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  };

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      setIsOpen(false);
    }
  });

  const isDefaultSort = (item) => {
    return item.value === "asc" || item.value === "desc";
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return (
    <div className={styles.sort} onClick={() => setIsOpen(!isOpen)}>
      <div className={styles["sort__btn"]}>
        <Button type="clear" width={15} height={10}>
          {isOpen ? <ArrowUp /> : <ArrowDown />}
        </Button>
        {isOpen && (
          <div className={styles["sublist"]} ref={innerRef}>
            {sortOptions.map((item) => {
              return (
                <span
                  onClick={() => {
                    applySort(item.value);
                  }}
                  className={cx({
                    [styles.selected]: sort === item.value,
                    [styles.default]: isDefaultSort(item),
                  })}
                >
                  {isDefaultSort(item) ? (
                    <DefaultSort type={item.value} />
                  ) : (
                    item.label
                  )}
                </span>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SortButton;
