/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowWidth } from "@react-hook/window-size";
import { useDispatch, useSelector } from "react-redux";

import ModalEvent from "../ModalEvent";
import EventCategory from "../../components/EventCategory";
import GearButton from "../../components/GearButton";
import AddButton from "../../components/AddButton";
import ArrowButton from "../../components/ArrowButton";
import {
  createEventCategory,
  selectSelectedEventCategoryId,
  setEventCategorySelected,
  updateEventCategory
} from "../../store/slices/eventCategory";
import { selectIsReadyToSubmit } from "../../store/slices/steps";

import "swiper/css";
import "swiper/css/bundle";
import "./index.scss";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
const TopBar = () => {
  const { t } = useTranslation(["notification"]);
  const categoryList = useSelector(
    (state) => state.eventCategory.eventCategoryList
  );
  const selectedCategoryId = useSelector(selectSelectedEventCategoryId);
  const isNewEventRedyToSubmitData = useSelector(selectIsReadyToSubmit);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [showSlides, setShowSlides] = useState(
    categoryList.length >= 3 ? 3 : categoryList.length
  );
  const width = useWindowWidth();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const viewSlides = () => {
    if (width >= 1920) {
      setShowSlides(7);
    }
    if (width < 1920) {
      setShowSlides(6);
    }
    if (width <= 1400) {
      setShowSlides(4);
    }
    if (width <= 1280) {
      setShowSlides(5);
    }
    if (width <= 1180) {
      setShowSlides(4);
    }
    if (width < 1000) {
      setShowSlides(2);
    }
  };

  const getSliderElementCount = () =>
    categoryList.length >= showSlides ? showSlides : categoryList.length;

  const createCategory = async (data) => {
    setIsOpenModal(!isOpenModal);
    try {
      dispatch(
        createEventCategory({
          ...data.formData,
          categoryColor: data.color
        })
      )
        .unwrap()
        .catch((err) => {
          NotificationManager.error("", t(" errorCreateCategory"), 6000);
        });
    } catch ({ response }) {
    }
  };

  const updateCategory = async (data) => {
    setIsOpenModal(!isOpenModal);
    try {
      dispatch(
        updateEventCategory({
          id: data.id,
          data: {
            ...data.formData,
            categoryColor: data.color
          }
        })
      )
        .unwrap()
        .then(() => {
          NotificationManager.success("", t("successUpdateCategory"), 6000);
        })
        .catch((err) => {
          NotificationManager.error("", t(" errorUpdateCategory"), 6000);
        });
    } catch ({ response }) {
    }
  };

  const selectCategory = async (id) => {
    if (
      window.location.pathname !== "/events/new-event" ||
      !isNewEventRedyToSubmitData
    ) {
      dispatch(setEventCategorySelected(id));
    }
  };

  useEffect(() => {
    viewSlides();
  }, [width, categoryList]);

  return (
    <div className="header-inner">
      <div className="header-events">
        <div
          className="header-events__prev"
          ref={navigationPrevRef}
          style={{ opacity: categoryList.length ? 1 : 0.5 }}
        >
          <ArrowButton onClick={null} />
        </div>
        <div
          className="header-events__next"
          ref={navigationNextRef}
          style={{ opacity: categoryList.length ? 1 : 0.5 }}
        >
          <ArrowButton left={false} onClick={null} />
        </div>
        <div className="header-slider">
          <Swiper
            modules={[Navigation]}
            autoHeight={false}
            slidesPerView={getSliderElementCount()}
            slidesPerGroup={getSliderElementCount()}
            spaceBetween={20}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
            {categoryList &&
              Boolean(categoryList.length) &&
              categoryList?.map((item) => (
                <SwiperSlide key={item.categoryUuid}>
                  <EventCategory
                    title={item.categoryName}
                    id={item.categoryUuid}
                    selected={selectedCategoryId === item.categoryUuid}
                    color={item.categoryColor}
                    onClick={() =>
                      selectCategory(
                        selectedCategoryId === item.categoryUuid
                          ? null
                          : item.categoryUuid
                      )
                    }
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div className="header-events__add">
          <AddButton
            onClick={() => {
              setIsCreateModal(true);
              setIsOpenModal(!isOpenModal);
            }}
          />
        </div>
      </div>
      <div className="header-tools">
        <GearButton
          onClick={() => {
            setIsCreateModal(false);
            setIsOpenModal(!isOpenModal);
          }}
        />
      </div>
      <ModalEvent
        isOpen={isOpenModal}
        itsCreate={isCreateModal}
        setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
        saveHandler={(data) => {
          if (isCreateModal) {
            createCategory(data);
          } else {
            updateCategory(data);
          }
        }}
      />
    </div>
  );
};

export default TopBar;
