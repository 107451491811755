// React && Styling
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./index.scss";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  signInAndCheckIf2faEnabledAction,
  selectAuthLoading,
  enable2FaOnSigninAction,
} from "../../store/slices/auth";

// Components
import Button from "../../components/Button";
import Input from "../../components/Input";
import ModalTwoFactorPin from "containers/ModalTwoFactorPin";
import ModalLoginTwoFa from "containers/ModalLoginTwoFa";

// Utils && Libs
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

const Login = () => {
  const { t } = useTranslation(["button", "common"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectAuthLoading);
  const [isTwoFactorModalOpen, setIsTwoFactorModalOpen] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [qrImg, setQrImg] = useState(null);
  const [secretCode, setSecretCode] = useState(null);
  const [twoFACode, setTwoFACode] = useState("");
  const [recaptchaScriptLoaded, setRecaptchaScriptLoaded] = useState(false);

  const { control, handleSubmit, formState, getValues } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      login: "",
      password: "",
    },
  });
  const { errors, isValid } = formState;

  const loginUser = async (formData) => {
    try {
      const token = await handleLoadedCaptcha();

      await dispatch(signInAndCheckIf2faEnabledAction({ formData, token })).unwrap();
      setIsPinModalOpen(!isPinModalOpen);
    } catch (error) {
      if (error.response?.data?.message === "Invalid recaptcha token") {
        NotificationManager.error("", "Suspicious activity detected. Please try again", 6000);
        return;
      }

      if (error.status === 400) {
        setQrImg(error.data.qrCodeImage);
        setSecretCode(error.data.secretKey);
        setIsTwoFactorModalOpen(!isTwoFactorModalOpen);
      }
    }
  };

  const twoFaBtnHandler = async () => {
    const credentials = { ...getValues(), code: twoFACode };

    try {
      await dispatch(enable2FaOnSigninAction(credentials)).unwrap();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const pinCodeBtnHandler = async (code) => {
    const credentials = { ...getValues(), code };

    try {
      await dispatch(login(credentials)).unwrap();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadedCaptcha = async () => {
    try {
      await new Promise((resolve) => {
        window.grecaptcha.ready(resolve);
      });

      const token = await window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {
        action: "login",
      });
      return token;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    setRecaptchaScriptLoaded(true);
  }, []);

  return (
    <>
      <div className="login">
        <form onSubmit={handleSubmit(loginUser)}>
          <Input
            placeholder={"Login"}
            control={control}
            name="login"
            id="login"
            bordered
            rules={{
              required: t("common:form.validation.required"),
            }}
            errors={errors.login}
            style={{
              height: "40px",
            }}
          />
          <Input
            placeholder={"Password"}
            control={control}
            name="password"
            id="password"
            bordered
            rules={{
              required: t("common:form.validation.required"),
            }}
            errors={errors.password}
            type="password"
            style={{
              height: "40px",
            }}
          />
          <Button disabled={!isValid || isLoading || !recaptchaScriptLoaded}>{t("button:enter")}</Button>
        </form>
      </div>

      <ModalLoginTwoFa
        qrImg={qrImg}
        secretCode={secretCode}
        twoFACode={twoFACode}
        setTwoFACode={setTwoFACode}
        isOpen={isTwoFactorModalOpen}
        setIsOpenModal={setIsTwoFactorModalOpen}
        successHandler={twoFaBtnHandler}
      />

      <ModalTwoFactorPin
        isOpen={isPinModalOpen}
        setIsOpenModal={setIsPinModalOpen}
        type
        cancelHandler={() => setIsPinModalOpen(false)}
        successHandler={pinCodeBtnHandler}
      />
    </>
  );
};

export default Login;
