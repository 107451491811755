import React, { useEffect, useState } from "react";
import cx from "classnames";

import styles from "./index.module.scss";
import CalendarInput from "components/DatePicker";
import ArrowUp from "assets/svg/ArrowUp";
import { useOuterClick } from "utils/useOuterClick";
import { parseDateToFormat } from "utils/date";

export const DatePickerDropdown = ({
  label,
  control,
  errors,
  name,
  rules,
  initValue,
  onChange = () => {},
  minDate
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [value, setValue] = useState(
    parseDateToFormat(initValue, "DD.MM.YYYY")
  );
  const [mounted, setMounted] = useState(false);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isMenuOpen) {
      setIsMenuOpen(false);
    }
  });

  useEffect(() => {
    if (isMenuOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isMenuOpen]);

  return (
    <div
      className={cx(styles["date-dropdown"], {
        [styles["open"]]: isMenuOpen,
      })}
    >
      <div className={styles["label-wrap"]}>
        {label && <span className={cx(styles["label"], {})}>{label}</span>}
      </div>
      <div className={styles["date-dropdown__wrap"]}>
        <div
          className={styles["date-dropdown__value"]}
          onClick={() => setIsMenuOpen(true)}
        >
          <span>{value}</span>
          <div
            style={{
              transform: !isMenuOpen ? "rotate(180deg)" : "rotate(0)",
            }}
          >
            <ArrowUp />
          </div>
        </div>
        {isMenuOpen && (
          <div className={styles["date-dropdown__calendar"]} ref={innerRef}>
            <CalendarInput
              control={control}
              name={name}
              placeholder={""}
              isPlaceholderWhite
              dateFormat={"dd.MM.yyyy, HH:mm, 'GMT' XXX"}
              customTimeInput
              open
              rules={rules}
              errors={errors}
              onFieldChange={(val) => {
                setValue(parseDateToFormat(val, "DD.MM.YYYY"));
              }}
              onChange={(val) => {
                onChange(val);
              }}
              minDate={minDate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePickerDropdown;
