import API from "../api";

export const getNotificationsListReq = (query) =>
  API.get("/admin/notification", {
    params: {
      ...query,
      page: query?.page ?? 0,
      size: query?.size ?? 9,
    },
  });

export const getNotificationReq = (id = "") =>
  API.get(`/admin/notification/${id}`);

export const createNotificationReq = (data) =>
  API.post("/admin/notification", data);

export const deleteNotificationReq = (id) =>
  API.delete(`/admin/notification/${id}`);

export const updateNotificationReq = (id, data) =>
  API.put(`/admin/notification/${id}`, data);

export const broadcastTgMsgMethod = (data) => {
  return API.post("/admin/notification/telegram", data);
};

export const filterTgMsgs = (body) => {
  return API.get("/admin/notification?status=TELEGRAM");
};
