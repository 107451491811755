import React, { useState, useEffect } from "react";
import cx from "classnames";
import ReactDOM from "react-dom";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import { useDispatch, useSelector } from "react-redux";
import { selectEventInfo } from "store/slices/event";
import { selectIsEventLoading } from "store/slices/event";
import { getEvent } from "store/slices/event";
import EventCard from "components/Cards/EventCard/EventCard";
import Loader from "components/Loader";
import { useNavigate } from "react-router";
import { setEventCategorySelected } from "store/slices/eventCategory";

import Close from "assets/svg/Close";
import styles from "./index.module.scss";

const ModalEventDetail = ({
  uuid,
  isOpen,
  setIsOpenModal = () => {},
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const eventData = useSelector(selectEventInfo);
  const isLoading = useSelector(selectIsEventLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  const getTitle = () => {
    return eventData?.status;
  };

  const redirect = async () => {
    successHandler();
    setIsOpenModal();
    await dispatch(setEventCategorySelected(eventData.categoryUuid));

    switch (eventData.status) {
      case "CREATED":
        navigate("/events/unpublished-event");
        return;

      case "PUBLISHED":
        navigate("/events/published");
        return;

      case "ACTIVE" || "PASSIVE":
        navigate("/events/active");
        return;

      case "FINISHED":
        navigate("/events/expirate-event");
        return;

      case "EXPIRED":
        navigate("/events/calculate-event");
        return;

      case "CLOSED":
        navigate("/events/finished-events");
        return;

      case "CANCELED":
        navigate("/events/deleted-events");
        return;

      default:
        return;
    }
  };

  const fetchEventData = () => {
    dispatch(
      getEvent({
        id: uuid,
      })
    );
  };

  useEffect(() => {
    if (isOpen) {
      fetchEventData();
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  useEffect(() => {
    console.log(eventData);
  }, [eventData]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen} background>
      <div ref={innerRef} className={cx(styles["modal"])}>
        <div className={styles["modal__header"]}>
          <div className={styles["modal__title"]}>{getTitle()}</div>
          <div className={styles["modal__close"]}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                cancelHandler();
                setIsOpenModal();
              }}
              type="clear"
            >
              <Close />
            </Button>
          </div>
        </div>

        {eventData && !isLoading && (
          <div className={styles["modal__body"]}>
            <EventCard item={eventData} isModalView />
          </div>
        )}

        {isLoading && <Loader />}

        <div className={styles["modal__btn"]}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              redirect();
            }}
          >
            Перейти до розділу
          </Button>
        </div>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default ModalEventDetail;
