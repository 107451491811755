import React from "react";

const NotepadImg = () => {
  return (
    <svg width="44" height="54" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.374 48.2191H2V14.2476L13.4258 2H37.9303V34.0667" stroke="#4DB275" strokeWidth="3" />
      <path d="M13.445 2L13.4258 14.2476H2" stroke="#4DB275" strokeWidth="3" />
      <path d="M6.92004 40.0287H18.3937" stroke="#4DB275" strokeWidth="3" />
      <path d="M6.92004 33.8096H21.2597" stroke="#4DB275" strokeWidth="3" />
      <path d="M6.92004 28H32.7237" stroke="#4DB275" strokeWidth="3" />
      <path d="M6.92004 22.1904H32.7237" stroke="#4DB275" strokeWidth="3" />
      <path
        d="M32.4371 52C37.7185 52 42 47.7318 42 42.4667C42 37.2016 37.7185 32.9333 32.4371 32.9333C27.1556 32.9333 22.8741 37.2016 22.8741 42.4667C22.8741 47.7318 27.1556 52 32.4371 52Z"
        stroke="#4DB275" strokeWidth="3" strokeLinecap="round" />
      <path d="M32.4371 37.2477V47.6858" stroke="#4DB275" strokeWidth="3" />
      <path d="M27.2114 42.4667H37.6724" stroke="#4DB275" strokeWidth="3" />
    </svg>
  );
};

export default NotepadImg;
