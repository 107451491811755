import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.scss";
import { getEventReport, selectEventData, selectEventIsLoading } from "store/slices/eventStat";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import CustomAccordion from "components/CustomAccordion/CustomAccordion";
import PaginationComponent from "components/PaginationComponent";
import moment from 'moment';

const StatByEvent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector(selectEventIsLoading);
    const listData = useSelector(selectEventData);
    const isError = useSelector((state) => state.isError);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 50;
    const totalPages = listData?.totalPages || 0;

    useEffect(() => {
        const size = 50;
        if (currentPage >= 0) {
            dispatch(getEventReport({ page: currentPage, size }));
        } else {
            dispatch(getEventReport(currentPage, itemsPerPage));
        }
        document.title = t("common:statByEvent.title");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const handlePaginationClick = (pageNum) => {
        // Устанавливаем текущую страницу и делаем новый запрос с новым номером страницы
        setCurrentPage(pageNum - 1);
        dispatch(getEventReport({ page: pageNum - 1, size: itemsPerPage }));
    };

    if (isError) {
        return <div>Сталася помилка під час завантаження даних</div>;
    }

    return (
        <div className={styles.statByEvent}>
            <h1 className={styles.pageTitle}>{t("statByEvent.title")}</h1>
            <div className={styles.accordionWrapper}>
                {listData?.content?.map((event) => (
                    <CustomAccordion key={event.uuid} title={`Назва події: ${event.title}.  Дата події: ${moment(event.createdTime).format('YYYY-MM-DD HH:mm:ss')}`}>
                        <div className={styles.tableContainer}>
                            <h3>Так X {event.bidUpCoefficient}</h3>
                            <table className={styles.table}>
                                <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Ставка</th>
                                    <th>Сума виграшу</th>
                                    <th>Баланс перед розрахунком</th>
                                    <th>Баланс після розрахунку</th>
                                </tr>
                                </thead>
                                <tbody>
                                {event.bidUpList.map((bid) => (
                                    <tr key={bid.id}>
                                        <td>{bid.username}</td>
                                        <td>{bid.bidAmount}</td>
                                        <td>{bid.clearGainAmount}</td>
                                        <td>{bid.balanceBeforeCalculation}</td>
                                        <td>{bid.balanceAfterCalculation}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            <h3>Ні X {event.bidDownCoefficient}</h3>
                            <table className={styles.table}>
                                <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Ставка</th>
                                    <th>Сума виграшу</th>
                                    <th>Баланс перед розрахунком</th>
                                    <th>Баланс після розрахунку</th>
                                </tr>
                                </thead>
                                <tbody>
                                {event.bidDownList.map((bid) => (
                                    <tr key={bid.id}>
                                        <td>{bid.username}</td>
                                        <td>{bid.bidAmount}</td>
                                        <td>{bid.clearGainAmount}</td>
                                        <td>{bid.balanceBeforeCalculation}</td>
                                        <td>{bid.balanceAfterCalculation}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </CustomAccordion>
                ))}
            </div>

            <div className={styles.pagination}>
                <PaginationComponent
                    isAdminTbl
                    itemsPerPage={itemsPerPage}
                    totalPages={totalPages}
                    forcePage={currentPage}
                    onChanePage={handlePaginationClick}
                />
            </div>

            {isLoading && <Loader />}
        </div>
    );
};

export default StatByEvent;
