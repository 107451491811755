import Question from "assets/svg/Question";
import GreenCheckSquare from "assets/svg/GreenCheckSquare";
import GreenCheckMark from "assets/svg/GreenCheckMark";
import Stop from "assets/svg/Stop";
import RedTriangle from "../../assets/svg/RedTriangle";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import ReactDOM from "react-dom";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import { fixNestedModalsCloseTrigger } from "utils/fixNestedModalsCloseTrigger";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

export const ErrorText = ({ children, isUpperCase = true }) => {
  return (
    <span
      className={styles["modal-warning__error-text"]}
      style={{ textTransform: isUpperCase ? "uppercase" : "unset" }}
    >
      {children}
    </span>
  );
};

export const SuccessText = ({ children, isUpperCase = false }) => {
  return (
    <span
      className={styles["modal-warning__success-text"]}
      style={{ textTransform: isUpperCase ? "uppercase" : "unset" }}
    >
      {children}
    </span>
  );
};

const ModalWarning = ({
  title,
  text,
  isOpen,
  setIsOpenModal,
  hideCancelBtn,
  type,
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button"]);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  const generateModalIcon = (type) => {
    switch (type) {
      case "isQuestion":
        return <Question />;

      case "isTwoFactor":
        return <GreenCheckSquare />;

      case "addWalletAddress":
        return <RedTriangle />;

      case "addWalletAddressSuccess":
        return <GreenCheckMark />;

      default:
        return <Stop />;
    }
  };

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div
        ref={innerRef}
        className={cx(styles["modal-warning"], {
          [styles[type]]: type,
        })}
        onClick={fixNestedModalsCloseTrigger}
      >
        <div className={styles["modal-warning__stop"]}>{generateModalIcon(type)}</div>

        <div className={styles["modal-warning__title"]}>{title}</div>
        {(type === "isTwoFactor" || type === "addWalletAddress" || type === "addWalletAddressSuccess") && (
          <div className={styles["modal-warning__text"]}>{text}</div>
        )}

        <div className={styles["modal-warning__btns"]}>
          <Button
            autoFocus
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              successHandler();
              setIsOpenModal();
            }}
            styleBtn={{ width: type === "isTwoFactor" ? "198px" : null }}
          >
            {!hideCancelBtn
              ? t("yes")
              : hideCancelBtn && type === "isTwoFactor"
                ? t("good")
                : hideCancelBtn && type === "addWalletAddressSuccess"
                  ? t("continue")
                  : t("itsClear")}
          </Button>
          {!hideCancelBtn && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                cancelHandler();
                setIsOpenModal();
              }}
            >
              {t("no")}
            </Button>
          )}
        </div>
      </div>
    </Modal>,
    document.getElementById("root"),
  );
};

export default ModalWarning;
