import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import ModalWarning from "../ModalWarning";
import styles from "./index.module.scss";
import Button from "components/Button";
import X from "assets/svg/X";
import Expand from "assets/svg/Expand";
import Download from "assets/svg/Download";
import { Trans } from "react-i18next";
import classNames from "classnames/bind";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import ModalRangeDatePicker from "containers/ModalRangeDatePicker";
import { formatDateToCustomFormat } from "utils/date";
import Calendar from "../../assets/controls/Calendar";
import { getUserActions } from "store/slices/users";

const cx = classNames.bind(styles);

const ModalUserActions = ({
  isOpen,
  setIsOpenModal,
  downloadUserActions,
  data,
  userId,
  title,
  startDate,
  endDate,
  onDateChange,
  resetDates,
  cancelHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const dispatch = useDispatch();

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        // ADD GET INFO BY data.id LOGIC
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
        <div
          ref={innerRef}
          className={styles["modal"]}
          style={{
            pointerEvents: "auto",
          }}
        >
          <div className={styles["modal__header"]}>
            <div className={styles["modal__title"]}>{title}</div>
            <div className={styles["modal__subtitle"]}>
              <div className={styles["modal__btn"]}>
                <button
                  className={styles["modal__date-btn"]}
                  onClick={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
                >
                  <Calendar />
                  {formatDateToCustomFormat(startDate)} - {formatDateToCustomFormat(endDate)}
                </button>
              </div>
              <div className={styles["modal__action-bar"]}>
                <div className={styles["modal__btn"]}>
                  <Button
                    type="clear"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDownloadModalOpen(!isDownloadModalOpen);
                    }}
                  >
                    <Download />
                  </Button>
                </div>

                <div className={styles["modal__btn"]}>
                  <Button
                    type="clear"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded(!isExpanded);
                    }}
                  >
                    <Expand />
                  </Button>
                </div>
              </div>

              <div className={styles["modal__close-btn"]}>
                <Button
                  type="clear"
                  onClick={(e) => {
                    e.stopPropagation();
                    cancelHandler();
                    setIsOpenModal();
                  }}
                >
                  <X />
                </Button>
              </div>
            </div>
          </div>

          <div
            className={cx(styles["modal__table"], {
              [styles["expanded-table"]]: isExpanded,
            })}
          >
            <TableComponent type={TABLE_VIEW.USER_ACTIONS} data={data} />
          </div>
        </div>
      </Modal>

      <ModalWarning
        isOpen={isDownloadModalOpen}
        setIsOpenModal={() => setIsDownloadModalOpen(!isDownloadModalOpen)}
        successHandler={() => downloadUserActions(startDate, endDate)}
        cancelHandler={() => setIsDownloadModalOpen(!isDownloadModalOpen)}
        title={<Trans i18nKey="modals:blockUser">Завантажити дані за обраний період?</Trans>}
      />

      <ModalRangeDatePicker
        isOpen={isCalendarModalOpen}
        setIsOpenModal={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
        isUserHours
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
        resetDates={resetDates}
        successHandler={async () => {
          const id = userId;
          await dispatch(getUserActions({ id, startDate, endDate }));

          setIsCalendarModalOpen(!isCalendarModalOpen);
        }}
        cancelHandler={() => setIsCalendarModalOpen(!isCalendarModalOpen)}
        title={<Trans i18nKey="modals:chooseTime">Оберіть проміжок часу</Trans>}
      />
    </>,
    document.getElementById("root"),
  );
};

export default ModalUserActions;
