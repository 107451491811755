import React, { useState, useEffect } from "react";
import ModalArrowBack from "assets/svg/ModalArrowBack";
import cx from "classnames";
import ReactDOM from "react-dom";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import Input from "components/Input";
import { useOuterClick } from "../../utils/useOuterClick";
import { fixNestedModalsCloseTrigger } from "utils/fixNestedModalsCloseTrigger";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const ModalTransfer = ({
  isOpen,
  setIsOpenModal,
  isQuestion,
  isTwoFactor,
  type,
  successHandler = () => {},
  cancelHandler = () => {},
  childContractData,
  childTransferData,
  setChildTransferData,
  childTransferDataError,
  mainContractAddress,
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button"]);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      type === "MAIN" &&
        setChildTransferData((prev) => {
          return { ...prev, address: mainContractAddress };
        });
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  const numberValidateHandle = (e) => {
    const inputValue = e.target.value;
    const sanitizedInput = inputValue.replace(/[^0-9.]/g, "");

    if (/^\d*\.?\d*$/.test(sanitizedInput) && sanitizedInput.indexOf(".") !== 0) {
      setChildTransferData((prev) => ({ ...prev, sum: sanitizedInput }));
    }
  };

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div
        ref={innerRef}
        className={cx(styles["modal-transfer"], {
          [styles["is-question"]]: isQuestion,
          [styles["is-twofactor"]]: isTwoFactor,
        })}
        onClick={fixNestedModalsCloseTrigger}
      >
        <div className={styles["modal-transfer__header-wrapper"]}>
          <div
            className={styles["modal-transfer__arrow-wrapper"]}
            style={{ cursor: "pointer" }}
            onClick={cancelHandler}
          >
            <ModalArrowBack />
          </div>
          <h2 className={styles["modal-transfer__title"]}>
            {type === "MAIN" ? "Виведення на ОСНОВНУ адресу" : "Виведення на СТОРОННЮ адресу"}
          </h2>
        </div>
        <div className={styles["modal-transfer__input-container"]}>
          <div className={styles["modal-transfer__input-wrapper"]}>
            {childTransferDataError.addressError && <span>Не вірна адреса, вам потрібна адреса в мережі TRC-20</span>}
            <Input
              disabled={type === "MAIN"}
              placeHolder="Введіть адресу"
              size="transferBig"
              value={childTransferData?.address}
              onChange={(e) =>
                setChildTransferData((prev) => {
                  return { ...prev, address: e.target.value };
                })
              }
            />
          </div>
          <div className={styles["modal-transfer__input-wrapper"]}>
            {childTransferDataError.sumError && <span>Недостатня сума на рахунку</span>}
            <Input
              placeHolder="Введіть суму"
              value={childTransferData?.sum}
              onChange={numberValidateHandle}
              style={{ width: "210px" }}
            />
          </div>
        </div>

        <div className={styles["modal-transfer__btns"]}>
          <Button
            disabled={!childContractData?.address || !childTransferData?.sum}
            styleBtn={{ width: "397px" }}
            type="submit"
            onClick={(e) => {
              fixNestedModalsCloseTrigger(e);
              successHandler();
            }}
          >
            {t("confirm")}
          </Button>
        </div>
      </div>
    </Modal>,
    document.getElementById("root"),
  );
};

export default ModalTransfer;
