import ImageList from "containers/ImageList";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";

import styles from "./index.module.scss";

const ImageListModal = ({
  isOpen,
  setIsOpenModal,
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);

  // TODO: not used because internal modals conflict
  // const innerRef = useOuterClick((ev) => {
  //   if (mounted && isOpen) {
  //     setIsOpenModal();
  //   }
  // });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div className={styles["modal-image"]}>
        <ImageList
          selectImageHandler={(image) => {
            successHandler(image);
            setIsOpenModal(false);
          }}
          closeBtn
          closeHandler={() => {
            cancelHandler();
            setIsOpenModal(false);
          }}
        />
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default ImageListModal;
