import React from "react";
import { CATEGORY_DEFAULT_COLOR } from "../../constants/colors";
import cx from "classnames";

import styles from "./index.module.scss";

const EventCategory = ({ title, color, onClick, selected, key }) => (
  <>
    <div
      className={cx(styles["event-category"], {
        [styles.selected]: selected,
      })}
      style={{
        "--event-item-color": color || CATEGORY_DEFAULT_COLOR,
      }}
      onClick={onClick}
      key={key}
    >
      <span>{title}</span>
    </div>
  </>
);

export default EventCategory;
