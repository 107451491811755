import React, { useState, useEffect } from "react";

import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import dog3 from "../../assets/events/dog-3.png";

import styles from "./index.module.scss";
import { useNavigate } from "react-router";

const ModaLogOut = ({
  isOpen,
  setIsOpenModal,
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button", "modals"]);
  const navigate = useNavigate()

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div ref={innerRef} className={styles["modal"]}>
        <div className={styles["modal__text"]}>{t("modals:logout.title")}</div>
        <div className={styles["modal__img"]}>
          <img className="icon2" src={dog3} alt="choose-icon-2" />
        </div>
        <div className={styles["modal__btns"]}>
          <Button
            onClick={() => {
              successHandler(navigate);
              setIsOpenModal();
            }}
          >
            {t("button:yes")}
          </Button>
          <Button
            onClick={() => {
              cancelHandler();
              setIsOpenModal();
            }}
          >
            {t("button:no")}
          </Button>
        </div>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default ModaLogOut;
