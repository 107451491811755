import React from "react";
import { formatDateForProfitTable } from "utils/date";

const ProfitCol = ({ data, headers }) => {
  return headers?.map((header, index) => {
    return (
      <td key={header.key}>
        {header.key === "status" ? (
          <>
            {data[header.key] === "CANCELED" ? (
              <span style={{ color: "#EB4755" }}>Скасована</span>
            ) : (
              <span style={{ color: "#4DB275" }}>Розрахована</span>
            )}
          </>
        ) : header.key === "finishTime" ? (
          <span>{formatDateForProfitTable(data.finishTime)}</span>
        ) : (
          <span>{data[header.key]}</span>
        )}
      </td>
    );
  });
};

export default ProfitCol;
