import API from "../api";

export const getQRCode = () => API.get(`/api/code/generate`);

export const enable2FA = (code) => API.put(`/api/code/on`, { code });

export const disable2Fa = (code) => API.put(`/api/code/off`, { code });

export const validate2FA = (code) => API.post(`/api/code/validate`, { code });

export const checkIf2FAEnabledMethod = () => API.get(`/admin/auth/signin/on/fa`);
