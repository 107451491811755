import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.scss";

// Components and Containers
import Loader from "components/Loader";
import Button from "components/Button";
import AddButton from "components/AddButton";
import TableComponent from "components/TableComponent";
import ModalProfitTable from "containers/ModalProfitTable";
import ModalTwoFactor from "containers/ModalTwoFactor";
import ModalWarning from "containers/ModalWarning";
import ModalTransfer from "containers/ModalTransfer";
import ModalTwoFactorPin from "containers/ModalTwoFactorPin";
import ModalTransferSummary from "containers/ModalTransferSummary";
import ModalMainWalletHistoryTable from "containers/ModalMainWalletHistoryTable";
import ModalChildWalletHistoryTable from "containers/ModalChildWalletHistoryTable";
import WithdrawFactoriWallet from "components/withdrawFactoriWallet";

// Icons
import GoogleIcon from "../../assets/controls/google";

// Utils & Libs
import { toasterCreator } from "utils/toasterCreator";
import { NotificationManager } from "react-notifications";
import { calculateDateOneWeekEarlier } from "utils/date";

// Redux & API
import {
  selectIsLoading,
  getUserCryptoInfoAction,
  getPeriodProfitAction,
  getChildWalletsAction,
  getMainWalletsAction,
  selectInfo,
  selectPeriodProfit,
  selectChildWallets,
  selectMainWallets,
  disableTwoFaAction,
  validateTwoFaAction,
  enableTwoFaAction,
  getTransferFeeAction,
  selectFee,
  clearFeeAction,
} from "store/slices/crypto";
import { withdrawFromParentContractMethod } from "api/withdrawal";
import { addNewChildWallet, withdrawalChildContract } from "api/crypto";

const CryptoPage = () => {
  const dispatch = useDispatch();

  // Info Modals
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [isAddAddressSuccessModalOpen, setIsAddAddressSuccessModalOpen] = useState(false);

  // 2FA Modals states
  const [isTwoFactorModalOpen, setIsTwoFactorModalOpen] = useState(false);
  const [isTwoFactorEnabledOpen, setIsTwoFactorEnabledOpen] = useState(false);
  const [isTwoFactorDisabledOpen, setIsTwoFactorDisabledOpen] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  // Data Tables Modals states
  const [isProfitTblModalOpen, setIsProfitTblModalOpen] = useState(false);
  const [isMainWalletHistoyModalOpen, setIsMainWalletHistoyModalOpen] = useState(false);
  const [isChildWalletHistoyModalOpen, setIsChildWalletHistoyModalOpen] = useState(false);

  // Transfer Modals states
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isTransferSummaryModalOpen, setIsTransferSummaryModalOpen] = useState(false);

  // Other States
  const [type, setType] = useState("");
  const [isTransactionSuccesfull, setIsTransactionSuccesfull] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Child transfer states
  const [childContractData, setChildContractData] = useState(null);
  const [transferData, setTransferData] = useState({
    address: "",
    sum: "",
    supportCode: "",
  });
  const [childTransferDataError, setChildTransferDataError] = useState({ addressError: false, sumError: false });

  // Redux Selectors
  const isLoading = useSelector(selectIsLoading);
  const cryptoPageInfo = useSelector(selectInfo) || {};
  const periodProfitTblData = useSelector(selectPeriodProfit) || {};
  const childWalletsTblData = useSelector(selectChildWallets) || {};
  const mainWalletsTblData = useSelector(selectMainWallets) || {};
  const fee = useSelector(selectFee) || 0;

  const {
    totalUsersBalance,
    twoFactorAuthenticationEnabled,
    mainContractAddress,
    mainContractUSDTBalance,
    nodeContractAddress,
    nodeContractTRXBalance,
    totalBalance,
    totalChildContractsBalance,
    allChildContracts,
  } = cryptoPageInfo;

  const { profitEvents, totalProfit } = periodProfitTblData;

  const { content: childWalletsArray } = childWalletsTblData;

  const { content: mainWalletsArray } = mainWalletsTblData;

  useEffect(() => {
    dispatch(getUserCryptoInfoAction());
  }, [dispatch]);

  const mutateDataForMainTable = (childContractsArray) => {
    return {
      header: [
        {
          key: "number",
          label: "Номер",
        },

        {
          key: "address",
          label: "Адреси",
        },

        {
          key: "balance",
          label: "Сума USDT",
        },

        {
          key: "external",
          label: "Вивід на",
        },

        {
          key: "primary",
          label: "Вивід на",
        },
      ],

      data: childContractsArray?.map((contract, index) => {
        return {
          number: index + 1,
          address: contract.address,
          balance: contract.balance,
          external: null,
          primary: null,
        };
      }),
    };
  };

  // Reset & Cancel Handlers

  const closeTransferModals = () => {
    setIsTransferModalOpen(false);
    setIsTransferSummaryModalOpen(false);
  };

  const resetTransferData = () => {
    if (type === "USDT") {
      setType("");
    }
    setTransferData({ address: "", sum: "", supportCode: "" });
    setChildTransferDataError({ addressError: false, sumError: false });
    dispatch(clearFeeAction());
  };

  const cancelTransferHandler = () => {
    resetTransferData();
    closeTransferModals();
  };

  const transferSummaryBackBtnHandler = () => {
    if (type === "USDT") {
      return cancelTransferHandler();
    }
    dispatch(clearFeeAction());
    setIsTransferModalOpen(true);
    setIsTransferSummaryModalOpen(false);
    setIsTransactionSuccesfull(false);
  };

  const setDatePickerDefaultDates = () => {
    const { todaysDate, sevenDaysAgoDate } = calculateDateOneWeekEarlier();
    setStartDate(sevenDaysAgoDate);
    setEndDate(todaysDate);
  };

  const addWalletBtnHandler = async () => {
    try {
      await addNewChildWallet();
      setIsAddAddressSuccessModalOpen(!isAddAddressSuccessModalOpen);
    } catch (error) {
      toasterCreator.errorsToaster.error(error.message);
    }
  };

  const handle2FAHeaderBtn = () => {
    setType("");

    if (twoFactorAuthenticationEnabled) {
      return setIsPinModalOpen(true);
    }
    setIsTwoFactorModalOpen(true);
  };

  const handle2FACode = async (code) => {
    dispatch(validateTwoFaAction({ code })).then(async ({ payload }) => {
      if (!payload) {
        return NotificationManager.error("", "Не вірний 2FA перевірочний код", 3000);
      }

      if (!type) {
        await dispatch(
          disableTwoFaAction({
            code,
            action: () => {
              setIsPinModalOpen(false);
              setIsTwoFactorDisabledOpen(true);
            },
          }),
        );
        await dispatch(getUserCryptoInfoAction());
        return;
      }

      if (type === "MAIN" || type === "OTHER") {
        await withdrawalChildContract({
          to: transferData.address,
          from: childContractData.address,
          amount: Number(transferData.sum),
          validateCodeDto: { code },
        })
          .then(({ data }) => {
            setTransferData((prevState) => {
              return { ...prevState, supportCode: data?.supportCode ?? "" };
            });
            setIsPinModalOpen(false);
            setIsTransactionSuccesfull(true);
            setIsTransferSummaryModalOpen(true);
          })
          .catch(() => NotificationManager.error("", "Something went wrong, please try again!", 3000));

        return;
      }

      /// withdraw func from factory sc
      await withdrawFromParentContractMethod({
        to: transferData.address,
        from: mainContractAddress,
        amount: Number(transferData.sum),
        validateCodeDto: { code },
      })
        .then(({ data }) => {
          setTransferData((prevState) => {
            return { ...prevState, supportCode: data?.supportCode ?? "" };
          });
          setIsPinModalOpen(false);
          setIsTransactionSuccesfull(true);
          setIsTransferSummaryModalOpen(true);
        })
        .catch(() => NotificationManager.error("", "Something went wrong, please try again!", 3000));
    });
  };

  const onEnable2FA = async (code) => {
    await dispatch(
      enableTwoFaAction({
        code,
        action: async () => {
          if (!type) {
            setIsTwoFactorModalOpen(false);
            setIsTwoFactorEnabledOpen(true);
            await dispatch(getUserCryptoInfoAction());
            return;
          }
          if (type === "MAIN" || type === "OTHER") {
            await withdrawalChildContract({
              to: transferData.address,
              from: childContractData.address,
              amount: Number(transferData.sum),
              validateCodeDto: { code },
            })
              .then(({ data }) => {
                setTransferData((prevState) => {
                  return { ...prevState, supportCode: data?.supportCode ?? "" };
                });
                setIsTwoFactorModalOpen(false);
                setIsTransactionSuccesfull(true);
                setIsTransferSummaryModalOpen(true);
              })
              .catch(() => NotificationManager.error("", "Something went wrong, please try again!", 3000));

            return;
          }

          /// withdraw func from factory sc
          await withdrawFromParentContractMethod({
            to: transferData.address,
            from: mainContractAddress,
            amount: Number(transferData.sum),
            validateCodeDto: { code },
          })
            .then(({ data }) => {
              setTransferData((prevState) => {
                return { ...prevState, supportCode: data?.supportCode ?? "" };
              });
              setIsTwoFactorModalOpen(false);
              setIsTransactionSuccesfull(true);
              setIsTransferSummaryModalOpen(true);
            })
            .catch(() => NotificationManager.error("", "Something went wrong, please try again!", 3000));
        },
      }),
    );
  };

  const onStartChildTransfer = () => {
    resetTransferData();
    setIsTransactionSuccesfull(false);
    setIsTransferModalOpen(true);
  };

  const onSubmitDataChildTransfer = async () => {
    if (childContractData.balance >= transferData.sum && transferData.address[0] === "T") {
      const getFeeApiMethodPayload = {
        to: transferData.address,
        from: childContractData?.address,
        amount: +transferData.sum,
      };

      await dispatch(getTransferFeeAction(getFeeApiMethodPayload)).unwrap();

      setIsTransferModalOpen(false);
      setIsTransferSummaryModalOpen(true);
    }

    setChildTransferDataError((prevState) => {
      return { ...prevState, sumError: childContractData.balance < transferData.sum };
    });

    setChildTransferDataError((prevState) => {
      return { ...prevState, addressError: transferData.address[0] !== "T" };
    });
  };

  const onSubmitChildTransferSummary = () => {
    setIsTransferSummaryModalOpen(false);

    if (isTransactionSuccesfull) {
      dispatch(getUserCryptoInfoAction());
      dispatch(clearFeeAction());
      setTransferData({ address: "", sum: "", supportCode: "" });
      setType("");
      setIsTransactionSuccesfull(false);
      return;
    }

    if (twoFactorAuthenticationEnabled) {
      return setIsPinModalOpen(true);
    }
    setIsTwoFactorModalOpen(true);
  };

  useEffect(() => {
    setDatePickerDefaultDates();
  }, []);

  return (
    <>
      <div className={styles.crypto}>
        <div className={styles["crypto__header"]}>
          <h1 className={styles["crypto__header--title"]}>Крипто процесінг</h1>

          <div className={styles["crypto__header--balance-wrapper"]}>
            <div className={styles["crypto__header--balance"]}>
              <span>Загальний баланс користувачів</span>
              <span>{totalUsersBalance}</span>
            </div>
            <Button
              width={147}
              height={72}
              styleBtn={{
                padding: "12px 24px",
                fontSize: "20px",
                lineHeight: "normal",
              }}
              onClick={() => {
                dispatch(getPeriodProfitAction({ query: { size: 8 } }));
                setIsProfitTblModalOpen(!isProfitTblModalOpen);
              }}
            >
              Прибуток за період
            </Button>
          </div>

          <div className={styles["crypto__header--google"]}>
            <div>
              <GoogleIcon />
            </div>
            <span>Аутентифікація через Google Authenticator</span>
            <Button
              disabled={isLoading}
              width={141}
              height={43}
              styleBtn={{
                padding: "12px 24px",
                fontSize: "16px",
                lineHeight: "normal",
                fontWeight: "400",
              }}
              onClick={handle2FAHeaderBtn}
            >
              {twoFactorAuthenticationEnabled ? "Відключити" : "Підключити"}
            </Button>
          </div>
        </div>

        <div className={styles["crypto__body"]}>
          <div className={styles["crypto__wallet"]}>
            <div className={styles["wallet__container"]}>
              <WithdrawFactoriWallet
                onSubmitActions={{ setIsTransferSummaryModalOpen, setType, setTransferData }}
                data={{ mainContractAddress, mainContractUSDTBalance, nodeContractAddress, nodeContractTRXBalance }}
              />
            </div>
          </div>

          <div className={styles["crypto__table"]}>
            <div className={styles["table__info-container"]}>
              <div className={styles["table__info"]}>
                <span>Загальний баланс</span>
                <span>{totalBalance}</span>
              </div>
              <div className={styles["table__info"]}>
                <span>Дочірні гаманці</span>
                <span>{totalChildContractsBalance}</span>
              </div>
              <Button
                width={316}
                height={72}
                styleBtn={{
                  padding: "12px 24px",
                  fontSize: "20px",
                  lineHeight: "normal",
                }}
                onClick={() => {
                  dispatch(getMainWalletsAction({ query: { size: 8 }, startDate, endDate, type: "all" }));
                  setIsMainWalletHistoyModalOpen(!isMainWalletHistoyModalOpen);
                }}
              >
                Історія основного гаманця
              </Button>
              <Button
                width={316}
                height={72}
                styleBtn={{
                  padding: "12px 24px",
                  fontSize: "20px",
                  lineHeight: "normal",
                }}
                onClick={() => {
                  dispatch(getChildWalletsAction({ query: { size: 8 }, startDate, endDate, type: "all" }));
                  setIsChildWalletHistoyModalOpen(!isChildWalletHistoyModalOpen);
                }}
              >
                Історія дочірніх гаманців
              </Button>
              <div className={styles["table__addBtn"]}>
                <AddButton
                  border
                  onClick={() => {
                    setIsAddAddressModalOpen(!isAddAddressModalOpen);
                  }}
                />
              </div>
            </div>

            <div className={styles["table__wrapper"]}>
              <TableComponent
                type="crypto"
                data={mutateDataForMainTable(allChildContracts)}
                setType={setType}
                setChildContractData={setChildContractData}
                onStartChildTransfer={onStartChildTransfer}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalProfitTable
        isOpen={isProfitTblModalOpen}
        setIsOpenModal={setIsProfitTblModalOpen}
        data={profitEvents}
        totalProfit={totalProfit}
      />

      <ModalMainWalletHistoryTable
        isOpen={isMainWalletHistoyModalOpen}
        setIsOpenModal={setIsMainWalletHistoyModalOpen}
        data={mainWalletsArray}
        totalPages={mainWalletsTblData?.totalPages}
      />

      <ModalChildWalletHistoryTable
        isOpen={isChildWalletHistoyModalOpen}
        setIsOpenModal={setIsChildWalletHistoyModalOpen}
        data={childWalletsArray}
        totalPages={childWalletsTblData?.totalPages}
      />

      <ModalTwoFactor
        isOpen={isTwoFactorModalOpen}
        setIsOpenModal={setIsTwoFactorModalOpen}
        successHandler={onEnable2FA}
      />

      <ModalTwoFactorPin
        isOpen={isPinModalOpen}
        setIsOpenModal={setIsPinModalOpen}
        type={type}
        cancelHandler={() => setIsPinModalOpen(false)}
        successHandler={handle2FACode}
      />

      <ModalWarning
        type="isTwoFactor"
        isOpen={isTwoFactorEnabledOpen}
        setIsOpenModal={setIsTwoFactorEnabledOpen}
        hideCancelBtn
        title="Двофакторна автентифікація включена"
        text="Ми наполегливо рекомендуємо не відключати цю
        функцію, оскільки це знизить безпеку вашого облікового
        запису."
      />

      <ModalWarning
        type="isTwoFactor"
        isOpen={isTwoFactorDisabledOpen}
        setIsOpenModal={setIsTwoFactorDisabledOpen}
        hideCancelBtn
        title="Двофакторна автентифікація вимкнена"
        text="Ми наполегливо рекомендуємо увімкнути цю функцію,
        оскільки це підвищить рівень безпеки вашого облікового запису."
      />

      <ModalWarning
        type="addWalletAddress"
        isOpen={isAddAddressModalOpen}
        setIsOpenModal={setIsAddAddressModalOpen}
        title="Ви бажаєте створити ще одну адресу?"
        text="Адресу видалити не можливо зважте ще раз чи вам це потрібно."
        successHandler={addWalletBtnHandler}
      />

      <ModalWarning
        type="addWalletAddressSuccess"
        isOpen={isAddAddressSuccessModalOpen}
        setIsOpenModal={setIsAddAddressSuccessModalOpen}
        title="Створення гаманця відправлено в обробку!"
        text="Додавання гаманця займе до 3-х хв, потім просто перезавантажте сторінку"
        hideCancelBtn
      />

      <ModalTransfer
        type={type}
        isOpen={isTransferModalOpen}
        setIsOpenModal={setIsTransferModalOpen}
        childContractData={childContractData}
        setChildTransferData={setTransferData}
        childTransferData={transferData}
        childTransferDataError={childTransferDataError}
        successHandler={onSubmitDataChildTransfer}
        cancelHandler={cancelTransferHandler}
        mainContractAddress={mainContractAddress}
      />

      <ModalTransferSummary
        isOpen={isTransferSummaryModalOpen}
        setIsOpenModal={setIsTransferSummaryModalOpen}
        type={type}
        isTransactionSuccesfull={isTransactionSuccesfull}
        transferData={transferData}
        fee={fee}
        backBtnHandler={transferSummaryBackBtnHandler}
        cancelHandler={cancelTransferHandler}
        successHandler={onSubmitChildTransferSummary}
      />

      {isLoading && <Loader />}
    </>
  );
};

export default CryptoPage;
