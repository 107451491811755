export const commissionAndBonusSettings = [
  {
    title: "Комісія користувачів USDT",
    name: "commissionUsdt",
    setting: "COMMISSION_USDT",
  },

  {
    title: "Комісія користувачів UAH вивід коштів",
    name: "commissionUahWithdrawal",
    setting: "COMMISSION_UAH_WITHDRAWAL",
  },

  {
    title: "Бонус при реестрації",
    name: "registrationBonus",
    setting: "REGISTRATION_BONUS",
  },
];

export const withdrawAndDepositToggleSettings = [
  {
    text: "USDT:",
    value: "CryptoUSDT",
  },

  {
    text: "P2P Ukraine:",
    value: "P2p",
  },

  {
    text: "Обмінник:",
    value: "Exchanger",
  },

  {
    text: "Visa/MC EUR:",
    value: "VisaEur",
  },
];
