import React, { useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";
import cx from "classnames";

import DatePickerComponent, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styles from "./index.module.scss";
import CustomTimeInput from "./CustomTimeInput";

const MyContainer = (setIsOpened) => {
  const CustomContainer = ({ children, className }) => {
    useEffect(() => {
      setIsOpened(true);
      return () => {
        setIsOpened(false);
      };
    }, []);
    return <CalendarContainer className={className}>{children}</CalendarContainer>;
  };

  return CustomContainer;
};

const MyHeader = ({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
  <div>
    <button
      className={"react-datepicker__navigation react-datepicker__navigation--previous"}
      style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
      onClick={(event) => {
        event.preventDefault();
        decreaseMonth();
      }}
    >
      <span className={"react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"}>{"<"}</span>
    </button>
    <span className="react-datepicker__current-month">
      {monthDate.toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      })}
    </span>
    <button
      className={"react-datepicker__navigation react-datepicker__navigation--next"}
      style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
      onClick={(event) => {
        event.preventDefault();
        increaseMonth();
      }}
    >
      <span className={"react-datepicker__navigation-icon react-datepicker__navigation-icon--next"}>{">"}</span>
    </button>
  </div>
);

const DatePickerContorol = (props) => {
  const [isFocus, setIsFocus] = useState(false);
  const ref = useRef();

  const {
    label,
    placeholder,
    showTimeInput = true,
    dateFormat,
    disabled,
    onChange,
    onFieldChange,
    selected,
    onFocus,
    onBlur,
    errors,
    rules,
    open,
    minDate,
    isPlaceholderWhite,
    type,
    customTimeInput,
    isUserHours,
    isPopper,
    startDate,
    endDate,
  } = props;

  const { field } = useController(props);

  useEffect(() => {
    if (onFieldChange) {
      onFieldChange(field.value);
    }
  }, [field.value]);

  return (
    <>
      <div
        className={cx(styles["input"], styles["date-input"], {
          [styles["placeholder-white"]]: isPlaceholderWhite,
          [styles["is-empty"]]: !field.value,
          [styles[`${type}`]]: type,
        })}
      >
        <DatePickerComponent
          {...field}
          {...props}
          onChange={(dates) => {
            if (onChange) {
              !isUserHours && field.onChange(dates);
              onChange(dates);
            } else {
              field.onChange(dates);
            }
          }}
          ref={ref}
          open={open}
          selected={field.value || selected}
          disabled={disabled}
          dateFormat={dateFormat}
          showTimeInput={!isUserHours && showTimeInput}
          placeholderText={placeholder}
          minDate={minDate}
          onFocus={() => {}}
          onBlur={(e) => {
            field.onBlur(e);
            if (onBlur) {
              onBlur(e);
            }
          }}
          popperPlacement="top"
          popperClassName={(isUserHours || isPopper) && styles.popper}
          calendarClassName="datapicker-component"
          calendarContainer={!isUserHours && MyContainer(setIsFocus, "")}
          autoComplete="off"
          timeFormat="p"
          selectsRange={isUserHours ? true : false}
          startDate={startDate ? startDate : null}
          endDate={endDate ? endDate : null}
          customTimeInput={customTimeInput && <CustomTimeInput />}
          renderCustomHeader={MyHeader}
        />
      </div>
    </>
  );
};

const DatePickerWithoutContorol = (props) => {
  const { size = "md", isPlaceholderWhite } = props;
  const calendarRef = useRef();
  const [isFocus, setIsFocus] = useState(false);
  const ref = useRef();

  const {
    label,
    placeholder,
    showTimeInput = true,
    dateFormat,
    disabled,
    onChange,
    selected,
    onFocus,
    onBlur,
    errors,
    rules,
    open,
    minDate,
    customTimeInput,
  } = props;

  return (
    <>
      <div
        className={cx(styles["input"], styles["date-input"], {
          [styles["placeholder-white"]]: isPlaceholderWhite,
          [styles[`${size}`]]: size,
        })}
      >
        <DatePickerComponent
          {...props}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus();
            }
          }}
          ref={ref}
          open={open}
          selected={selected}
          disabled={disabled}
          dateFormat={dateFormat}
          showTimeInput={showTimeInput}
          placeholderText={placeholder}
          minDate={minDate}
          popperPlacement="top"
          calendarClassName="datapicker-component"
          calendarContainer={MyContainer(setIsFocus, "")}
          autoComplete="off"
          timeFormat="p"
          customTimeInput={props.customTimeInput && <CustomTimeInput />}
          renderCustomHeader={MyHeader}
        />
      </div>
    </>
  );
};

const CalendarInput = (props) => {
  if (props.control) {
    return <DatePickerContorol {...props} />;
  }

  return <DatePickerWithoutContorol {...props} />;
};

export default CalendarInput;
