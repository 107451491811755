import API from "../api";

export const createEventCategoryReq = (data) =>
  API.post("/admin/event/category", data);

export const updateEventCategoryReq = (id, data) =>
  API.put(`/admin/event/category/${id}`, data);

export const deleteEventCategoryReq = (id) =>
  API.delete(`/admin/event/category/${id}`);

export const getEventCategoryReq = (id) =>
  API.get(`/admin/event/category/${id}`);

export const getEventCategoryListReq = () =>
  API.get(`/admin/event/category/list`);
