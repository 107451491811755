import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import Notepad from "assets/svg/Notepad";
import Plus from "assets/svg/Plus";

const AnimatedIcon = ({ selected }) => (
  <>
    <div
      className={cx(styles["animated-icon"], {
        [styles.selected]: selected,
      })}
    >
      <div>
        <Notepad />
      </div>
      <div>
        <Plus />
      </div>
    </div>
  </>
);

export default AnimatedIcon;
