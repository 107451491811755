import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { ns } from "./i18n.namespace";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "uk",
    fallbackLng: ["uk"],
    debug: false && process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false,
    },
    ns: ns,
    react: {
      // useSuspense: false,
    },
  });

export default i18n;
