import * as React from "react";

const SortArrowUp = (props) => (
  <svg
    width="9"
    height="27"
    viewBox="0 0 9 27"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 26V2M4.5 2L1 7M4.5 2L8 7"
      stroke="#3DDDE8"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

export default SortArrowUp;
