/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  selectStepsPages,
  selectStoredCategoryId,
  setToDefault,
  updateStepPageDataByKey,
} from "../../../store/slices/steps";
import {
  createEvent,
  eventDataMutationForEventDetails,
  eventDataMutationForRequest,
  selectIsEventLoading,
} from "../../../store/slices/event";
import { lang } from "store/slices/langSlice";

import ModalWarning, { ErrorText, SuccessText } from "containers/ModalWarning";
import { NotificationManager } from "react-notifications";

import EventDetail from "containers/EventDetail";
import Loader from "components/Loader";
import { Trans, useTranslation } from "react-i18next";
import { parseDateToFormat } from "utils/date";

const FinishStepPage = ({ setActiveStep }) => {
  const { t } = useTranslation(["modals", "notification"]);
  const storedData = useSelector(selectStepsPages);
  const storedCategoryId = useSelector(selectStoredCategoryId);
  const isLoading = useSelector(selectIsEventLoading);
  const langValue = useSelector(lang);

  const [editData, setEditData] = useState(null);
  const [requestData, setRequestData] = useState(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showSuccessModal = (data) => {
    setEditData(data);
    setIsSuccessModal(true);
    setIsOpenModal(true);
  };

  const showDeleteModal = () => {
    setIsSuccessModal(false);
    setIsOpenModal(true);
  };

  const clearStore = () => {
    dispatch(setToDefault());
    setActiveStep(-1);
  };

  const successHandler = async () => {
    if (!isSuccessModal) {
      return clearStore();
    }

    setIsOpenModal(false);

    try {
      await dispatch(createEvent(requestData)).unwrap();

      clearStore();
      navigate("/events/new-event");
    } catch (error) {
      if (error?.response?.data?.errorMessages) {
        NotificationManager.error("", error?.response?.data?.errorMessages[0], 6000);
      }
    }
  };
  useEffect(() => {
    if (storedData) {
      setEditData(eventDataMutationForEventDetails(storedData, storedCategoryId, langValue));

      setRequestData(eventDataMutationForRequest(storedData, storedCategoryId, langValue));
    }
  }, [storedData, langValue]);

  useEffect(() => {
    const showModal = (e) => {
      if (e.keyCode === 13 && e.ctrlKey) {
        e.preventDefault();
        setIsSuccessModal(true);
        setIsOpenModal(true);
      }
    };
    window.addEventListener("keydown", showModal);
    return () => window.removeEventListener("keydown", showModal);
  }, []);

  return (
    editData && (
      <>
        <EventDetail
          isFinished
          data={editData}
          requestData={requestData}
          saveHandler={showSuccessModal}
          deleteHandler={showDeleteModal}
          onFieldChange={(key, value) => {
            let fixedValue = value;
            if (key === "betEndTime" || key === "finishTime" || key === "startTime") {
              fixedValue = parseDateToFormat(value);
            }
            dispatch(updateStepPageDataByKey(key, fixedValue, langValue));
          }}
        />
        <ModalWarning
          isOpen={isOpenModal}
          setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
          successHandler={successHandler}
          cancelHandler={() => {
            setIsSuccessModal(false);
          }}
          title={
            isSuccessModal ? (
              <span>
                <Trans i18nKey="modals:saveToUnpublished">
                  Зберегти все в <SuccessText>не опубліковані події</SuccessText>?
                </Trans>
              </span>
            ) : (
              <span>
                <Trans i18nKey="modals:cancelEvent">
                  Ти точно впевнений, що хочеш <ErrorText>СКАСУВАТИ ПОДІЮ</ErrorText>?
                </Trans>
              </span>
            )
          }
        />
        {isLoading && <Loader />}
      </>
    )
  );
};

export default FinishStepPage;
