import styles from "./style.module.scss";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import validator from "validator";
import cx from "classnames";

const PinField = ({ setCode, size = 8, isTwoFA }) => {
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);
  const inputRef8 = useRef(null);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState("");
  const [value7, setValue7] = useState("");
  const [value8, setValue8] = useState("");

  const changeInp = (e, inputVal, inputRefNext, changeVal) => {
    let char = e.target.value;
    if (validateChar(char)) {
      changeVal(char);
      inputRefNext && inputRefNext.current?.focus();
    }
  };

  const validateChar = (char) => {
    if (validator.isInt(char)) {
      return true;
    }
    return false;
  };

  const onPress = (e, inputVal, changeVal, inputRefPrev) => {
    if (inputRefPrev?.current?.value === "") {
      inputRefPrev && inputRefPrev.current?.focus();
    }
    if (e.keyCode === 8) {
      changeVal("");
      if (inputVal.length == 0) {
        inputRefPrev && inputRefPrev.current?.focus();
      }
    } else {
      if (validateChar(e.key)) {
        changeVal("");
      }
    }
  };

  useEffect(() => {
    let code =
      size === 8
        ? `${value1}${value2}${value3}${value4}${value5}${value6}${value7}${value8}`
        : `${value1}${value2}${value3}${value4}${value5}${value6}`;
    setCode(code);
  }, [value1, value2, value3, value4, value5, value6, value7, value8]);

  return (
    <div
      className={cx(styles.wrapperInputs + " " + styles[`size-${size}`], {
        [styles.flex]: isTwoFA,
      })}
    >
      <div
        className={cx(styles["wrapperTwoInputs"], {
          [styles.sizing]: isTwoFA,
        })}
      >
        <Input
          value={value1}
          onKeyDown={(e) => onPress(e, value1, setValue1, null)}
          onInput={(e) => changeInp(e, value1, inputRef2, setValue1)}
          onFocus={() => {
            setValue1("");
          }}
          placeholder={"X"}
          ref={inputRef1}
        />
        <Input
          value={value2}
          onKeyDown={(e) => onPress(e, value2, setValue2, inputRef1)}
          onInput={(e) => changeInp(e, value2, inputRef3, setValue2)}
          onFocus={() => {
            setValue2("");
          }}
          placeholder={"X"}
          ref={inputRef2}
        />
      </div>

      <div
        className={cx(styles["wrapperTwoInputs"], {
          [styles.sizing]: isTwoFA,
        })}
      >
        <Input
          value={value3}
          onKeyDown={(e) => onPress(e, value3, setValue3, inputRef2)}
          onInput={(e) => changeInp(e, value3, inputRef4, setValue3)}
          onFocus={() => {
            setValue3("");
          }}
          placeholder={"X"}
          ref={inputRef3}
        />
        <Input
          value={value4}
          onKeyDown={(e) => onPress(e, value4, setValue4, inputRef3)}
          onInput={(e) => changeInp(e, value4, inputRef5, setValue4)}
          onFocus={() => {
            setValue4("");
          }}
          placeholder={"X"}
          ref={inputRef4}
        />
      </div>

      {size == 8 ? (
        <>
          <div className={styles.wrapperTwoInputs}>
            <Input
              type={"number"}
              value={value5}
              onKeyDown={(e) => onPress(e, value5, setValue5, inputRef4)}
              onInput={(e) => changeInp(e, value5, inputRef6, setValue5)}
              onFocus={() => {
                setValue5("");
              }}
              placeholder={"X"}
              ref={inputRef5}
            />
            <Input
              type={"number"}
              value={value6}
              onKeyDown={(e) => onPress(e, value6, setValue6, inputRef5)}
              onInput={(e) => changeInp(e, value6, inputRef7, setValue6)}
              onFocus={() => {
                setValue6("");
              }}
              placeholder={"X"}
              ref={inputRef6}
            />
          </div>
          <div className={styles.wrapperTwoInputs}>
            <Input
              type={"number"}
              value={value7}
              onKeyDown={(e) => onPress(e, value7, setValue7, inputRef6)}
              onInput={(e) => changeInp(e, value7, inputRef8, setValue7)}
              onFocus={() => {
                setValue7("");
              }}
              placeholder={"X"}
              ref={inputRef7}
            />
            <Input
              type={"number"}
              value={value8}
              onKeyDown={(e) => onPress(e, value8, setValue8, inputRef7)}
              onInput={(e) => changeInp(e, value8, null, setValue8)}
              onFocus={() => {
                setValue8("");
              }}
              placeholder={"X"}
              ref={inputRef8}
            />
          </div>
        </>
      ) : (
        <div
          className={cx(styles["wrapperTwoInputs"], {
            [styles.sizing]: isTwoFA,
          })}
        >
          <Input
            value={value5}
            onKeyDown={(e) => onPress(e, value5, setValue5, inputRef4)}
            onInput={(e) => changeInp(e, value5, inputRef6, setValue5)}
            ref={inputRef5}
            placeholder={"X"}
          />
          <Input
            value={value6}
            onKeyDown={(e) => onPress(e, value6, setValue6, inputRef5)}
            onInput={(e) => changeInp(e, value6, null, setValue6)}
            ref={inputRef6}
            placeholder={"X"}
          />
        </div>
      )}
    </div>
  );
};
export default PinField;
const Input = forwardRef((props, ref) => {
  return <input maxLength={1} className={styles.number} ref={ref} {...props} />;
});
