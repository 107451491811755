import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCommissionsMethod,
  setSystemSettingMethod,
  toggleDepositWithdrawSettingMethod,
  getDepositWithdrawSettingMethod,
} from "api/settings";
import { toasterCreator } from "utils/toasterCreator";

const initialState = {
  isLoading: false,
  error: null,
  commissions: {},
  depositWithdrawSettings: {},
};

export const getCommissionsAction = createAsyncThunk(
  "settings/getCommissionsAction",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCommissionsMethod();
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const setSystemSettingAction = createAsyncThunk(
  "settings/setSystemSettingAction",
  async (data, { rejectWithValue }) => {
    try {
      const res = await setSystemSettingMethod(data);
      if (res.status === 200 || res.status === 201) {
        toasterCreator.generalSuccess.success(res?.data?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getDepositWithdrawSettingAction = createAsyncThunk(
  "settings/getDepositWithdrawSettingAction",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getDepositWithdrawSettingMethod();
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const toggleDepositWithdrawSettingAction = createAsyncThunk(
  "settings/toggleDepositWithdrawSettingAction",
  async (data, { rejectWithValue }) => {
    const { setting, toggle } = data;
    try {
      const res = await toggleDepositWithdrawSettingMethod(setting, toggle);
      if (res.status === 200 || res.status === 201) {
        toasterCreator.generalSuccess.success(res?.data?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getCommissionsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCommissionsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.commissions = action.payload;
      })
      .addCase(getCommissionsAction.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(setSystemSettingAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setSystemSettingAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(setSystemSettingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDepositWithdrawSettingAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepositWithdrawSettingAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.depositWithdrawSettings = action.payload;
      })
      .addCase(getDepositWithdrawSettingAction.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(toggleDepositWithdrawSettingAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(toggleDepositWithdrawSettingAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(toggleDepositWithdrawSettingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const selectIsLoading = (state) => state.settings?.isLoading;
export const selectCommissions = (state) => state.settings?.commissions;
export const selectDepositWithdrawSettings = (state) => state.settings?.depositWithdrawSettings;

export default settingsSlice.reducer;
