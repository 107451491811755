import React, { useState, useEffect } from "react";
import Close from "assets/svg/X";
import cx from "classnames";
import ReactDOM from "react-dom";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import PinField from "components/PinField";
import { useOuterClick } from "../../utils/useOuterClick";
import { fixNestedModalsCloseTrigger } from "utils/fixNestedModalsCloseTrigger";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../store/slices/crypto";

const ModalTwoFactorPin = ({
  isOpen,
  setIsOpenModal,
  size = 6,
  successHandler = () => {},
  cancelHandler = () => {},
  type,
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button"]);
  const [code, setCode] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  const isLoading = useSelector(selectIsLoading);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  useEffect(() => {
    const codeLength = code.length;
    codeLength === size && !isLoading ? setBtnDisabled(false) : setBtnDisabled(true);
  }, [code, isLoading]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div ref={innerRef} className={cx(styles["modal-pin"])} onClick={fixNestedModalsCloseTrigger}>
        <div className={styles["modal-pin__header-wrapper"]}>
          <h2 className={styles["modal-pin__title"]}>{!type ? "Відключити 2FA" : "Введіть код 2FA"}</h2>
          <div className={styles["modal-pin__close-wrapper"]} onClick={cancelHandler}>
            <Close />
          </div>
        </div>

        <div className={styles["modal-pin__content"]}>
          <p>
            {!type &&
              "З метою безпеки ми повинні переконатися, що саме ви намагаєтеся змінити налаштування. Введіть ваш 2FA перевірочний код"}
          </p>

          <PinField setCode={setCode} size={6} />
        </div>

        <div className={styles["modal-pin__btns"]}>
          <Button
            disabled={btnDisabled}
            autoFocus
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              successHandler(code);
            }}
            styleBtn={{ minWidth: "191px" }}
          >
            {!type ? t("turnOff") : t("confirm_v2")}
          </Button>
        </div>
      </div>
    </Modal>,
    document.getElementById("root"),
  );
};

export default ModalTwoFactorPin;
