import React from "react";

const BlockImg = () => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27 52C40.8071 52 52 40.8071 52 27C52 13.1929 40.8071 2 27 2C13.1929 2 2 13.1929 2 27C2 40.8071 13.1929 52 27 52Z"
        stroke="#FFD01F" strokeWidth="3" />
      <path d="M44.6779 44.6779L9.32214 9.32214" stroke="#FFD01F" strokeWidth="3" />
    </svg>

  );
};

export default BlockImg;
