import React from "react";
import cx from "classnames";
import { googleTranslateReq } from "api/google";
import { NotificationManager } from "react-notifications";

import styles from "./index.module.scss";

const AutoTranslateButton = ({
  getText = () => {},
  onTranslate = () => {},
  disabled,
}) => {
  const extractSomeText = (data) => {
    for (const key of Object.keys(data)) {
      if (data[key]) {
        return data[key];
      }
    }

    return "";
  };

  const translate = async () => {
    const { currentText, allTextValues, langs } = getText();
    let someTextToTranslate = currentText || extractSomeText(allTextValues);

    if (!someTextToTranslate) {
      return;
    }

    const translated = {};

    for (const key of Object.keys(langs)) {
      try {
        const res = await googleTranslateReq(someTextToTranslate, key);
        const data = await res.json();
        const value = data.data?.translations;

        if (value) {
          translated[key] = value[0].translatedText;
        }

        if (data?.error?.message) {
          NotificationManager.error("", data.error.message, 6000);
        }
      } catch (err) {
        NotificationManager.error("", err.toString(), 6000);
      }
    }

    onTranslate(translated);
  };

  return (
    <div
      className={cx(styles["autotranslate"], {
        [styles.disabled]: disabled,
      })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        translate();
      }}
      tabIndex={0}
    >
      <span>Auto Translation</span>
      <i className="autotranslate-icon"></i>
    </div>
  );
};

export default AutoTranslateButton;
