import * as React from "react";

const Question = (props) => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.0615 24.0946C34.5638 21.8887 36.5841 20.0848 38.9455 18.8412C41.3068 17.5975 43.9372 16.9519 46.606 16.961C49.2749 16.9701 51.9008 17.6337 54.2536 18.8934C56.6064 20.1532 58.6144 21.9708 60.1015 24.1869C61.5887 26.4031 62.5096 28.9501 62.7836 31.6049C63.0577 34.2596 62.6764 36.9411 61.6733 39.4142C60.6702 41.8874 59.0759 44.0769 57.03 45.7907C54.9841 47.5045 52.5491 48.6904 49.9384 49.2445C49.0064 49.4213 48.1642 49.915 47.5547 50.642C46.9452 51.369 46.6059 52.2844 46.5944 53.233V60.0429"
      stroke="#FF0000"
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M47.2386 78.0671C49.5472 78.0671 51.4187 76.1956 51.4187 73.8871C51.4187 71.5785 49.5472 69.707 47.2386 69.707C44.9301 69.707 43.0586 71.5785 43.0586 73.8871C43.0586 76.1956 44.9301 78.0671 47.2386 78.0671Z"
      stroke="#FF0000"
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M48 93C72.8528 93 93 72.8528 93 48C93 23.1472 72.8528 3 48 3C23.1472 3 3 23.1472 3 48C3 72.8528 23.1472 93 48 93Z"
      stroke="#FF0000"
      stroke-width="5"
      stroke-linecap="round"
    />
  </svg>
);

export default Question;
