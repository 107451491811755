import * as React from "react";

const Plus = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="10"
      y1="3"
      x2="10"
      y2="17"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <line
      x1="3"
      y1="10"
      x2="17"
      y2="10"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
  </svg>
);

export default Plus;
