/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventCategoryList,
  selectSelectedEventCategoryId,
} from "../../store/slices/eventCategory";

import Loader from "../../components/Loader";
import Tips, { TIPS_TYPE } from "../../containers/Tips";

import Icon2 from "../../assets/home/animale.png";
import dogTip1 from "../../assets/events/dog-1.png";

import "./index.scss";

const Events = ({ children }) => {
  const [tipType, setTipType] = useState(TIPS_TYPE.CHOOSE);
  const dispatch = useDispatch();
  const isEventCategoryLoading = useSelector(
    (state) => state.eventCategory.isEventCategoryLoading
  );
  const selectedCategory = useSelector(selectSelectedEventCategoryId);
  const fetchCategoryList = async () => {
    dispatch(getEventCategoryList()).unwrap().then().catch();
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  useEffect(() => {
    if (selectedCategory && !children) {
      setTipType(TIPS_TYPE.CHOOSE_PART);
    } else if (!selectedCategory && !children) {
      setTipType(TIPS_TYPE.CHOOSE);
    }
  }, [selectedCategory, children]);

  return (
    <div className="events">
      {children || (
        <>
          <div className="events__tips">
            <Tips type={tipType} />
          </div>
          <img
            className="icon2"
            src={tipType === TIPS_TYPE.CHOOSE ? Icon2 : dogTip1}
            alt="choose-icon-2"
          />
        </>
      )}
      {isEventCategoryLoading && <Loader />}
    </div>
  );
};

export default Events;
