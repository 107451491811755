import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import Copy from "assets/svg/Copy";
import styles from "./index.module.scss";
import { copyToBuffer } from "utils/copyToBuffer";
import { getTransferFeeAction, selectFee } from "store/slices/crypto";

function WithdrawalWallet({ data, onSubmitActions, isTransferSummaryModalOpen }) {
  const dispatch = useDispatch();
  // const fee = useSelector(selectFee) || 0;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const { mainContractAddress, mainContractUSDTBalance, nodeContractAddress, nodeContractTRXBalance } = data;

  const handleCopyClick = (e) => {
    copyToBuffer(e.currentTarget.textContent);
  };

  const handleFormSubmit = (data) => {
    onSubmitActions.setTransferData((prev) => {
      return { ...prev, ...data };
    });
    onSubmitActions.setType("USDT");
    onSubmitActions.setIsTransferSummaryModalOpen(!isTransferSummaryModalOpen);
    reset();
  };

  const withdrawBtnHandler = () => {
    const getFeeApiMethodPayload = {
      to: getValues()?.address,
      from: mainContractAddress,
      amount: +getValues()?.sum,
    };
    dispatch(getTransferFeeAction(getFeeApiMethodPayload));
  };

  return (
    <>
      <ul className={styles["table"]}>
        <li key={"Node"} className={styles["row"]}>
          <span className={styles["row-title"]}>Node адреса</span>

          <span className={styles["adress-wrapper"]}>
            <span onClick={handleCopyClick} className={styles["adress"]}>
              {nodeContractAddress}
              <Copy width="24" height="24" />
            </span>
          </span>

          <span className={styles["quantity"]}>{nodeContractTRXBalance} TRX</span>
        </li>
        <li key={"Factoty"} className={styles["row"]}>
          <span className={styles["row-title"]}>Factory адреса</span>

          <span className={styles["adress-wrapper"]}>
            <span onClick={handleCopyClick} className={styles["adress"]}>
              {mainContractAddress}
              <Copy width="24" height="24" />
            </span>
          </span>

          <span className={styles["quantity"]}>{mainContractUSDTBalance} USDT</span>
        </li>
      </ul>

      <div className={styles["wallet-form"]}>
        <span className={styles["row-title"]}>Вивід з factory адреси</span>
        <form onSubmit={handleSubmit(handleFormSubmit)} className={styles["wallet-form_form"]}>
          <div>
            <input
              {...register("address", {
                required: "This field is required",
                pattern: { value: /^[A-Za-z0-9]+$/, message: "Wrond adress format" },
              })}
              placeholder="Введіть адресу"
              className={styles["wallet-imput_adress"]}
            />
            {errors.address && <div className={styles["errorMessage"]}>{errors?.address.message}</div>}
          </div>
          <div>
            <input
              {...register("sum", {
                required: "This field is required",
                pattern: { value: /^\d+(\.\d+)?$/, message: "Allow numbers only" },
                validate: (v) => {
                  if (v > mainContractUSDTBalance) return "Не достатньо коштів на рахунку";
                  // to be revised
                  // if (v <= fee) return `Сума виводу має бути більшою за ${fee} USDT`;
                },
              })}
              placeholder="Сума"
              className={styles["wallet-imput_amount"]}
            />
            {errors.sum && <div className={styles["errorMessage"]}>{errors?.sum.message}</div>}
          </div>

          <button onClick={withdrawBtnHandler} type="subbmit">
            Надіслати
          </button>
        </form>
      </div>
    </>
  );
}

export default WithdrawalWallet;
