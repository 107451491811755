import React from "react";
import "./index.scss";

const SaveButton = ({ onClick, disabled }) => (
  <button className="save-button " onClick={onClick} disabled={disabled}>
    <i className="save-button__icon" />
  </button>
);

export default SaveButton;
