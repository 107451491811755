import React from "react";

// Redux
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setLang, lang } from "store/slices/langSlice";
import { selectEventList } from "store/slices/event";

// Icons
import FR from "assets/svg/FR";
import ES from "assets/svg/ES";
import KZ from "assets/svg/KZ";
import PL from "assets/svg/PL";
import UA from "assets/svg/UA";
import DE from "assets/svg/DE";
import EN from "assets/svg/EN";

// Styling
import classNames from "classnames/bind";
import styles from "./LangComponent.module.scss";
const cx = classNames.bind(styles);

const langArr = [
  {
    value: "uk",
    icon: <UA />,
  },
  {
    value: "en",
    icon: <EN />,
  },
  {
    value: "de",
    icon: <DE />,
  },
  {
    value: "fr",
    icon: <FR />,
  },
  {
    value: "es",
    icon: <ES />,
  },
  {
    value: "kk",
    icon: <KZ />,
  },
  {
    value: "pl",
    icon: <PL />,
  },
];

const LangComponent = ({
  eventList,
  setIndividualLang,
  individualLang,
  isEventCard,
  isNotifications,
  getEvents,
  viewType = "horizontal",
  onLangChange = () => {},
}) => {
  const dispatch = useDispatch();
  const { content } = useSelector(selectEventList, shallowEqual);
  const stateLang = useSelector(lang);

  const language = isEventCard || isNotifications ? individualLang : stateLang;

  const changeLang = (lang) => {
    if (isEventCard || isNotifications) {
      setIndividualLang(lang);
    }

    if (!isNotifications) {
      dispatch(setLang(lang));
      localStorage.setItem("lang", lang);
    }

    if (isEventCard && !content[lang].length) {
      getEvents();
    }
  };

  return (
    <div className={cx("langComponent", viewType)}>
      <ul className={cx("langComponent__list", viewType)}>
        {langArr.map((el, index) => (
          <li
            key={index}
            className={cx(
              "langComponent__list-item",
              language === el.value ? "active" : ""
            )}
            onClick={() => changeLang(el.value)}
          >
            {el.icon}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default LangComponent;
