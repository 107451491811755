import React, { useEffect } from "react";
import ImageList from "../../containers/ImageList";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const ImagePage = () => {
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    document.title = t("common:images");
  }, []);

  return (
    <div className={styles.images}>
      <ImageList isEdit />
    </div>
  );
};

export default ImagePage;
