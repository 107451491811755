import React from "react";

const Calendar = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.09277 9.4025H20.9167"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.442 13.3088H16.4512"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0045 13.3088H12.0137"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.55769 13.3088H7.56695"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.442 17.1955H16.4512"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0045 17.1955H12.0137"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.55769 17.1955H7.56695"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0438 2V5.29078"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.96564 2V5.29078"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2383 3.57812H7.77096C4.83427 3.57812 3 5.21406 3 8.22115V17.2708C3 20.3252 4.83427 21.9989 7.77096 21.9989H16.229C19.175 21.9989 21 20.3535 21 17.3464V8.22115C21.0092 5.21406 19.1842 3.57812 16.2383 3.57812Z"
      stroke="#3DDDE8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Calendar;
