/* eslint-disable react-hooks/exhaustive-deps */
import ModalWarning, { ErrorText, SuccessText } from "containers/ModalWarning";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markItemAsDirty,
  markItemAsValid,
  markItemAsVisited,
  saveStepPageToStore,
  selectStepsItems,
  setStepPageData,
} from "../../../store/slices/steps";
import { selectSelectedEventCategoryId } from "store/slices/eventCategory";
import ImageList from "../../ImageList";
import { Trans, useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const ImageStepPage = ({ id, setActiveStep }) => {
  const { t } = useTranslation(["modals"]);
  const dispatch = useDispatch();
  const stepsItems = useSelector(selectStepsItems);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCanGoToFinishPage, setIsCanGoToFinishPage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const selectedCategory = useSelector(selectSelectedEventCategoryId);

  useEffect(() => {
    dispatch(markItemAsDirty(id, true));
    dispatch(markItemAsVisited(id));
  }, []);

  const selectImageHandler = (image) => {
    dispatch(setStepPageData(id, image));
    dispatch(markItemAsValid(id, true));
  };

  const chekIsCanSaveImage = (image) => {
    setSelectedImage(image);
    setIsOpenModal(true);
  };

  useEffect(() => {
    const noStoreItem = stepsItems.filter((item) => !item.isStored);
    if (noStoreItem.length > 1) {
      return setIsCanGoToFinishPage(false);
    }
    setIsCanGoToFinishPage(true);
  }, []);

  return (
    <div className={styles["image-step"]}>
      <ImageList selectImageHandler={chekIsCanSaveImage} />
      <ModalWarning
        isOpen={isOpenModal}
        type={!isCanGoToFinishPage && "isQuestion"}
        setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
        successHandler={() => {
          if (isCanGoToFinishPage) {
            selectImageHandler(selectedImage);
            dispatch(saveStepPageToStore(id, selectedCategory));
            setActiveStep(-1);
          }
        }}
        title={
          isCanGoToFinishPage ? (
            <span>
              <Trans i18nKey="modals:areYouShureImage">
                Впевнений у виборі <br />
                <SuccessText>зображення</SuccessText>?
              </Trans>
            </span>
          ) : (
            <span>
              <Trans i18nKey="modals:selectImageError">
                Не можливо вибрати зображення, бо <ErrorText>попередні кроки не були збережені</ErrorText>!
              </Trans>
            </span>
          )
        }
        hideCancelBtn={!isCanGoToFinishPage}
      />
    </div>
  );
};

export default ImageStepPage;
