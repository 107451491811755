import React from "react";
import cx from "classnames";

import Button from "components/Button";

import DeleteIcon from "assets/svg/DeleteIcon";
import ArrowUp from "assets/svg/ArrowUp";
import ArrowDown from "assets/svg/ArrowDown";
import Play from "assets/svg/Play";
import AnimatedIcon from "components/AnimatedIcon";

import styles from "../index.module.scss";

const UserCol = ({
  data,
  headers,
  position,
  expandRow,
  userHistoryOpenedModal,
  loggedUserRole,
  deleteHandler = () => {},
  openModal = () => {},
  changeStatus = () => {},
  setExpandRow = () => {},
  userBidsActivityHandler,
  setUsername,
  userWithdrawHistoryModalHandler,
  userDepositHistoryModalHandler,
}) => {
  return headers.map((header, index) => {
    return (
      <td
        key={header.key}
        className={cx(styles["user-col"], {
          [styles.status]: header.key === "status",
          [styles.history]:
            header.key === "activity_history" || header.key === "out_history" || header.key === "add_history",
          [styles.selected]: header.key === userHistoryOpenedModal.key && data.id === userHistoryOpenedModal.id,
        })}
      >
        {header.key === "activity_history" || header.key === "out_history" || header.key === "add_history" ? (
          <Button
            disabled={data.status === "DELETED" && true}
            width={34}
            height={46}
            type="clear"
            onClick={() => {
              openModal(header.key, data);
              setUsername(data?.username);

              if (header.key === "activity_history") {
                userBidsActivityHandler(data?.username);
              }

              if (header.key === "out_history") {
                userWithdrawHistoryModalHandler(data?.username);
              }

              if (header.key === "add_history") {
                userDepositHistoryModalHandler(data?.username);
              }
            }}
          >
            <AnimatedIcon
              selected={header.key === userHistoryOpenedModal.key && data.id === userHistoryOpenedModal.id}
            />
          </Button>
        ) : header.key === "actions" ? (
          <div className={styles.actions}>
            {data.blocked ? (
              <Button
                disabled={data.status === "DELETED" || loggedUserRole === "ROLE_MARKETER"}
                width={30}
                height={30}
                type="unblock"
                onClick={() => changeStatus(data)}
              />
            ) : (
              <Button
                disabled={data.status === "DELETED" || loggedUserRole === "ROLE_MARKETER"}
                width={30}
                height={30}
                type="block"
                onClick={() => changeStatus(data)}
              />
            )}

            <Button
              disabled={
                loggedUserRole === "ROLE_TECH_SUPPORT" ||
                loggedUserRole === "ROLE_MARKETER" ||
                data.status === "DELETED"
              }
              width={30}
              height={30}
              type="clear"
              onClick={() => {
                deleteHandler(data);
              }}
            >
              <DeleteIcon />
            </Button>
            <Button
              width={30}
              height={30}
              type="clear"
              onClick={() => {
                setExpandRow(position === expandRow ? -1 : position);
              }}
            >
              {position === expandRow && <ArrowUp />}
              {position !== expandRow && <ArrowDown />}
            </Button>
          </div>
        ) : data[header.key] === undefined ? (
          <span>-</span>
        ) : (
          <span>{data[header.key]}</span>
        )}
      </td>
    );
  });
};

export default UserCol;
