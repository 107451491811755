import API from "../api";
import { formatDateForApiCall } from "utils/date";

export const getUsersListReq = (query) =>
  API.get("/admin/users", {
    params: {
      ...query,
      page: query?.page || 0,
      size: query?.size || 5,
    },
  });

export const getUserByIdReq = (id) => API.get(`/admin/users/${id}`);

export const fetchUsersForAdminTbl = (page, size) => {
  return API.get(`/admin/administrators?page=${page}&size=${size}`);
};

export const fetchUserForAdminTbl = (id) => {
  return API.get(`/admin/administrators/${id}`);
};

export const createUserForAdminTbl = (userData) => {
  return API.post("/admin/administrators/create", userData);
};

export const updateUserRoleForAdminTbl = (id, role) => {
  return API.patch(`/admin/administrators/${id}?role=${role}`);
};

export const searchUsersInAdminTbl = (keyword) => {
  return API.get(`/admin/administrators?keyword=${keyword}`);
};

export const blockUserInAdminTbl = (id) => {
  return API.put(`/admin/administrators/${id}/block`);
};

export const unblockUserInAdminTbl = (id) => {
  return API.put(`/admin/administrators/${id}/unblock`);
};

export const deleteUserInAdminTbl = (id) => {
  return API.delete(`/admin/administrators/${id}`);
};

export const resetUserPassInAdminTbl = (userId, body) => {
  return API.put(`/admin/administrators/${userId}/change-password`, body);
};

export const fetchUserActionsInAdminTbl = (userId, startDate, endDate) => {
  return API.get(
    `/admin/administrators/${userId}/activity?startDate=${formatDateForApiCall(
      startDate,
    )} 00:00&endDate=${formatDateForApiCall(endDate)} 23:59`,
  );
};

export const downloadAdminActions = (userId, startDate, endDate) => {
  return API.get(
    `/admin/administrators/${userId}/activity/download?startDate=${formatDateForApiCall(
      startDate,
    )} 00:00&endDate=${formatDateForApiCall(endDate)} 23:59`,
    {
      responseType: "blob",
    },
  );
};

export const fetchUserhoursInAdminTbl = (userId, startDate, endDate) => {
  return API.get(
    `/admin/administrators/${userId}/tracking?startDate=${formatDateForApiCall(
      startDate,
    )} 00:00&endDate=${formatDateForApiCall(endDate)} 23:59`,
  );
};

export const downloadUserhoursInAdminTbl = (userId, startDate, endDate) => {
  return API.get(
    `/admin/administrators/${userId}/tracking/download?startDate=${formatDateForApiCall(
      startDate,
    )} 00:00&endDate=${formatDateForApiCall(endDate)} 23:59`,
    {
      responseType: "blob",
    },
  );
};

export const getCustomersListReq = (query) =>
  API.get("/admin/editingbalance", {
    params: {
      ...query,
      page: query?.page || 0,
      size: query?.size || 9,
    },
  });

export const incrementCustomerBalanceReq = (data) => {
  const { query, code } = data;

  const amount = query?.amount ? `?amount=${query?.amount}` : `?amount=0`;
  return API.put(`/admin/editingbalance/${query.username}/add-balance${amount}&code=${code}`);
};

export const decrementCustomerBalanceReq = (data) => {
  const { query, code } = data;

  const amount = query?.amount ? `?amount=${query?.amount}` : `?amount=0`;
  return API.put(`/admin/editingbalance/${query.username}/subtract-balance${amount}&code=${code}`);
};

export const blockUserInUsersTbl = (id) => {
  return API.put(`/admin/users/${id}/block`);
};

export const unblockUserInUsersTbl = (id) => {
  return API.put(`/admin/users/${id}/unblock`);
};

export const deleteUserInUsersTbl = (id) => {
  return API.delete(`/admin/users/${id}`);
};

export const fetchUserBidsActivity = (username, page = 0, size = 3) => {
  return API.get(`/admin/users/${username}/activity?page=${page}&size=${size}`);
};
