export const CATEGORY_COLORS = [
  {
    color:
      "linear-gradient(97.17deg, #FC0700 0%, rgba(252, 142, 81, 0.87) 101.96%)",
    id: 1,
  },
  {
    color: "linear-gradient(90deg, #3D0B3B 0%, #C31432 100%)",
    id: 2,
  },
  {
    color: "linear-gradient(90deg, #F25D00 0%, #FFAF14 100%)",
    id: 3,
  },
  {
    color:
      "linear-gradient(97.17deg, #CAFC58 0%, rgba(250, 126, 105, 0.87) 101.96%)",
    id: 4,
  },
  {
    color: "linear-gradient(90deg, #007112 0%, #71E200 100%)",
    id: 5,
  },
  {
    color: "linear-gradient(90deg, #076585 0%, #36D1DC 100%)",
    id: 6,
  },
  {
    color:
      "linear-gradient(97.17deg, #6BB5F8 0%, rgba(184, 107, 248, 0.87) 101.96%)",
    id: 7,
  },
  {
    color: "linear-gradient(90deg, #C31432 0%, #EF32D9 100%)",
    id: 8,
  },
  {
    color: "linear-gradient(90deg, #FFAFBD 0%, #FFC3A0 100%)",
    id: 9,
  },
  {
    color: "linear-gradient(90deg, #E94057 0%, #FFAFBD 100%)",
    id: 10,
  },
  {
    color:
      "linear-gradient(97.17deg, #72FCC3 0%, rgba(228, 250, 130, 0.87) 101.96%)",
    id: 11,
  },
  {
    color: "linear-gradient(90deg, #7B920A 0%, #ADD100 100%)",
    id: 12,
  },
  {
    color: "linear-gradient(90deg, #1F3200 0%, #007C32 100%)",
    id: 13,
  },
  {
    color: "linear-gradient(90deg, #BE93C5 0%, #7BC6CC 100%)",
    id: 14,
  },
  {
    color: "linear-gradient(90deg, #EF32D9 0%, #89FFFD 100%)",
    id: 15,
  },
  {
    color: "linear-gradient(90deg, #493240 0%, #FF0099 100%, #FF0099 100%)",
    id: 16,
  },
];

export const CATEGORY_DEFAULT_COLOR =
  "linear-gradient(97.17deg, #CAFC58 0%, rgba(250, 126, 105, 0.87) 101.96%)";

export const TOASTERS_PALLET = {
  green: "#53D888",
  yellow: "#FFD01F",
  red: "#FF0000"
}
