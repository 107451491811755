import * as React from "react";

const Important = (props) => (
  <svg
    width="34"
    height="34"
    fill="none"
    viewBox="0 0 34 34"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0371 8.05896V7.64453"
      stroke="#D2E408"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M17.0371 25.227V12.5898"
      stroke="#D2E408"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M17 32C25.2843 32 32 25.2843 32 17C32 8.71573 25.2843 2 17 2C8.71573 2 2 8.71573 2 17C2 25.2843 8.71573 32 17 32Z"
      stroke="#D2E408"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default Important;
