import React from "react";

const BinImg = () => {
  return (
    <svg width="44" height="54" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.8359 52H8.14357C7.87156 52 7.61068 51.8909 7.41834 51.6968C7.22599 51.5027 7.11793 51.2393 7.11793 50.9648L5.06665 13.6978H38.882L36.8308 50.9648C36.8309 51.234 36.727 51.4928 36.5413 51.686C36.3556 51.8793 36.1025 51.9919 35.8359 52Z"
        stroke="#FF0000" strokeWidth="3" />
      <path
        d="M41.4872 13.6874H2.51282C2.22564 13.6874 2 13.5218 2 13.3251L2.95385 8.82197C2.99509 8.72159 3.06718 8.63723 3.15951 8.58132C3.25183 8.52541 3.3595 8.5009 3.46667 8.51141H40.5333C40.6405 8.5009 40.7482 8.52541 40.8405 8.58132C40.9328 8.63723 41.0049 8.72159 41.0462 8.82197L42 13.3251C42 13.5218 41.7744 13.6874 41.4872 13.6874Z"
        stroke="#FF0000" strokeWidth="3" />
      <path d="M13.7949 19.4637V45.3436" stroke="#FF0000" strokeWidth="3" />
      <path d="M22 19.4637V45.3436" stroke="#FF0000" strokeWidth="3" />
      <path d="M30.2052 19.4637V45.3436" stroke="#FF0000" strokeWidth="3" />
      <path
        d="M15.5692 8.51138V3.0352C15.5692 2.76064 15.6773 2.49734 15.8696 2.3032C16.062 2.10906 16.3228 2 16.5949 2H27.4051C27.6771 2 27.938 2.10906 28.1304 2.3032C28.3227 2.49734 28.4308 2.76064 28.4308 3.0352V8.51138"
        stroke="#FF0000" strokeWidth="3" />
    </svg>
  );
};

export default BinImg;
