import * as React from "react";

const Critical = (props) => (
  <svg
    width="38"
    height="34"
    viewBox="0 0 38 34"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.5449 32H2.45512C2.37482 32.0005 2.29584 31.9797 2.22628 31.9396C2.15672 31.8995 2.0991 31.8417 2.05933 31.772C2.01957 31.7023 1.9991 31.6233 2.00003 31.5431C2.00096 31.4629 2.02325 31.3844 2.06462 31.3156L18.6095 2.23103C18.6479 2.16108 18.7044 2.10274 18.7731 2.06209C18.8418 2.02145 18.9202 2 19 2C19.0798 2 19.1582 2.02145 19.2269 2.06209C19.2956 2.10274 19.3521 2.16108 19.3905 2.23103L35.9354 31.3156C35.9767 31.3844 35.999 31.4629 36 31.5431C36.0009 31.6233 35.9804 31.7023 35.9407 31.772C35.9009 31.8417 35.8433 31.8995 35.7737 31.9396C35.7042 31.9797 35.6252 32.0005 35.5449 32Z"
      stroke="#FF0000"
      stroke-width="3"
    />
    <path
      d="M19.0005 27.1133V27.5718"
      stroke="#FF0000"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M19.0005 11.543V23.4711"
      stroke="#FF0000"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
);

export default Critical;
