/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useNavigate, useParams } from "react-router";
import { getEventReq } from "api/event";
import EventDetail from "containers/EventDetail";
import ModalWarning, { ErrorText } from "containers/ModalWarning";
import styles from "./UnpublishedEventsEdit.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, publishEvent, updateEvent } from "store/slices/event";
import { NotificationManager } from "react-notifications";
import Loader from "components/Loader";
import { selectEventsContent } from "store/slices/event";
import { parseDateToFormat } from "utils/date";
import { updateEventDataByKey } from "store/slices/event";
import { Trans, useTranslation } from "react-i18next";
import { lang } from "store/slices/langSlice";

const cx = classNames.bind(styles);

const UnpublishedEventEdit = () => {
  const { t } = useTranslation(["event"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const langValue = useSelector(lang);
  const eventList = useSelector(selectEventsContent);
  const [requestData, setRequestData] = useState(null);

  let { id } = useParams();

  const showModal = (type) => {
    setModalType(type);
    setIsOpenModal(true);
  };

  const update = () => {
    setModalType(null);
    setIsLoading(true);
    dispatch(updateEvent({ id, data: editData }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        navigate("/events/unpublished-event");
      })
      .catch((err) => {
        setIsLoading(false);
        NotificationManager.error("", t("notification:errorUpdateTitle"), 6000);
      });
  };

  const publish = () => {
    setModalType(null);
    setIsLoading(true);
    dispatch(
      publishEvent({
        eventUuid: id,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        NotificationManager.success(
          "",
          t("notification:succesPublishTitle"),
          6000
        );
        navigate("/events/published");
      })
      .catch((err) => {
        setIsLoading(false);
        NotificationManager.error(
          "",
          t("notification:errorPublishTitle"),
          6000
        );
      });
  };

  const updateAndPublish = () => {
    setModalType(null);
    setIsLoading(true);
    dispatch(updateEvent({ id, data: editData }))
      .unwrap()
      .then(() => {
        publish();
      })
      .catch((err) => {
        setIsLoading(false);
        NotificationManager.error("", t("notification:errorUpdateTitle"), 6000);
      });
  };

  const deleteHandler = () => {
    setModalType(null);
    setIsLoading(true);
    dispatch(deleteEvent({ id }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        NotificationManager.success(
          "",
          t("notification:succesDeleteTitle"),
          6000
        );
        navigate("/events/unpublished-event");
      })
      .catch((err) => {
        setIsLoading(false);
        NotificationManager.error("", t("notification:errorDeleteTitle"), 6000);
      });
  };

  const successHandler = () => {
    if (modalType === "publish") {
      return publish();
    }
    if (modalType === "save") {
      return update();
    }
    if (modalType === "publish-and-save") {
      return updateAndPublish();
    }
    deleteHandler();
  };

  const getEventsMethod = async () => {
    setIsLoading(true);
    getEventReq(id)
      .then((res) => {
        setEditData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    !editData && getEventsMethod();
  }, []);

  useEffect(() => {
    if (editData) {
      document.title = editData.title;
    }
  }, [editData]);

  useEffect(() => {
    if (editData) {
      getEventsMethod();
    }
  }, [langValue]);

  return (
    <div className={cx("event")}>
      {editData && (
        <EventDetail
          data={editData}
          setData={setEditData}
          setRequestData={setRequestData}
          requestData={requestData}
          publishBtn
          showTimer
          publishHandler={() => showModal("publish")}
          saveHandler={(data) => {
            setEditData(data);
            showModal("save");
          }}
          saveWithPublish={(data) => {
            setEditData(data);
            showModal("publish-and-save");
          }}
          deleteHandler={() => showModal("delete")}
          onFieldChange={(key, value) => {
            let fixedValue = value;
            if (
              key === "betEndTime" ||
              key === "finishTime" ||
              key === "startTime"
            ) {
              fixedValue = parseDateToFormat(value);
            }
            dispatch(updateEventDataByKey(key, fixedValue, editData.uuid));
          }}
        />
      )}
      <ModalWarning
        isOpen={isOpenModal}
        setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
        successHandler={successHandler}
        cancelHandler={() => {
          setModalType(null);
        }}
        title={
          modalType === "save" ||
          modalType === "publish" ||
          modalType === "publish-and-save" ? (
            <Trans i18nKey="modals:allEnteredDataCorrect">
              Ти точно впевнений, що всі{" "}
              <ErrorText isUpperCase>Дані правильно введені</ErrorText>?
            </Trans>
          ) : (
            <Trans i18nKey="modals:cancelEvent">
              Ти точно впевнений, що хочеш{" "}
              <ErrorText isUpperCase>СКАСУВАТИ ПОДІЮ</ErrorText>?
            </Trans>
          )
        }
      />
      {isLoading && <Loader />}
    </div>
  );
};

export default UnpublishedEventEdit;
