import API from "../api";
import { formatDateForApiCall } from "utils/date";

export const getUserCryptoInfoMethod = () => {
  return API.get("admin/crypto");
};

export const getPeriodProfitMethod = (query, startDate = new Date("2023-08-23T00:00"), endDate = new Date()) => {
  return API.get(
    `/admin/crypto/profit?startDate=${formatDateForApiCall(startDate)} 00:00&endDate=${formatDateForApiCall(
      endDate,
    )} 23:59`,
    {
      params: {
        ...query,
        page: query?.page || 0,
        size: query?.size || 5,
      },
    },
  );
};

export const downloadPeriodProfitMethod = (startDate, endDate) => {
  return API.get(
    `/admin/crypto/profit/download?startDate=${formatDateForApiCall(startDate)} 00:00&endDate=${formatDateForApiCall(
      endDate,
    )} 23:59`,
    {
      responseType: "blob",
    },
  );
};

export const getMainWalletsMethod = (query, startDate, endDate, type) => {
  const url =
    type === "all"
      ? `/admin/crypto/parent-wallet?startDate=${formatDateForApiCall(startDate)} 00:00&endDate=${formatDateForApiCall(
          endDate,
        )} 23:59`
      : `/admin/crypto/parent-wallet?startDate=${formatDateForApiCall(startDate)} 00:00&endDate=${formatDateForApiCall(
          endDate,
        )} 23:59&transactionType=${type}`;

  return API.get(url, {
    params: {
      ...query,
      page: query?.page || 0,
      size: query?.size || 5,
    },
  });
};

export const downloadMainWalletsMethod = (query, startDate, endDate, type) => {
  const url =
    type === "all"
      ? `/admin/crypto/parent-wallet/download?startDate=${formatDateForApiCall(
          startDate,
        )} 00:00&endDate=${formatDateForApiCall(endDate)} 23:59`
      : `/admin/crypto/parent-wallet/download?startDate=${formatDateForApiCall(
          startDate,
        )} 00:00&endDate=${formatDateForApiCall(endDate)} 23:59&transactionType=${type}`;

  return API.get(url, {
    params: {
      ...query,
    },
    responseType: "blob",
  });
};

export const getChildWalletsMethod = (query, startDate, endDate, type) => {
  const url =
    type === "all"
      ? `/admin/crypto/child-wallets?startDate=${formatDateForApiCall(startDate)} 00:00&endDate=${formatDateForApiCall(
          endDate,
        )} 23:59`
      : `/admin/crypto/child-wallets?startDate=${formatDateForApiCall(startDate)} 00:00&endDate=${formatDateForApiCall(
          endDate,
        )} 23:59&transactionType=${type}`;

  return API.get(url, {
    params: {
      ...query,
      page: query?.page || 0,
      size: query?.size || 5,
    },
  });
};

export const downloadChildWalletsMethod = (query, startDate, endDate, type) => {
  const url =
    type === "all"
      ? `/admin/crypto/child-wallets/download?startDate=${formatDateForApiCall(
          startDate,
        )} 00:00&endDate=${formatDateForApiCall(endDate)} 23:59`
      : `/admin/crypto/child-wallets/download?startDate=${formatDateForApiCall(
          startDate,
        )} 00:00&endDate=${formatDateForApiCall(endDate)} 23:59&transactionType=${type}`;

  return API.get(url, {
    params: {
      ...query,
    },
    responseType: "blob",
  });
};

export const addNewChildWallet = () => {
  return API.post("/admin/crypto/child-wallet");
};

export const withdrawalChildContract = (data, code) => {
  return API.post("/admin/crypto/child-wallet/withdraw", data);
};

export const getTransferFeeMethod = (payload) => API.post("/admin/crypto/estimate", payload);
