import React from "react";
import MenuLayout from "../../containers/MenuLayout";
import "./index.scss";

const Page = ({ children, isAuth, isTwoFaEnabled, loggedUserRole }) => (
  <div className="page">
    <MenuLayout isAuth={isAuth} isTwoFaEnabled={isTwoFaEnabled} loggedUserRole={loggedUserRole}>
      {children}
    </MenuLayout>
  </div>
);

export default Page;
