import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.scss";
import { Trans, useTranslation } from "react-i18next";
import Input from "components/Input";
import { useForm } from "react-hook-form";
import AddButton from "components/AddButton";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import {
  ADMIN_TABLE_HEADERS,
  HOURS_WORKED_TABLE_HEADERS,
  USER_ACTIONS_TABLE_HEADERS,
} from "constants/table";
import PaginationComponent from "components/PaginationComponent";
import ModalWarning, { ErrorText, SuccessText } from "containers/ModalWarning";
import ModalAdminUser from "containers/ModalAdminUser";
import ModalHoursWorked from "containers/ModalHoursWorked";
import ModalUserActions from "containers/ModalUserActions";
import Loader from "components/Loader";
import {
  getUsersForAdminTbl,
  adminTableUsers,
  createUser,
  updateUser,
  blockUser,
  unblockUser,
  deleteUser,
  resetUserPass,
  searchUsers,
  selectPageSize,
  selectTotalPages,
  selectCurrentPage,
  setCurrentPage,
  selectUsersIsLoading,
  setTotalPages,
  selectCustomersError,
  selectUserHours,
  selectUserActions,
  selectUserTotalHours,
  getUserHours,
  getUserActions,
  downloadUserHoursToFile,
  downloadUserActionsToFile,
} from "store/slices/users";
import { debounce } from "lodash";
import { setDateToOneMonthEarlier } from "utils/date";

const AdministratorsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "modals"]);

  // Modal state
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isEditUserOpened, setIsEditUserOpened] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isUnblockModalOpen, setIsUnblockModalOpen] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [isResetPassModalOpen, setIsResetPassModalOpen] = useState(false);
  const [isHoursModalOpen, setIsHoursModalOpen] = useState(false);
  const [isUserActionsModalOpen, setIsUserActionsModalOpen] = useState(false);

  // React state
  const [userId, setUserId] = useState("");
  const [adminName, setAdminName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState([]);
  const [queryCode, setQueryCode] = useState(null);
  const [startDate, setStartDate] = useState(setDateToOneMonthEarlier());
  const [endDate, setEndDate] = useState(new Date());

  // Redux selectors
  const adminTblUsers = useSelector(adminTableUsers);
  const pageSize = useSelector(selectPageSize);
  const totalPages = useSelector(selectTotalPages);
  const currentPage = useSelector(selectCurrentPage);
  const isLoading = useSelector(selectUsersIsLoading);
  const usersError = useSelector(selectCustomersError);
  const userHours = useSelector(selectUserHours);
  const userActions = useSelector(selectUserActions);
  const userTotalHours = useSelector(selectUserTotalHours);

  const createData = () => {
    const data = {
      data: adminTblUsers,
      header: ADMIN_TABLE_HEADERS,
    };

    return data;
  };

  const createHoursWorkedData = () => {
    if (userHours) {
      const data = {
        data: [...userHours],
        userTotalHours,
        header: HOURS_WORKED_TABLE_HEADERS,
      };

      return data;
    }
  };

  const createUserActionsData = () => {
    if (userActions) {
      const data = {
        data: [...userActions],
        header: USER_ACTIONS_TABLE_HEADERS,
      };

      return data;
    }
  };

  const { control, handleSubmit, watch, getValues, register } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      search: "",
    },
  });

  const { onChange, onBlur, name, ref } = register("search");

  const onDateChange = (dates) => {
    if (dates.length) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
    }
  };

  const resetDates = () => {
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const fetchUsers = (page, size) => {
    dispatch(getUsersForAdminTbl({ page, size }));
  };

  const handleChangePage = async (selectedPage) => {
    await dispatch(setCurrentPage(selectedPage - 1));

    fetchUsers(selectedPage - 1, pageSize);
  };

  const handleSearchChange = (event) => {
    if (event.target.value === "") {
      fetchUsers(currentPage, pageSize);
    } else {
      dispatch(setTotalPages(0));
      dispatch(searchUsers(event.target.value));
    }
  };

  const addNewUser = async (data) => {
    const requestData = {
      ...data,
      role: data.role.value,
    };

    await dispatch(createUser(requestData));

    fetchUsers(currentPage, pageSize);
  };

  const updateUserRole = async (id, role) => {
    const newRole = role.value;

    await dispatch(updateUser({ id, newRole }));

    fetchUsers(currentPage, pageSize);
  };

  const blockUserHandler = async (id) => {
    await dispatch(blockUser(id));

    fetchUsers(currentPage, pageSize);
  };

  const unblockUserHandler = async (id) => {
    await dispatch(unblockUser(id));

    fetchUsers(currentPage, pageSize);
  };

  const deleteUserHandler = async (id) => {
    await dispatch(deleteUser(id));

    fetchUsers(currentPage, pageSize);
  };

  const resetPassword = (userId, newPassword) => {
    const { password, confirmPassword } = newPassword;
    const payload = { password, confirmPassword };

    dispatch(resetUserPass({ userId, payload }));
  };

  const userHoursHandler = async (id) => {
    setIsHoursModalOpen(!isHoursModalOpen);

    await dispatch(getUserHours({ id, startDate, endDate }));
  };

  const userActionsBtnHandler = async (id) => {
    setIsUserActionsModalOpen(!isUserActionsModalOpen);

    await dispatch(getUserActions({ id, startDate, endDate }));
  };

  const downloadUserHours = async (startDate, endDate) => {
    await dispatch(downloadUserHoursToFile({ userId, startDate, endDate }));
  };

  const downloadUserActions = async (startDate, endDate) => {
    await dispatch(downloadUserActionsToFile({ userId, startDate, endDate }));
  };

  useEffect(() => {
    fetchUsers(currentPage, pageSize);
  }, []);

  return (
    <div className={styles.admins}>
      <div className={styles.admins__header}>
        <div>
          <span className={styles.admins__title}>
            {t("common:admin.admins")}
          </span>

          <div className={styles.admins__search}>
            <Input
              control={control}
              name="search"
              type="text"
              role="search"
              size="sm-2"
              {...register("firstName", {
                onChange: debounce(handleSearchChange, 1000),
              })}
            />
          </div>
        </div>

        <AddButton
          border
          onClick={() => {
            setIsAddUserOpen(!isAddUserOpen);
          }}
        />
      </div>

      <div className={styles.admins__body}>
        <div className={styles.table}>
          <TableComponent
            setUserId={setUserId}
            setAdminName={setAdminName}
            setUserEmail={setUserEmail}
            setUserRole={setUserRole}
            type={TABLE_VIEW.ADMIN}
            editHandler={() => setIsEditUserOpened(!isEditUserOpened)}
            setBlockModal={() => setIsBlockModalOpen(!isBlockModalOpen)}
            setUnblockModal={() => setIsUnblockModalOpen(!isUnblockModalOpen)}
            setDeleteModal={() => setIsDelModalOpen(!isDelModalOpen)}
            setResetPassModal={() =>
              setIsResetPassModalOpen(!isResetPassModalOpen)
            }
            deleteHandler={() => setIsDelModalOpen(!isDelModalOpen)}
            userHoursHandler={userHoursHandler}
            userActionsHandler={userActionsBtnHandler}
            data={createData()}
          />
        </div>

        <div className={styles.pagination}>
          <PaginationComponent
            itemsPerPage={pageSize}
            totalPages={Math.ceil(totalPages)}
            forcePage={0}
            isAdminTbl
            onChanePage={handleChangePage}
          />
        </div>
      </div>

      <ModalWarning
        isOpen={isBlockModalOpen}
        setIsOpenModal={() => setIsBlockModalOpen(!isBlockModalOpen)}
        successHandler={() => {
          blockUserHandler(userId);
          setIsBlockModalOpen(!isBlockModalOpen);
        }}
        cancelHandler={() => setIsBlockModalOpen(!isBlockModalOpen)}
        title={
          <Trans i18nKey="modals:blockUser">
            <ErrorText>заблокувати</ErrorText> цього користувача
          </Trans>
        }
      />

      <ModalWarning
        isOpen={isUnblockModalOpen}
        setIsOpenModal={() => setIsUnblockModalOpen(!isUnblockModalOpen)}
        successHandler={() => {
          unblockUserHandler(userId);
          setIsUnblockModalOpen(!isUnblockModalOpen);
        }}
        cancelHandler={() => setIsUnblockModalOpen(!isUnblockModalOpen)}
        title={
          <Trans i18nKey="modals:unblockUser">
            <SuccessText>розблокувати</SuccessText> цього користувача
          </Trans>
        }
      />

      <ModalWarning
        isOpen={isDelModalOpen}
        setIsOpenModal={() => setIsDelModalOpen(!isDelModalOpen)}
        successHandler={() => {
          deleteUserHandler(userId);
          setIsDelModalOpen(!isDelModalOpen);
        }}
        cancelHandler={() => setIsDelModalOpen(!isDelModalOpen)}
        title={
          <Trans i18nKey="modals:deleteUser">
            Ти впевнений, що хочеш <ErrorText>видалити</ErrorText> цього
            користувача?
          </Trans>
        }
      />

      <ModalAdminUser
        isOpen={isAddUserOpen}
        setIsOpenModal={() => setIsAddUserOpen(!isAddUserOpen)}
        createUserHandler={addNewUser}
      />

      <ModalAdminUser
        isEdit
        isOpen={isEditUserOpened}
        setIsOpenModal={() => setIsEditUserOpened(!isEditUserOpened)}
        userId={userId}
        adminName={adminName}
        userEmail={userEmail}
        userRole={userRole}
        updateUserHandler={updateUserRole}
      />

      <ModalAdminUser
        isResetPass
        userId={userId}
        isOpen={isResetPassModalOpen}
        setIsOpenModal={() => setIsResetPassModalOpen(!isResetPassModalOpen)}
        resetPassHandler={resetPassword}
      />

      <ModalHoursWorked
        isOpen={isHoursModalOpen}
        setIsOpenModal={() => setIsHoursModalOpen(!isHoursModalOpen)}
        downloadUserHours={downloadUserHours}
        data={createHoursWorkedData()}
        userId={userId}
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
        resetDates={resetDates}
        title={
          <Trans i18nKey="modals:hoursWorked">
            Cтатистика відпрацьваних годин
          </Trans>
        }
      />

      <ModalUserActions
        isOpen={isUserActionsModalOpen}
        setIsOpenModal={() =>
          setIsUserActionsModalOpen(!isUserActionsModalOpen)
        }
        downloadUserActions={downloadUserActions}
        data={createUserActionsData()}
        userId={userId}
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
        resetDates={resetDates}
        title={
          <Trans i18nKey="modals:userActions">
            Відстеження дій співробітників
          </Trans>
        }
      />

      {isLoading && <Loader />}
    </div>
  );
};

export default AdministratorsPage;
