import React from "react";
import CreateEvent from "./CreateEvent";
import EditEvent from "./EditEvent";

const ModalEvent = ({
  isOpen,
  setIsOpenModal,
  itsCreate = true,
  saveHandler,
}) => {
  if (itsCreate) {
    return (
      <CreateEvent
        isOpen={isOpen}
        setIsOpenModal={setIsOpenModal}
        itsCreate={itsCreate}
        saveHandler={saveHandler}
      />
    );
  }

  return (
    <EditEvent
      isOpen={isOpen}
      setIsOpenModal={setIsOpenModal}
      itsCreate={itsCreate}
      saveHandler={saveHandler}
    />
  );
};

export default ModalEvent;
