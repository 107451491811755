// React Redux Styling
import React from "react";
import styles from "./index.module.scss";

// Components
import Button from "components/Button";
import AutoResizeTextArea from "components/AutoResizeTextArea";

// Utils & Constants
import { useTranslation } from "react-i18next";
import { commissionAndBonusSettings } from "constants/adminSettings";
import { useForm } from "react-hook-form";

const FeeSettingForm = ({ commissions, commissionSettingBtnHandler }) => {
  const { t } = useTranslation(["common", "button"]);

  const { control, getValues, reset } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      registrationBonus: "",
    },
  });

  return (
    <div className={styles["commission"]}>
      {commissionAndBonusSettings.map(({ title, name, setting }) => {
        return (
          <div key={crypto.randomUUID()} className={styles["commission__row"]}>
            <span className={styles["commission__title"]}>{title}</span>

            <div>
              <span>Зараз встановлено</span>
              <div>{commissions[name]}</div>
            </div>

            <AutoResizeTextArea control={control} placeholder="__" name={name} size="sm-2" />

            <Button
              customBox
              height={48}
              styleBtn={{ lineHeight: "24px" }}
              onClick={() => {
                commissionSettingBtnHandler(setting, getValues()[name], reset);
              }}
            >
              {t("button:set")}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default FeeSettingForm;
