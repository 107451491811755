import React, { useState } from "react";
import Select, { components } from "react-select";
import { useController } from "react-hook-form";
import cx from "classnames";

import styles from "./index.module.scss";
import ArrowUp from "assets/svg/ArrowUp";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    background: "#202643",
    boxShadow:
      "-3px 3px 6px rgba(13, 16, 28, 0.2), 3px -3px 6px rgba(13, 16, 28, 0.2), -3px -3px 6px rgba(53, 62, 110, 0.9), 3px 3px 8px rgba(13, 16, 28, 0.9), inset 1px 1px 2px rgba(53, 62, 110, 0.3), inset -1px -1px 2px rgba(13, 16, 28, 0.5)",
    borderRadius: " 10px",
    overflow: "hidden",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "12px 0",
  }),
  menuList: (provided, state) => {
    return {
      ...provided,
      paddingTop: "0!important",
      paddingBottom: "0!important",
      borderRadius: " 0 0 8px 8px",
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      position: "relative",
      borderRadius: "10px",
      border: "none",
      display: "flex",
      justifyContent: "center",
      "> div": {
        flex: "unset",
      },
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      color: state.data.color,
      textAlign: "center",
      backgroundColor: "#202643 !important",
      cursor: "pointer",
      "&:hover, &:active, &:focus": {
        backgroundColor: "#202643",
        boxShadow:
          "1px 1px 2px rgb(50 59 104 / 30%), -1px -1px 2px rgb(17 20 35 / 50%), inset -1px 1px 2px rgb(17 20 35 / 20%), inset 1px -1px 2px rgb(17 20 35 / 20%), inset -1px -1px 2px rgb(50 59 104 / 90%), inset 1px 1px 3px rgb(17 20 35 / 90%) ",
      },
    };
  },
  placeholder: (provided, state) => ({
    color: "#ffffff",
  }),
  singleValue: (provided, state) => {
    return {
      ...provided,
      color: state.data.color,
      positon: "relative",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      padding: 0,
      svg: {
        fill: "#3ddde8",
        width: "40px",
        height: "30px",
      },
    };
  },
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowUp />
    </components.DropdownIndicator>
  );
};

export const SelectInput = (props) => {
  const {
    options,
    handleChange,
    label,
    errors,
    disabled,
    placeholder = "Select",
    inForm,
  } = props;
  const { field } = useController(props);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div
      className={cx(styles["dropdown"], {
        [styles["form-dropdown"]]: inForm,
        [styles["disabled"]]: disabled,
        [styles["open"]]: isMenuOpen,
      })}
    >
      <div className={styles["label-wrap"]}>
        {label && (
          <span
            className={cx({
              [styles["label"]]: !inForm,
              [styles["form-label"]]: inForm,
              [styles[errors]]: errors,
            })}
          >
            {label}
          </span>
        )}
      </div>
      <div
        className={cx({
          [styles["dropdown__wrap"]]: !inForm,
          [styles["dropdown__form-wrap"]]: inForm,
        })}
      >
        <Select
          {...field}
          styles={customStyles}
          value={field.value}
          onChange={(data) => {
            field.onChange(data);
            if (handleChange) {
              handleChange(data);
            }
          }}
          components={{ DropdownIndicator }}
          options={options}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          empty
          isClearable={false}
          isSearchable={false}
          placeholder={placeholder}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export default SelectInput;
