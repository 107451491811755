import * as React from "react";

const Copy = (props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="transparent"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="3.25"
      width="11.0001"
      height="10.9996"
      rx="3.25"
      stroke="#3DDDE8"
      stroke-width="1.5"
    />
    <rect
      x="3.25"
      y="0.75"
      width="11.0001"
      height="10.9996"
      rx="3.25"
      fill="#212745"
      stroke="#3DDDE8"
      stroke-width="1.5"
    />
  </svg>
);

export default Copy;
