import * as React from "react";

const VectorUp = (props) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.1452 4.45956L9.1452 16.9492"
      stroke="#4DB375"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.44656 9.49609L9.14476 4.4586L13.8438 9.49609"
      stroke="#4DB375"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default VectorUp;
